<script setup lang="ts">
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroLabel from '@/components/global/ZyroLabel.vue';
import ZyroSelect from '@/components/global/ZyroSelect.vue';
import ZyroTooltip from '@/components/global/ZyroTooltip.vue';
import Icon from '@/components/global/Icon.vue';

import { useStore } from 'vuex';

import EventLogApi from '@/api/EventLogApi';
import { useI18n } from 'vue-i18n';
import { useEcommerceAdminStore } from '@/stores';
import {
	computed,
	onMounted,
	ref,
} from 'vue';
import EcommerceNoProducts from '@/components/builder-controls/edit-button/EcommerceNoProducts.vue';
import {
	ECOMMERCE_REDIRECT_PATHS,
	REDIRECT_PARAM_KEYS,
	ECOMMERCE_FUNNEL_LOCATIONS,
} from '@/constants';
import { EcommerceProduct } from '@zyro-inc/site-modules/types';
import { useEcommerceStore } from '@/stores/ecommerceStore';

const { t } = useI18n();
const {
	state,
	getters,
	dispatch,
} = useStore();
const ecommerceAdminStore = useEcommerceAdminStore();
const ecommerceStore = useEcommerceStore();

const DEFAULT_DROPDOWN_FIELD = {
	id: -1,
	title: t('builder.ecommerce.chooseProduct'),
};

const currentPage = ref(0);

const currentElementSettings = computed(() => getters.currentElementSettings);
const products = computed<EcommerceProduct[]>(() => ecommerceStore.products);
const product = computed(() => {
	const { productId } = currentElementSettings.value;

	return products.value.find((p) => p.id === productId);
});
const variant = computed(() => {
	const { productVariantId } = currentElementSettings.value;

	return product.value?.variants.find((v) => v.id === productVariantId);
});
const selectedProduct = computed(() => product.value || DEFAULT_DROPDOWN_FIELD);
const selectedVariant = computed(() => variant.value || DEFAULT_DROPDOWN_FIELD);
const isProductIdValid = computed(() => selectedProduct.value.id !== -1);
const isVariantSelectVisible = computed(() => (isProductIdValid.value
	? !!(selectedProduct.value as EcommerceProduct).options.length
	: false
));
const productSelectOptions = computed(() => products.value.map((p) => {
	const [firstVariant] = p.variants;

	return {
		id: p.id,
		// eslint-disable-next-line camelcase
		disabled: p.custom_fields?.some(({ is_required }) => is_required),
		title: p.title,
		defaultVariantId: firstVariant.id,
		sku: !p.options.length ? firstVariant.sku : null,
	};
}));
const isMobileScreen = computed(() => state.gui.isMobileScreen);

const handleButtonClick = async () => {
	await EventLogApi.logEvent({
		eventName: 'website_builder.ecomm_products.edit',
		eventProperties: {
			location: 'button',
		},
	});

	ecommerceAdminStore.setIsEcommerceAdminIframeOpen({
		isOpen: true,
		path: ECOMMERCE_REDIRECT_PATHS.PRODUCTS_EDIT,
		params: {
			[REDIRECT_PARAM_KEYS.PRODUCT]: selectedProduct.value.id,
		},
		location: ECOMMERCE_FUNNEL_LOCATIONS.IFRAME_EDIT_PRODUCT_BUTTON,
	});
};

const updateSelectedProduct = (productData: typeof productSelectOptions['value'][number]) => {
	dispatch('mergeCurrentElementData', {
		elementData: {
			settings: {
				productId: productData.id,
				productVariantId: productData.defaultVariantId,
			},
		},
	});
};

const updateSelectedVariant = (v: EcommerceProduct['variants'][number]) => {
	dispatch('mergeCurrentElementData', {
		elementData: {
			settings: {
				productVariantId: v.id,
			},
		},
	});
};

const loadProducts = async () => {
	if (ecommerceStore.count === products.value.length) {
		return;
	}

	currentPage.value += 1;

	await ecommerceStore.fetchProducts({
		limit: 25 * currentPage.value,
	});
};

onMounted(async () => {
	await loadProducts();
});
</script>

<template>
	<div class="ecommerce-tab">
		<EcommerceNoProducts v-if="!products.length" />
		<template v-else>
			<ZyroLabel class="ecommerce-tab__label">
				{{ $t('builder.editButton.ecommerce.connectButtonTo') }}
			</ZyroLabel>
			<ZyroSelect
				v-qa="'ecommerce-button-product-select'"
				class="ecommerce-tab__dropdown"
				:options="productSelectOptions"
				label-key="title"
				:model-value="selectedProduct"
				is-lazyload-enabled
				:total-options-count="ecommerceStore.count"
				:selectable="(p: typeof productSelectOptions[number]) => !p.disabled"
				@load-more="loadProducts"
				@update:model-value="updateSelectedProduct"
			>
				<template #option="{ option }">
					<div
						v-qa="`dropdown-option-${option.title}`"
						:aria-disabled="option.disabled"
						class="ecommerce-tab__dropdown-option"
						:class="{ 'ecommerce-tab__dropdown-option--disabled': option.disabled }"
					>
						<div>
							<span
								v-if="option.sku"
								class="ecommerce-tab__dropdown-prefix"
							>
								{{ `SKU ${option.sku}` }}
							</span>
							{{ option.title }}
						</div>
						<ZyroTooltip
							v-if="option.disabled"
							:position="isMobileScreen ? 'top' : 'bottom'"
							toggle-event="hover"
							mode="dark"
							:use-portal="!isMobileScreen"
							:forced-position="isMobileScreen ? {
								left: '0px',
								top: '130px',
								'white-space': 'wrap'
							} : undefined"
						>
							<template #trigger>
								<Icon
									name="help-circle"
									dimensions="15px"
								/>
							</template>
							<p class="text-body-2">
								{{ $t('builder.cannotAssignCustomField') }}
							</p>
						</ZyroTooltip>
					</div>
				</template>
			</ZyroSelect>
			<template v-if="isVariantSelectVisible">
				<ZyroLabel class="ecommerce-tab__label">
					{{ $t('builder.editButton.ecommerce.productOption') }}:
				</ZyroLabel>
				<ZyroSelect
					v-qa="'ecommerce-tab-product-select'"
					class="ecommerce-tab__dropdown"
					:options="(selectedProduct as EcommerceProduct).variants"
					label-key="title"
					:model-value="selectedVariant"
					@update:model-value="updateSelectedVariant"
				>
					<template #option="{ option }">
						<div
							v-qa="`dropdown-option-${option.title}`"
							class="ecommerce-tab__dropdown-option"
						>
							<span
								v-if="option.sku"
								class="ecommerce-tab__dropdown-prefix"
							>
								{{ `SKU ${option.sku}` }}
							</span>
							{{ option.title }}
						</div>
					</template>
				</ZyroSelect>
			</template>
			<HostingerButton
				v-if="isProductIdValid"
				v-qa="'ecommerce-settings-button-editthisproduct'"
				button-type="outlined"
				class="ecommerce-tab__edit-button"
				@click="handleButtonClick"
			>
				{{ $t('builder.editButton.ecommerce.editProduct') }}
			</HostingerButton>
		</template>
	</div>
</template>

<style lang="scss" scoped>
.ecommerce-tab {
	display: flex;
	flex-direction: column;

	&__edit-button {
		margin: 40px auto 26px;
	}

	&__dropdown {
		background-color: $color-light;
	}

	&__dropdown-option {
		display: flex;
		align-items: center;
		justify-content: space-between;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		&--disabled {
			cursor: not-allowed;
			color: $color-gray;
		}
	}

	&__dropdown-prefix {
		color: $color-gray;
	}
}
</style>
