import {
	DEFAULT_BLOCK_BLOG_COLUMN_GAP,
	DEFAULT_BLOCK_BLOG_M_COLUMN_GAP,
	DEFAULT_ECOMMERCE_PRODUCT_CONTENT_WIDTH,
	DEFAULT_BLOG_CONTENT_WIDTH,
} from '@zyro-inc/site-modules/constants/defaultStyles';

import {
	BLOG_POST_EXAMPLE_POSTS_PER_PAGE_COUNT,
	BLOG_POST_EXAMPLE_POST_COLUMN_COUNT,
} from '@/constants';

import { blogPageElements } from '@/components/builder-controls/add-element/elements';
import { generateRandomId } from '@/utils/generateRandomId';

import type { AiGeneratedImageElementDataType } from '@/use/useAiImageGenerator';
import { EcommerceProduct } from '@zyro-inc/site-modules/types';
import { PAGE_TYPE_ECOMMERCE_DYNAMIC_PRODUCT } from '@zyro-inc/site-modules/constants';

export const DEFAULT_GRID_STYLES = {
	'row-gap': '16px',
	'row-size': '48px',
	'column-gap': '24px',
	'block-padding': '16px 0',
	'm-block-padding': '56px 16px',
};

export const getBlogListBlock = ({
	mockCategories,
	postsPerPage = BLOG_POST_EXAMPLE_POSTS_PER_PAGE_COUNT,
	postColumnCount = BLOG_POST_EXAMPLE_POST_COLUMN_COUNT,
}: {mockCategories: boolean, postsPerPage: number, postColumnCount: number}) => ({
	type: 'BlockBlogList',
	settings: {
		postsPerPage,
		postColumnCount,
		categories: [],
		showAllPosts: true,
		showWithoutCategories: false,
		fullWidth: false,
		mockCategories,
		shownItems: {
			coverImage: true,
			title: true,
			description: true,
			date: true,
			categories: true,
			authorFullName: true,
			avatar: true,
			minutesToRead: true,
		},
		styles: {
			'grid-gap-size': `${DEFAULT_BLOCK_BLOG_COLUMN_GAP}px`,
			'm-grid-gap-size': `${DEFAULT_BLOCK_BLOG_M_COLUMN_GAP}px`,
			'block-padding': '60px',
			'content-width': `${DEFAULT_BLOG_CONTENT_WIDTH}px`,
			'cover-object-fit': 'contain',
			// Styles below are not used for the list appearance - they are for AddBlockModal support
			rows: 10,
			cols: 10,
			'row-size': DEFAULT_GRID_STYLES['row-size'],
			'row-gap': DEFAULT_GRID_STYLES['row-gap'],
		},
	},
	components: [],
	zindexes: [],
	background: {
		current: 'color',
		color: 'rgb(255, 255, 255)',
	},
});

export const getLayoutBlock = () => ({
	type: 'BlockLayout',
	desktop: {
		minHeight: 500,
	},
	mobile: {
		minHeight: 140,
	},
	settings: {
		styles: {
			'block-padding': '16px 0 16px 0',
			'm-block-padding': '16px',
		},
	},
	components: [],
	background: {
		color: 'rgb(255, 255, 255)',
		current: 'color',
	},
	zindexes: [],
});

export const getBlogHeaderBlock = () => ({
	type: 'BlockBlogHeader',
	background: {
		current: 'color',
		color: 'rgb(255, 255, 255)',
	},
	settings: {
		shownItems: {
			date: true,
			categories: true,
			authorFullName: true,
			avatar: true,
			minutesToRead: true,
			description: true,
		},
		styles: {
			'block-padding': '45px 30px 45px 30px',
			'm-block-padding': '16px 16px 16px 16px',
			'content-width': '954px',
			align: 'flex-start',
			'text-align': 'center',
		},
	},
	animation: {
		name: 'slide',
		type: 'global',
	},
	components: [],
});

export const getBlogPostLayoutBlock = (
	postContent: string,
	blogThumbnail: string,
	textBoxElementId: string = generateRandomId(),
	imageElementId: string = generateRandomId(),
) => ({
	blockData: {
		type: 'BlockLayout',
		desktop: {
			minHeight: 500,
		},
		mobile: {
			minHeight: 140,
		},
		components: [
			imageElementId,
			textBoxElementId,
		],
		zindexes: [
			imageElementId,
			textBoxElementId,
		],
		background: {
			current: 'color',
			color: 'rgb(255, 255, 255)',
		},
		settings: {
			styles: {
				'm-block-padding': DEFAULT_GRID_STYLES['m-block-padding'],
			},
		},
	},
	elements: {
		[imageElementId]: blogPageElements.getImage(blogThumbnail),
		[textBoxElementId]: blogPageElements.getLayoutContentTextBox(postContent),
	},
});

export const getEcommerceProductBlock = ({
	productId = -1,
	buttonText,
}: {productId?: number, buttonText?: string} = {}) => ({
	type: 'BlockEcommerceProduct',
	settings: {
		styles: {
			'content-width': `${DEFAULT_ECOMMERCE_PRODUCT_CONTENT_WIDTH}px`,
			'block-padding': '100px 16px',
			'm-block-padding': DEFAULT_GRID_STYLES['m-block-padding'],
		},
	},
	background: {
		current: 'color',
		color: 'rgb(255, 255, 255)',
	},
	navigationArrowsColor: 'rgb(0, 0, 0)',
	navigationThumbnailArrowsColor: 'rgb(0, 0, 0)',
	galleryPlacement: 'bottom',
	imageRatio: 'contain',
	imageBorderRadius: '0%',
	product: {
		id: productId,
	},
	zindexes: [],
	isQuantityPickerEnabled: true,
	buttonStyle: {
		'grid-button-primary-color': 'rgb(255, 255, 255)',
		'grid-button-primary-color-hover': 'rgb(255, 255, 255)',
		'grid-button-primary-background-color': 'rgb(0, 0, 0)',
		'grid-button-primary-background-color-active': 'rgb(0, 0, 0)',
		'grid-button-primary-background-color-hover': 'rgb(29, 30, 32)',
		'grid-button-primary-border-color': 'rgb(0, 0, 0)',
		'grid-button-primary-border-color-active': 'rgb(0, 0, 0)',
		'grid-button-primary-border-color-hover': 'rgb(29, 30, 32)',
		'grid-button-secondary-color': 'rgb(46, 47, 49)',
		'grid-button-secondary-color-hover': 'rgb(29, 30, 32)',
		'grid-button-secondary-background-color': 'rgb(255, 255, 255)',
		'grid-button-secondary-background-color-active': 'rgb(255, 255, 255)',
		'grid-button-secondary-background-color-hover': 'rgb(241, 241, 241)',
		'grid-button-secondary-border-color': 'rgb(255, 255, 255)',
		'grid-button-secondary-border-color-active': 'rgb(255, 255, 255)',
		'grid-button-secondary-border-color-hover': 'rgb(241, 241, 241)',
	},
	textColorVars: {
		'h1-color': 'rgb(26, 26, 26)',
		'h2-color': 'rgb(26, 26, 26)',
		'h3-color': 'rgb(26, 26, 26)',
		'h4-color': 'rgb(26, 26, 26)',
		'h5-color': 'rgb(26, 26, 26)',
		'h6-color': 'rgb(26, 26, 26)',
		'body-large-color': 'rgb(26, 26, 26)',
		'body-color': 'rgb(26, 26, 26)',
	},
	components: [],
	...(buttonText ? {
		buttonText,
	} : {}),
});

export const getEcommerceProductListBlock = ({
	products = [],
	textAlign = 'left',
	blockPadding = '100px 16px',
	imageRatio = 'contain',
	isFullWidth = false,
	columnCountDesktop = 3,
	columnGapDesktop = 24,
	columnCountMobile,
	columnGapMobile,
	isSortingEnabled = false,
	productsPerPage = 6,
	productCategoryId = null,
	isCategoryListEnabled = false,
	isTotalProductCountShown = false,
	isButtonFullWidth = false,
	buttonDisplay = null,
	imageHoverEffect = null,
	isButtonEnabled = false,
	isListCentered = false,
	buttonStyle = {},
	buttonBorderWidth = null,
}: {
	products: Array<EcommerceProduct>,
	textAlign?: string,
	blockPadding?: string,
	imageRatio?: string,
	columnCountDesktop?: number,
	columnGapDesktop?: number,
	columnCountMobile?: number,
	columnGapMobile?: number,
	isFullWidth?: boolean,
	isSortingEnabled?: boolean,
	productsPerPage?: number,
	productCategoryId?: number | null,
	isCategoryListEnabled?: boolean,
	isTotalProductCountShown?: boolean,
	isButtonFullWidth?: boolean,
	imageHoverEffect?: string | null,
	buttonDisplay?: string | null,
	isButtonEnabled?: boolean,
	isListCentered?: boolean,
	buttonStyle?: Record<string, string>,
	buttonBorderWidth?: number | null,
}) => ({
	type: 'BlockEcommerceProductList',
	settings: {
		styles: {
			textAlign,
			'content-width': `${DEFAULT_ECOMMERCE_PRODUCT_CONTENT_WIDTH}px`,
			'block-padding': blockPadding,
			'm-block-padding': DEFAULT_GRID_STYLES['m-block-padding'],
			...(imageHoverEffect && {
				imageHoverEffect,
			}),
		},
	},
	background: {
		current: 'color',
		color: 'rgb(255, 255, 255)',
	},
	imageRatio,
	...((columnCountDesktop || columnGapDesktop) && {
		desktop: {
			...(columnCountDesktop && {
				columnCount: columnCountDesktop,
			}),
			...(columnGapDesktop && {
				columnGap: columnGapDesktop,
			}),
		},
	}),
	...((columnCountMobile || columnGapMobile) && {
		mobile: {
			...(columnCountMobile && {
				columnCount: columnCountMobile,
			}),
			...(columnGapMobile && {
				columnGap: columnGapMobile,
			}),
		},
	}),
	isFullWidth,
	...(isSortingEnabled && {
		productSorting: {
			enabled: true,
			textColor: '#000',
			sortingOptions: [
				{
					id: 'default',
					label: 'Default',
					value: '',
					isEnabled: true,
				},
				{
					id: 'priceLTH',
					label: 'Price (low to high)',
					value: 'order=ASC&sort_by=price',
					isEnabled: true,
				},
				{
					id: 'priceHTL',
					label: 'Price (high to low)',
					value: 'order=DESC&sort_by=price',
					isEnabled: true,
				},
				{
					id: 'mostRecent',
					label: 'Most recent',
					value: 'order=DESC&sort_by=created_at',
					isEnabled: true,
				},
			],
		},
	}),
	productsPerPage,
	...(productCategoryId && {
		productCategoryId,
	}),
	isCategoryListEnabled,
	isTotalProductCountShown,
	productIds: products.map((product: EcommerceProduct) => product.id),
	zindexes: [],
	textColorVars: {
		'h1-color': 'rgb(26, 26, 26)',
		'h2-color': 'rgb(26, 26, 26)',
		'h3-color': 'rgb(26, 26, 26)',
		'h4-color': 'rgb(26, 26, 26)',
		'h5-color': 'rgb(26, 26, 26)',
		'h6-color': 'rgb(26, 26, 26)',
		'body-large-color': 'rgb(26, 26, 26)',
		'body-color': 'rgb(26, 26, 26)',
	},
	buttonStyle: {
		'grid-button-primary-color': 'rgb(255, 255, 255)',
		'grid-button-primary-color-hover': 'rgb(255, 255, 255)',
		'grid-button-primary-background-color': 'rgb(0, 0, 0)',
		'grid-button-primary-background-color-active': 'rgb(0, 0, 0)',
		'grid-button-primary-background-color-hover': 'rgb(29, 30, 32)',
		'grid-button-primary-border-color': 'rgb(0, 0, 0)',
		'grid-button-primary-border-color-active': 'rgb(0, 0, 0)',
		'grid-button-primary-border-color-hover': 'rgb(29, 30, 32)',
		'grid-button-secondary-color': 'rgb(46, 47, 49)',
		'grid-button-secondary-color-hover': 'rgb(29, 30, 32)',
		'grid-button-secondary-background-color': 'rgb(255, 255, 255)',
		'grid-button-secondary-background-color-active': 'rgb(255, 255, 255)',
		'grid-button-secondary-background-color-hover': 'rgb(241, 241, 241)',
		'grid-button-secondary-border-color': 'rgb(255, 255, 255)',
		'grid-button-secondary-border-color-active': 'rgb(255, 255, 255)',
		'grid-button-secondary-border-color-hover': 'rgb(241, 241, 241)',
		...buttonStyle,
	},
	...(buttonDisplay && {
		buttonDisplay,
	}),
	...(buttonBorderWidth && {
		buttonBorderWidth,
	}),
	isButtonEnabled,
	isButtonFullWidth,
	ribbonStyle: {
		'ribbon-color': 'rgb(255, 255, 255)',
		'ribbon-background-color': 'rgb(29, 30, 32)',
	},
	components: [],
	...(isListCentered && {
		isListCentered,
	}),
});

export const getEcommerceProductTemplate = (blockId: string = generateRandomId()) => ({
	pageData: {
		type: PAGE_TYPE_ECOMMERCE_DYNAMIC_PRODUCT,
		blocks: [blockId],
	},
	blocks: {
		[blockId]: {
			...getEcommerceProductBlock(),
		},
	},
});

export const getPageTitleBlock = ({
	title,
	gridTextBoxElementId = generateRandomId(),
}: {title: string, gridTextBoxElementId: string}) => ({
	blockData: {
		type: 'BlockLayout',
		mobile: {
			minHeight: 170,
		},
		desktop: {
			minHeight: 234,
		},
		settings: {
			styles: {
				'block-padding': '16px 0 16px 0',
				'm-block-padding': '16px',
			},
		},
		zindexes: [gridTextBoxElementId],
		background: {
			color: 'rgb(255, 255, 255)',
			current: 'color',
		},
		components: [gridTextBoxElementId],
	},
	elements: {
		[gridTextBoxElementId]: {
			type: 'GridTextBox',
			mobile: {
				top: 64,
				left: 0,
				width: 328,
				height: 46,
			},
			content: `<h1>${title}</h1>`,
			desktop: {
				top: 80,
				left: 309,
				width: 606,
				height: 72,
			},
			settings: {
				styles: {
					text: 'center',
					align: 'flex-start',
					justify: 'flex-start',
					'm-element-margin': '0 0 16px 0',
				},
			},
			animation: {
				name: 'slide',
				type: 'global',
			},
		},
	},
});

export const getPortfolioBlock = (gridGalleryElementId:string = generateRandomId()) => ({
	blockData: {
		type: 'BlockLayout',
		mobile: {
			minHeight: 1455,
		},
		desktop: {
			minHeight: 1925,
		},
		settings: {
			styles: {
				'block-padding': '16px 0 16px 0',
				'm-block-padding': '16px',
			},
		},
		zindexes: [gridGalleryElementId],
		background: {
			color: 'rgb(255, 255, 255)',
			current: 'color',
		},
		components: [gridGalleryElementId],
	},
	elements: {
		[gridGalleryElementId]: {
			type: 'GridGallery',
			images: [
				{
					alt: '',
					path: 'https://assets.zyrosite.space/AR0eprE7RRhpVzDz/pexels-aeae-ss-ae---2848289-m5kpo846zzf0bgek-AE0E3LX1KZuDK17O.jpg',
					origin: 'other',
				},
				{
					alt: '',
					path: 'https://assets.zyrosite.space/AR0eprE7RRhpVzDz/pexels-edward-jenner-4252525-m5kpo84naqf4kbo6-A85D7Pk72PuR98aN.jpg',
					origin: 'other',
				},
				{
					alt: '',
					path: 'https://assets.zyrosite.space/AR0eprE7RRhpVzDz/pexels-graphicgumcom-1289672-ylen74xpl8u9a0rn-m2W1OV5L2kHblob7.jpg',
					origin: 'other',
				},
				{
					alt: '',
					path: 'https://assets.zyrosite.space/AR0eprE7RRhpVzDz/pexels-ilya-galica-5271593-ar0ep1qeb1s9kwnj-Yg27E3gErJhgP4yO.jpg',
					origin: 'other',
				},
				{
					alt: '',
					path: 'https://assets.zyrosite.space/AR0eprE7RRhpVzDz/pexels-carlos-montelara-5152692-y4llnpbqpnclvzxo-Yan7eP3egJtD3jeo.jpg',
					origin: 'other',
				},
				{
					alt: '',
					path: 'https://assets.zyrosite.space/AR0eprE7RRhpVzDz/pexels-lisa-fotios-4644397-d95mnzob1zhx54ov-mP4Z6Je6j2c3Xxaw.jpg',
					origin: 'other',
				},
				{
					alt: '',
					path: 'https://assets.zyrosite.space/AR0eprE7RRhpVzDz/pexels-anna-shvets-4557398-avlpox4q0ku594kg-dWxn64G6erF5laj3.jpg',
					origin: 'other',
				},
				{
					alt: '',
					path: 'https://assets.zyrosite.space/AR0eprE7RRhpVzDz/pexels-damian-apanasowicz-4185556-y4llnpbn95sznnjk-AzGOab1Q6WsOjBPD.jpg',
					origin: 'other',
				},
				{
					alt: '',
					path: 'https://assets.zyrosite.space/AR0eprE7RRhpVzDz/pexels-thiago-giardini-5815066-ar0ep1qv43t1kger-YrD3O7zOlBUjOwNa.jpg',
					origin: 'other',
				},
				{
					alt: '',
					path: 'https://assets.zyrosite.space/AR0eprE7RRhpVzDz/pexels-charlotte-may-5825574-aopda47pbotbyq5l-mjEzjB0j9JUO1K48.jpg',
					origin: 'other',
				},
				{
					alt: '',
					path: 'https://assets.zyrosite.space/AR0eprE7RRhpVzDz/pexels-mitchell-luo-3685207-a3qbylnzq0trx2gq-YbNb68MWQof74BQ7.jpg',
					origin: 'other',
				},
				{
					alt: '',
					path: 'https://assets.zyrosite.space/AR0eprE7RRhpVzDz/pexels-bruno-cervera-6033461-yg27e4gdebtgb9o7-mePyWM0W3lSkywGL.jpg',
					origin: 'other',
				},
			],
			mobile: {
				top: 16,
				left: 0,
				width: 328,
				height: 1417,
				columnGap: 12,
				columnCount: 2,
			},
			desktop: {
				top: 64,
				left: 103,
				width: 1018,
				height: 1746,
				columnGap: '16',
				columnCount: '3',
			},
			settings: {
				layout: 'masonry',
				styles: {
					'm-element-margin': '0 0 16px 0',
				},
				imageClickAction: 'lightbox',
			},
			animation: {
				name: 'slide',
				type: 'global',
			},
		},
	},
});

export const getImageBlock = ({
	imageData,
	imageElementId = generateRandomId(),
}: { imageData: AiGeneratedImageElementDataType, imageElementId?: string}) => ({
	blockData: {
		type: 'BlockLayout',
		mobile: {
			minHeight: 400,
		},
		desktop: {
			minHeight: 640,
		},
		settings: {
			styles: {
				'block-padding': '16px 0 16px 0',
				'm-block-padding': '16px',
			},
		},
		zindexes: [imageElementId],
		background: {
			color: 'rgb(255, 255, 255)',
			current: 'color',
		},
		components: [imageElementId],
	},
	elements: {
		[imageElementId]: {
			rel: 'nofollow',
			type: 'GridImage',
			mobile: {
				top: 40,
				left: 4,
				width: 320,
				height: 320,
			},
			desktop: {
				top: 80,
				left: 372,
				width: 480,
				height: 480,
			},
			...imageData,
			settings: {
				alt: 'Bright living room with modern inventory',
				...imageData.settings,
				styles: {
					align: 'center',
					justify: 'center',
					'm-element-margin': '0 0 16px 0',
				},
				clickAction: 'none',
			},
		},
	},
});

export const getStickyBarBlock = () => ({
	type: 'BlockStickyBar',
	desktop: {
		minHeight: 40,
	},
	mobile: {
		minHeight: 40,
	},
	settings: {
		styles: {
			'block-padding': '0',
			'm-block-padding': '0 16px',
		},
	},
	components: [],
	background: {
		color: 'rgb(255, 255, 255)',
		current: 'color',
	},
	zindexes: [],
	items: [
		{
			type: 'text',
			content: '<p class="body-small" style="color: rgb(26, 26, 26)">Add your promotional text...</p>',
		},
	],
});
