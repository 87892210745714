import { COOKIE_SETUP_PAYMENT_NOTIFICATION_CLOSED_AT } from '@/constants';
import {
	SitePages,
	SiteEcommerceSortingValue,
} from '@hostinger/builder-schema-validator';
import { extractText } from '@/utils/extractText';
import { generatePageSlug } from '@/utils/generatePageSlug';
import { EcommerceProduct } from '@zyro-inc/site-modules/types';

export const getFilteredProductsByCategoryId = (products: EcommerceProduct[], categoryId: string) => products
	.filter((product) => product.product_collections.some(
		(category) => category.collection_id === categoryId,
	))
	.sort((a, b) => {
		const resolveOrder = (product: EcommerceProduct) => {
			const index = product.product_collections
				.findIndex((item) => item.collection_id === categoryId);

			return Number(product.product_collections[index].order);
		};

		return resolveOrder(a) >= resolveOrder(b) ? 1 : -1;
	});

export const getSetupPaymentNotificationClosedAtCookieName = (storeId: string) => `${
	COOKIE_SETUP_PAYMENT_NOTIFICATION_CLOSED_AT}/${storeId}`;

export const getSortedProductsKey = (sort = '', collectionId = '') => `${sort}${collectionId}` as SiteEcommerceSortingValue;

export const getShouldSkipProductsFetch = ({
	sortType,
	collectionId,
	sortedProductsFromStore,
	allProducts,
	totalProductCount,
}: {
	sortType?: SiteEcommerceSortingValue,
	collectionId?: string,
	sortedProductsFromStore?: EcommerceProduct[],
	allProducts: EcommerceProduct[],
	totalProductCount: number,
}) => !!((sortType || collectionId) && sortedProductsFromStore?.length)
	|| (totalProductCount !== 0 && allProducts?.length === totalProductCount);

export const fillSeoSettingsForProducts = ({
	products,
	sitePages,
}: {products: Array<EcommerceProduct>, sitePages:SitePages}): Array<EcommerceProduct> => {
	const existingPageSlugs: Array<string> = Object.values(sitePages)
		.flatMap((page) => (page.slug ? [page.slug] : []));
	const existingProductSlugs: Array<string> = [];

	const ecommerceProductsWithUpdatedSeo = products.map((product: EcommerceProduct) => {
		const parsedDescriptionHtml = new DOMParser().parseFromString(product.description, 'text/html');
		const description = parsedDescriptionHtml ? extractText(parsedDescriptionHtml) : '';

		const productSlug = generatePageSlug({
			initialSlug: product.title,
			existingSlugs: [
				...existingProductSlugs,
				...existingPageSlugs,
			],
			slugSuffix: '-product',
		});

		existingProductSlugs.push(productSlug);

		const updatedProduct = {
			...product,
			seo_settings: {
				...(product.thumbnail ? {
					ogImagePath: product.thumbnail,
				} : {}),
				ogImageOrigin: 'other',
				ogImageAlt: product.title,
				description,
				slug: productSlug,
				title: product.title,
			},
		};

		return updatedProduct;
	});

	return ecommerceProductsWithUpdatedSeo;
};
