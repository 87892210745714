<script setup lang="ts">
import ZyroDrawer from '@/components/global/ZyroDrawer.vue';
import HImage from '@/components/global/HImage.vue';
import PageSettingsSeoGeneral from '@/components/PageSettingsSeoGeneral.vue';
import Navigation from '@/components/builder-drawers/drawers/partials/seoDrawer/Navigation.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import { useStore } from 'vuex';
import { OPEN_MODAL } from '@/store/builder/gui';

import { HTML_LANG_VALUES } from '@/data/htmlLangValues';

import {
	DRAWER_SEO,
	MODAL_WEBSITE_EDIT,
} from '@/constants';
import {
	ref,
	computed,
	onMounted,
	onUnmounted,
} from 'vue';
import { useSidebar } from '@/use/useSidebar';
import { useSeo } from '@/use/useSeo';
import Icon from '@/components/global/Icon.vue';
import { ILanguage } from '@/types/seoTypes';
import { PAGE_TYPE_BLOG } from '@zyro-inc/site-modules/constants';
import { useSeoStore } from '@/stores/seoStore';
import { useI18n } from 'vue-i18n';
import { isProductPage } from '@zyro-inc/site-modules/utils/ecommerce';

const {
	getters,
	dispatch,
	state,
} = useStore();
const seoStore = useSeoStore();

const { closeInnerSidebar } = useSidebar();
const {
	allPageSeoStatuses,
	isPageWithSingleH1,
} = useSeo();
const { t } = useI18n();

const pageToOpen = ref(null);
const shouldScrollIntoSlugField = ref(false);
const metaTitle = computed(() => getters.currentLanguageData.metaTitle ?? getters.siteMeta.metaTitle);
const isOverviewSelected = ref(true);
const metaHtmlLanguage = computed<ILanguage | Record<string, never>>(
	() => HTML_LANG_VALUES.find(({ value }) => value === getters.siteMeta.metaHtmlLanguage)
	|| {
		title: '',
		value: '',
	},
);
const defaultPages = computed(() => getters.defaultPages);
const currentPageId = computed(() => state.currentPageId);
const homePage = computed(() => defaultPages.value[getters.homePageId]);
const homePageMeta = computed(() => homePage.value.meta ?? {});
const areHomepageMetaTitleAndDescriptionFilled = computed(() => homePageMeta.value.title && homePageMeta.value.description);

const areAllPagesCompletedSeo = computed(() => !Object.keys(seoStore.defaultNavigationPages)
	.map((key) => allPageSeoStatuses.value[key])
	.filter((item) => !item).length);

const isBrandNameAndLanguageDone = computed(() => metaTitle.value && metaHtmlLanguage.value);

const isCheckerListVisible = computed(() => !areAllPagesCompletedSeo.value || !isBrandNameAndLanguageDone.value);

const openEditWebsiteModal = () => {
	dispatch(`gui/${OPEN_MODAL}`, {
		name: MODAL_WEBSITE_EDIT,
	});
};

const openPageSEODrawer = ({ pageId }: { pageId: string }) => {
	dispatch('gui/OPEN_DRAWER', {
		id: DRAWER_SEO,
		settings: {
			pageId,
			goBackCallback: () => {
				dispatch('gui/TOGGLE_DRAWER', DRAWER_SEO);
			},
		},
	});
};

const selectPage = (key: string) => {
	isOverviewSelected.value = false;

	dispatch('updateCurrentPageId', key);
};

const handleOverviewClick = () => {
	isOverviewSelected.value = true;
};

const pagesList = computed<Record<string, any>>(() => getters.sitePages);
const seoDrawerTitle = computed(() => (isOverviewSelected.value ? t('builder.seoCheckerTitle')
	: `${t('builder.seoCheckerTitle')} - ${pagesList.value[currentPageId.value].name}`));

const showH1Suggestion = computed(() => {
	const pageType = pagesList.value[currentPageId.value].type;

	if (PAGE_TYPE_BLOG === pageType || isProductPage(pageType) || isPageWithSingleH1.value) {
		return false;
	}

	return true;
});

const handleGeneralPageClick = (pageId: string) => {
	if (!allPageSeoStatuses.value[pageId]) {
		selectPage(pageId);
	}
};

const closeDrawer = () => {
	pageToOpen.value = null;
};

onMounted(() => {
	const pageId = state.gui.activeDrawerSettings?.pageId;
	const isCheckoutSlugError = state.gui.activeDrawerSettings?.isCheckoutSlugError;

	if (pageId) {
		selectPage(pageId);

		if (isCheckoutSlugError) {
			shouldScrollIntoSlugField.value = true;

			setTimeout(() => {
				shouldScrollIntoSlugField.value = false;
			}, 1000);
		}

		return;
	}

	if (currentPageId.value) {
		selectPage(currentPageId.value);

		return;
	}

	if (!areHomepageMetaTitleAndDescriptionFilled.value) {
		openPageSEODrawer({
			pageId: getters.homePageId,
		});
	}
});

onUnmounted(() => {
	dispatch('saving/saveWebsite');
});

</script>

<template>
	<ZyroDrawer
		v-qa="'builder-sidebar-seo'"
		class="seo-drawer"
		:header-title="seoDrawerTitle"
		@close="closeDrawer"
		@back="closeInnerSidebar"
	>
		<template #body>
			<div class="seo-drawer__wrapper">
				<Navigation
					:handle-overview-click="handleOverviewClick"
					:is-overview-selected="isOverviewSelected"
					:select-page="selectPage"
				/>
				<div class="seo-drawer__content-container">
					<template v-if="isOverviewSelected">
						<div class="site-info">
							<div class="site-info__title text-bold-2">
								<p>
									{{ $t('builder.seoCheckerSiteInfo') }}
								</p>
							</div>
							<div class="site-info__content">
								<div class="site-info__row">
									<p class="text-bold-2">
										{{ $t('builder.seoCheckerBrandNameLabel') }}
									</p>
									<p class="text-body-2">
										{{ metaTitle }}
									</p>
								</div>
								<div class="site-info__row">
									<p class="text-bold-2">
										{{ $t('builder.seoCheckerSiteLanguage') }}
									</p>
									<p class="text-body-2">
										{{ metaHtmlLanguage.title }}
									</p>
								</div>
								<div class="site-info__row">
									<HostingerButton
										theme="primary"
										button-type="outlined"
										@click="openEditWebsiteModal"
									>
										{{ $t('common.edit') }}
									</HostingerButton>
								</div>
							</div>
						</div>

						<div
							v-if="isCheckerListVisible"
							class="site-info general-improvements"
						>
							<h3
								class="site-info__title site-info__title--padding-top text-bold-2"
								data-qa="seo-drawer-overview-title"
							>
								{{ $t('builder.seoCheckerIssuesContainerTitle') }}
							</h3>
							<div class="site-info__content">
								<div
									class="general-improvements__item"
									:class="{ 'general-improvements__item--completed' : isBrandNameAndLanguageDone }"
								>
									<div class="general-improvements__item-content general-improvements__item-content--completed">
										<Icon
											class="seo-drawer__status-icon"
											:name="isBrandNameAndLanguageDone ? 'check_circle' : 'error'"
											is-filled
											dimensions="20px"
											:class="{ 'seo-drawer__status-icon--completed': isBrandNameAndLanguageDone }"
										/>
										<p class="text-body-2">
											{{ $t('builder.seoCheckerBrandAndLanguage') }}
										</p>
									</div>
									<Icon
										v-if="!isBrandNameAndLanguageDone"
										class="seo-drawer__chevron"
										name="chevron_right"
										dimensions="24px"
									/>
								</div>
								<div
									v-for="(page, key) in seoStore.defaultNavigationPages"
									:key="`${key}-page`"
									class="general-improvements__item"
									@click="handleGeneralPageClick(key as string)"
								>
									<div
										class="general-improvements__item-content"
										:class="{ 'general-improvements__item-content--completed': allPageSeoStatuses[key] }"
									>
										<Icon
											class="seo-drawer__status-icon"
											:name="allPageSeoStatuses[key] ? 'check_circle' : 'error'"
											is-filled
											dimensions="20px"
											:class="{ 'seo-drawer__status-icon--completed': allPageSeoStatuses[key] }"
										/>
										<i18n-t
											class="text-body-2"
											tag="p"
											keypath="builder.seoCheckerImprovementsFound"
										>
											<b>{{ page.name }}</b>
										</i18n-t>
									</div>
									<Icon
										v-if="!allPageSeoStatuses[key]"
										class="seo-drawer__chevron"
										name="chevron_right"
										dimensions="24px"
									/>
								</div>
							</div>
						</div>

						<div class="site-info">
							<div class="site-info__title site-info__title--padding-top text-bold-2">
								<p>
									{{ $t('builder.seoCheckerHelpResources') }}
								</p>
							</div>
							<div class="site-info__content">
								<a
									class="z-link text-body-2 site-info__link"
									href="https://support.hostinger.com/en/articles/3692620-how-to-add-a-domain-to-google-search-console"
									target="_blank"
								>
									{{ $t('builder.seoDrawer.quickLinkGoogleSearchConsole') }}
									<Icon
										name="open_in_new"
										dimensions="20px"
									/>
								</a>
								<a
									class="z-link text-body-2 site-info__link"
									href="https://www.google.com/business/"
									target="_blank"
								>
									{{ $t('builder.seoDrawer.quickLinkGoogleBusiness') }}
									<Icon
										name="open_in_new"
										dimensions="20px"
									/>
								</a>
								<a
									class="z-link text-body-2 site-info__link"
									href="https://support.hostinger.com/en/articles/6448761-website-builder-how-to-make-a-website-appear-on-google"
									target="_blank"
								>
									{{ $t('builder.seoDrawer.quickLinkImproveSeo') }}
									<Icon
										name="open_in_new"
										dimensions="20px"
									/>
								</a>
							</div>
						</div>
					</template>
					<template v-else>
						<div class="checker-card seo-checker">
							<div class="checker-card__content">
								<PageSettingsSeoGeneral :should-scroll-into-slug-field="shouldScrollIntoSlugField" />
							</div>
						</div>

						<div
							v-if="showH1Suggestion"
							class="checker-card h1-section"
						>
							<div class="checker-card__header checker-card__header-with-icon">
								<Icon
									class="seo-drawer__status-icon"
									name="error"
									is-filled
									dimensions="20px"
								/>
								<p class="h1-section__title text-bold-2">
									{{ $t('builder.seoCheckerH1SectionTitle') }}
								</p>
							</div>
							<div class="checker-card__content">
								<p class="text-body-2">
									{{ $t('builder.seoCheckerH1SectionDescription') }}
								</p>

								<h4 class="text-bold-2 h1-section__suggestion-title">
									{{ $t('builder.seoCheckerH1SectionSuggestionTitle') }}
								</h4>
								<p class="text-body-2 h1-section__suggestion">
									{{ $t('builder.seoCheckerH1SectionSuggestionTop') }}
								</p>
								<p class="text-body-2">
									{{ $t('builder.seoCheckerH1SectionSuggestion') }}
								</p>
								<div class="h1-section__image-container">
									<HImage
										class="h1-section__image"
										src="/images/h1-instruction-example.png"
									/>
								</div>
							</div>
						</div>
					</template>
				</div>
			</div>
		</template>
	</ZyroDrawer>
</template>

<style lang="scss" scoped>
.seo-drawer {
	width: 900px;

	&__content-container {
		flex-grow: 1;
		padding: 24px;
		overflow-y: auto;

		@media screen and (max-width: $media-mobile) {
			padding: 16px;
		}
	}

	&__wrapper {
		display: flex;
		height: 100%;
		border-top: 1px solid var(--color-gray-border);

		@media screen and (max-width: $media-mobile) {
			flex-direction: column;
		}
	}

	&__chevron {
		flex-shrink: 0;
		cursor: pointer;
		transition: transform 0.2s ease-in-out;
	}

	&__status-icon {
		flex-shrink: 0;
		color: $color-warning-dark;

		&--completed {
			color: $color-success;
		}
	}
}

:deep(.drawer-body__content) {
	padding: 0;
	overflow: hidden;
}

.checker-card {
	background-color: $color-light;
	border-radius: 16px;
	margin-bottom: 16px;

	&__content {
		&--padding-top {
			padding-top: 16px;
		}
	}

	&__header {
		padding: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid $color-gray-border;
	}

	&__header-with-icon {
        justify-content: flex-start;
        gap: 8px;
	}

	&__links-container {
		padding: 16px 16px 16px 0;
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
}

.h1-section {
	color: $color-gray;

	&__title {
		color: $color-dark;
	}

	&__image-container {
		border-radius: 8px;
		margin-top: 8px;
		overflow: hidden;
	}

	&__image {
		display: block;
	}

	&__suggestion-title {
		margin-top: 16px;
		color: $color-dark;
	}

	&__suggestion {
		margin: 8px 0
	}
}

.site-info {
	&__title {
		padding-bottom: 8px;

		&--padding-top {
			padding-top: 32px;
		}
	}

	&__content {
		border: 1px solid var(--color-gray-border);
		padding: 16px;
		border-radius: 8px;
	}

    &__link {
			width: 100%;
			display: inline-flex;
			align-items: center;
			justify-content: space-between;
			gap: 8px;
			margin-bottom: 8px;
	}

	&__row {
		display: flex;
		gap: 8px;

		&:not(:last-child) {
			margin-bottom: 8px;
		}

		&:last-child {
			margin-top: 16px;
		}
	}
}

.seo-checker {
	&__heading {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 8px;
	}
}

.general-improvements {
	&__item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid $color-gray-border;
		padding: 16px 0;

		&:first-child {
			padding-top: 0;
		}

		&:last-child {
			border-bottom: none;
			padding-bottom: 0;
			margin-bottom: 0;
		}
	}

	&__item-content {
		display: flex;
		align-items: top;
		gap: 8px;
		cursor: pointer;

		&--completed {
			cursor: unset;
		}
	}
}

</style>
