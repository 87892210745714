import {
	ref,
	computed,
} from 'vue';
import { useStore } from 'vuex';
import { getTemplateStylesCssVariables } from '@/utils/getTemplateStylesCssVariables';

import {
	BLOCK_TYPE_NAVIGATION,
	BLOCK_TYPE_BLOG_HEADER,
	BLOCK_TYPE_STICKY_BAR,
} from '@zyro-inc/site-modules/constants';
import {
	BLOCKS_ECOMMERCE,
	DEMO_PRODUCTS,
} from '@zyro-inc/site-modules/constants/ecommerce';
import { filterObject } from '@zyro-inc/site-modules/utils/object';
import EventLogApi from '@/api/EventLogApi';
import {
	getBlogListBlock,
	getEcommerceProductBlock,
	getEcommerceProductListBlock,
	getLayoutBlock,
} from '@/components/block/blocks';
import template from '@/data/AddLayoutBlockModalTemplate.json';
import {
	CLOSE_MODAL,
	OPEN_MODAL,
} from '@/store/builder/gui';
import {
	MODAL_LEGAL_CONTENT_GENERATOR,
	BLOCK_CATEGORY_BLOG,
	BLOCK_CATEGORY_LITE_STORE,
	BLOCK_CATEGORY_FOOTER,
	BLOCK_CATEGORY_ABOUT,
	LEGAL_CONTENT_BLOCKS,
	MODAL_GENERATE_SECTION,
	BLOCK_CATEGORY_MAIN,
	BLOG_POST_EXAMPLE_POST_COUNT,
	BLOG_POST_EXAMPLE_POSTS_PER_PAGE_COUNT,
	BLOG_POST_EXAMPLE_POST_COLUMN_COUNT,
	ADD_MODAL_ECOMMERCE_SINGLE_PRODUCT,
	ADD_MODAL_ECOMMERCE_PRODUCT_LIST,
} from '@/constants';
import { useI18n } from 'vue-i18n';
import { useAddBlock } from '@/use/useAddBlock';
import { getExampleBlogPostsData } from '@/use/useAddPage';
import { BLOG_PLACEHOLDERS } from '@/data';
import { getFilteredProductsByCategoryId } from '@/utils/ecommerce';
import { useUserStore } from '@/stores/userStore';
import { useSiteStore } from '@/stores/siteStore';
import { useEcommerceStore } from '@/stores/ecommerceStore';
import { isProductPage } from '@zyro-inc/site-modules/utils/ecommerce';

const BLOCK_PRODUCT_LIST_ID_1 = `${ADD_MODAL_ECOMMERCE_PRODUCT_LIST}1`;
const BLOCK_PRODUCT_LIST_ID_2 = `${ADD_MODAL_ECOMMERCE_PRODUCT_LIST}2`;
const BLOCK_PRODUCT_LIST_ID_3 = `${ADD_MODAL_ECOMMERCE_PRODUCT_LIST}3`;
const BLOCK_PRODUCT_LIST_ID_4 = `${ADD_MODAL_ECOMMERCE_PRODUCT_LIST}4`;
const BLOCK_PRODUCT_LIST_ID_5 = `${ADD_MODAL_ECOMMERCE_PRODUCT_LIST}5`;
const BLOCK_PRODUCT_LIST_ID_6 = `${ADD_MODAL_ECOMMERCE_PRODUCT_LIST}6`;
const BLOCK_PRODUCT_LIST_ID_7 = `${ADD_MODAL_ECOMMERCE_PRODUCT_LIST}7`;
const BLOCK_PRODUCT_LIST_ID_8 = `${ADD_MODAL_ECOMMERCE_PRODUCT_LIST}8`;

export const useAddBlockModal = () => {
	const {
		state,
		getters,
		dispatch,
	} = useStore();
	const { t } = useI18n();
	const { activeModalSettings } = state.gui;
	const isCurrentPagePrivate = computed(() => getters.isCurrentPagePrivate);
	const isLocaleWithEcommerceItems = getters['ecommerce/isLocaleWithEcommerceItems'];
	const { addBlock } = useAddBlock();

	const siteStore = useSiteStore();
	const userStore = useUserStore();
	const ecommerceStore = useEcommerceStore();

	// Overriding website styles with templates styles for font-size, font-weight and line-height
	// otherwise global styles breaks design of elements inside andBlockModal
	const computedStyles = computed(() => getTemplateStylesCssVariables({
		templateStyles: template.styles,
		websiteStyles: siteStore.site.styles,
	}));

	// Custom blog logic
	const showBlogListChoice = ref(false);

	// Custom footer logic
	const showFooterGlobalChoice = ref(false);
	const doesFooterExist = computed(() => getters.doesFooterExist);

	const selectedBlockId = ref(null);

	const categories = {
		[BLOCK_CATEGORY_BLOG]: {
			prependIcon: 'checkbook',
			name: t('common.blog'),
		},
		...(!userStore.areFeaturesLocked && !isCurrentPagePrivate.value
			&& {
				[BLOCK_CATEGORY_LITE_STORE]: {
					prependIcon: 'shopping_cart',
					name: t('common.onlineStoreTitle'),
				},
			}),
		separator: {
			type: 'separator',
		},
		main: {
			name: t('common.main'),
		},
		[BLOCK_CATEGORY_ABOUT]: {
			name: t('common.about'),
		},
		services: {
			name: t('common.services'),
		},
		'product-categories': {
			name: t('common.productCategories'),
		},
		'contact-form': {
			name: t('common.contactForm'),
		},
		'subscribe-form': {
			name: t('common.subscribeForm'),
		},
		map: {
			name: t('common.map'),
		},
		testimonials: {
			name: t('common.testimonials'),
		},
		projects: {
			name: t('common.projects'),
		},
		resume: {
			name: t('common.resume'),
		},
		images: {
			name: t('common.images'),
		},
		gallery: {
			name: t('common.gallery'),
		},
		slideshow: {
			name: t('common.slideshow'),
		},
		video: {
			name: t('common.video'),
		},
		'instagram-feed': {
			name: t('common.instagramFeed'),
		},
		'social-icons': {
			name: t('common.socialIcons'),
		},
		menu: {
			name: t('common.menu'),
		},
		faq: {
			name: t('common.faq'),
		},
		[BLOCK_CATEGORY_FOOTER]: {
			name: t('common.footer'),
			icon: 'link',
		},
		separator2: {
			type: 'separator',
		},
		'privacy-policy': {
			name: t('common.privacyPolicy'),
		},
		'refund-policy': {
			name: t('common.refundPolicy'),
		},
		'terms-and-conditions': {
			name: t('common.termsAndConditions'),
		},
	};

	// Current sidebar category
	const currentCategory = ref('');
	const currentCategoryId = computed({
		get: () => {
			if (currentCategory.value) {
				return currentCategory.value;
			}

			return BLOCK_CATEGORY_MAIN;
		},
		set: (value) => {
			currentCategory.value = value;
		},
	});

	// Current selected category blocks
	const selectedCategoryBlocks = computed(() => {
		const nonAddableBlocks = [
			BLOCK_TYPE_NAVIGATION,
			BLOCK_TYPE_BLOG_HEADER,
			BLOCK_TYPE_STICKY_BAR,
		];

		if (!template) {
			return null;
		}

		if (currentCategoryId.value === BLOCK_CATEGORY_BLOG) {
			return {
				blog: getBlogListBlock({
					mockCategories: true,
					postsPerPage: 6,
				}),
				blog2: getBlogListBlock({
					mockCategories: true,
					postsPerPage: 6,
					postColumnCount: 3,
				}),
				blog3: getBlogListBlock({
					mockCategories: true,
					postColumnCount: 4,
				}),
			};
		}

		if (currentCategoryId.value === BLOCK_CATEGORY_LITE_STORE) {
			const ecommerceProductLists = {
				[BLOCK_PRODUCT_LIST_ID_1]: getEcommerceProductListBlock({
					products: DEMO_PRODUCTS,
					textAlign: 'outlined',
					blockPadding: '100px 16px',
					imageRatio: 'portrait',
					imageHoverEffect: 'swap_image',
					columnCountDesktop: 3,
					columnGapDesktop: 20,
					productsPerPage: 6,
					isButtonEnabled: true,
					buttonDisplay: 'always',
					isButtonFullWidth: true,
					isFullWidth: true,
					isSortingEnabled: true,
					isCategoryListEnabled: true,
					isTotalProductCountShown: true,
					buttonBorderWidth: 1,
					buttonStyle: {
						'grid-button-primary-color': 'rgb(0, 0, 0)',
						'grid-button-primary-background-color': 'rgba(0, 0, 0, 0)',
						'grid-button-primary-border-color': 'rgb(0, 0, 0)',
					},
				}),
				[BLOCK_PRODUCT_LIST_ID_2]: getEcommerceProductListBlock({
					products: DEMO_PRODUCTS,
					textAlign: 'center',
					blockPadding: '50px 16px 50px 16px',
					imageRatio: 'portrait',
					imageHoverEffect: 'zoom',
					isFullWidth: true,
					columnCountDesktop: 4,
					columnGapDesktop: 20,
					isSortingEnabled: true,
					productsPerPage: 8,
					buttonDisplay: 'hover',
					isButtonEnabled: true,
					isTotalProductCountShown: true,
					buttonBorderWidth: 1,
					buttonStyle: {
						'grid-button-primary-color': 'rgb(0, 0, 0)',
						'grid-button-primary-background-color': 'rgba(0, 0, 0, 0)',
						'grid-button-primary-border-color': 'rgb(0, 0, 0)',
					},
				}),
				[BLOCK_PRODUCT_LIST_ID_3]: getEcommerceProductListBlock({
					products: DEMO_PRODUCTS,
					textAlign: 'outlined',
					blockPadding: '100px 16px',
					imageRatio: 'landscape',
					imageHoverEffect: 'swap_image',
					columnCountDesktop: 3,
					columnGapDesktop: 20,
					productsPerPage: 6,
					isButtonEnabled: true,
					buttonDisplay: 'always',
					isButtonFullWidth: true,
					isFullWidth: true,
					isSortingEnabled: true,
					isCategoryListEnabled: true,
					isTotalProductCountShown: true,
				}),
				[BLOCK_PRODUCT_LIST_ID_4]: getEcommerceProductListBlock({
					products: DEMO_PRODUCTS,
					textAlign: 'left',
					blockPadding: '10px 16px 10px 16px',
					imageHoverEffect: 'swap_image',
					imageRatio: 'landscape',
					columnCountDesktop: 3,
					columnGapDesktop: 20,
					productsPerPage: 10,
					buttonDisplay: 'hover',
					isButtonEnabled: true,
					isFullWidth: true,
				}),
				[BLOCK_PRODUCT_LIST_ID_5]: getEcommerceProductListBlock({
					products: DEMO_PRODUCTS,
					textAlign: 'outlined',
					blockPadding: '20px 16px 20px 16px',
					imageRatio: 'portrait',
					columnCountDesktop: 4,
					isFullWidth: true,
					columnGapDesktop: 40,
					isSortingEnabled: false,
					productsPerPage: 4,
				}),
				[BLOCK_PRODUCT_LIST_ID_6]: getEcommerceProductListBlock({
					products: DEMO_PRODUCTS,
				}),
				[BLOCK_PRODUCT_LIST_ID_7]: getEcommerceProductListBlock({
					products: DEMO_PRODUCTS,
					textAlign: 'center',
					blockPadding: '15px 16px 15px 16px',
					imageRatio: 'portrait',
					columnCountDesktop: 5,
					productsPerPage: 5,
					buttonDisplay: 'hover',
					isButtonEnabled: true,
					buttonBorderWidth: 1,
					buttonStyle: {
						'grid-button-primary-color': 'rgb(0, 0, 0)',
						'grid-button-primary-background-color': 'rgba(0, 0, 0, 0)',
						'grid-button-primary-border-color': 'rgb(0, 0, 0)',
					},
				}),
				[BLOCK_PRODUCT_LIST_ID_8]: getEcommerceProductListBlock({
					products: DEMO_PRODUCTS,
					textAlign: 'center',
					blockPadding: '16px 16px 16px 16px',
					imageRatio: 'portrait',
					imageHoverEffect: 'swap_image',
					columnCountDesktop: 3,
					columnGapDesktop: 0,
					columnCountMobile: 2,
					columnGapMobile: 0,
					productsPerPage: 3,
					buttonDisplay: 'hover',
					isButtonEnabled: true,
					isListCentered: true,
				}),
			};

			if (isProductPage(getters.currentPage?.type)) {
				return ecommerceProductLists;
			}

			return {
				...ecommerceProductLists,
				[ADD_MODAL_ECOMMERCE_SINGLE_PRODUCT]: getEcommerceProductBlock(),
			};
		}

		const pageBlocks = template.languages.system.pages[currentCategoryId.value]?.blocks || [];

		const filteredBlocks = filterObject(
			template.languages.system.blocks,
			({
				key,
				value,
			}) => pageBlocks.includes(key) && !nonAddableBlocks.includes(value.type),
		);

		return filteredBlocks;
	});

	const selectedCategoryEcommerceSettings = computed(() => {
		if (currentCategoryId.value === BLOCK_CATEGORY_LITE_STORE) {
			return {
				[BLOCK_PRODUCT_LIST_ID_1]: {
					productCount: 6,
				},
				[BLOCK_PRODUCT_LIST_ID_2]: {
					productCount: 8,
				},
				[BLOCK_PRODUCT_LIST_ID_3]: {
					productCount: 6,
				},
				[BLOCK_PRODUCT_LIST_ID_4]: {
					productCount: 6,
				},
				[BLOCK_PRODUCT_LIST_ID_5]: {
					productCount: 4,
				},
				[BLOCK_PRODUCT_LIST_ID_6]: {
					productCount: 6,
				},
				[BLOCK_PRODUCT_LIST_ID_7]: {
					productCount: 5,
				},
				[BLOCK_PRODUCT_LIST_ID_8]: {
					productCount: 3,
				},
			};
		}

		return {};
	});

	const blockDescriptions = computed(() => (
		currentCategoryId.value === BLOCK_CATEGORY_LITE_STORE
			? {
				[ADD_MODAL_ECOMMERCE_PRODUCT_LIST]: {
					title: t('builder.productList'),
					text: t('builder.productListDescription'),
				},
				...(isProductPage(getters.currentPage?.type)
					? []
					: {
						[ADD_MODAL_ECOMMERCE_SINGLE_PRODUCT]: {
							title: t('builder.singleProduct'),
							text: t('builder.singleProductDescription'),
						},
					}),
			}
			: {}));

	// Current selected category components
	const templateElements = computed(() => {
		if (currentCategoryId.value === BLOCK_CATEGORY_BLOG) {
			return {};
		}

		return template.languages.system.elements;
	});

	async function addNewBlock({
		blockId,
		blockData,
		addToPage,
		slot,
	}) {
		const { previousBlockId } = activeModalSettings;
		const isBlockEcommerce = BLOCKS_ECOMMERCE.includes(blockData?.type);
		const isLegalContentBlock = LEGAL_CONTENT_BLOCKS.includes(currentCategoryId.value);

		// If no blockId given, add a default empty block
		if (!blockId && !addToPage) {
			dispatch('addBlock', {
				pageId: state.currentPageId,
				blockData: getLayoutBlock(),
				previousBlockId,
			});

			dispatch(`gui/${CLOSE_MODAL}`);

			return;
		}

		// Show popup before adding blog
		if (currentCategoryId.value === BLOCK_CATEGORY_BLOG && !addToPage) {
			if (!getters['blog/isLocaleWithBlogList']) {
				showBlogListChoice.value = true;

				return;
			}

			const blogPostsData = getExampleBlogPostsData({
				postTitle: t('builder.blog.blogPost.title'),
				postDescription: t('builder.blog.blogPost.description'),
				postContent: t('builder.blog.blogPageElements.content'),
				postThumbnails: BLOG_PLACEHOLDERS,
				postCount: BLOG_POST_EXAMPLE_POST_COUNT,
			});

			dispatch('addBlog', {
				pageTitle: t('common.blog'),
				blogPostsData,
				existingPageId: state.currentPageId,
				existingPagePreviousBlockId: previousBlockId,
				postsPerPage: blockData.settings.postsPerPage,
				postColumnCount: blockData.settings.postColumnCount,
			});

			dispatch(`gui/${CLOSE_MODAL}`);

			return;
		}

		// Show popup before adding footer
		if ([BLOCK_CATEGORY_FOOTER].includes(currentCategoryId.value)
			&& !doesFooterExist.value
			&& !showFooterGlobalChoice.value
			&& !isCurrentPagePrivate.value
		) {
			showFooterGlobalChoice.value = true;

			return;
		}

		// If the current element is bloglist, handle it specifically.
		if (blockData.type === 'BlockBlogList') {
			const blogPostsData = getExampleBlogPostsData({
				postTitle: t('builder.blog.blogPost.title'),
				postDescription: t('builder.blog.blogPost.description'),
				postContent: t('builder.blog.blogPageElements.content'),
				postThumbnails: BLOG_PLACEHOLDERS,
				postCount: BLOG_POST_EXAMPLE_POST_COUNT,
			});

			dispatch('addBlog', {
				pageTitle: t('common.blog'),
				blogPostsData,
				existingPageId: state.currentPageId,
				existingPagePreviousBlockId: previousBlockId,
				postsPerPage: blockData.settings.postsPerPage,
				postColumnCount: blockData.settings.postColumnCount,
			});
			dispatch(`gui/${CLOSE_MODAL}`);

			return;
		}

		if (isBlockEcommerce) {
			// Before adding ecommerce block, try to map correct productIds
			const storeProducts = ecommerceStore.products;
			const { productCategoryId } = blockData;

			const filteredProducts = productCategoryId
				? getFilteredProductsByCategoryId(storeProducts, productCategoryId)
				: storeProducts.sort((a, b) => a.title.localeCompare(b.title));

			dispatch('addBlock', {
				pageId: state.currentPageId,
				blockData: {
					...blockData,
					productIds: filteredProducts.map((product) => product.id),
				},
				previousBlockId,
			});

			dispatch(`gui/${CLOSE_MODAL}`);

			if (!isLocaleWithEcommerceItems) {
				EventLogApi.logEvent({
					eventName: 'website_builder.ecomm.store_added',
					eventProperties: {
						location: 'section',
						website_id: state.websiteId,
					},
				});
			}

			return;
		}

		if (isLegalContentBlock) {
			dispatch(`gui/${CLOSE_MODAL}`);
			dispatch(`gui/${OPEN_MODAL}`, {
				name: MODAL_LEGAL_CONTENT_GENERATOR,
				settings: {
					legalContentType: currentCategoryId.value,
					blockId,
					previousBlockId,
				},
			});

			return;
		}

		addBlock({
			blockId,
			slot,
			template,
		});

		dispatch(`gui/${CLOSE_MODAL}`);
	}

	function addBlogList({
		postsPerPage = BLOG_POST_EXAMPLE_POSTS_PER_PAGE_COUNT,
		postColumnCount = BLOG_POST_EXAMPLE_POST_COLUMN_COUNT,
	}) {
		addNewBlock({
			blockId: 'blockBlogList',
			blockData: getBlogListBlock({
				mockCategories: false,
				postsPerPage,
				postColumnCount,
			}),
			addToPage: true,
		});
	}

	function presetDefaultCategoryId() {
		if (activeModalSettings.categoryId) {
			currentCategoryId.value = activeModalSettings.categoryId;
		}
	}

	const generateNewSection = () => {
		dispatch(`gui/${CLOSE_MODAL}`);

		dispatch(`gui/${OPEN_MODAL}`, {
			name: MODAL_GENERATE_SECTION,
		});

		EventLogApi.logEvent({
			eventName: 'website_builder.add_section.generate_section',
		});
	};

	const setSelectedBlockId = (blockId) => {
		selectedBlockId.value = blockId;
	};

	presetDefaultCategoryId();

	return {
		template,
		categories,
		templateElements,
		doesFooterExist,
		selectedBlockId,
		currentCategoryId,
		addNewBlock,
		selectedCategoryBlocks,
		selectedCategoryEcommerceSettings,
		blockDescriptions,
		showBlogListChoice,
		showFooterGlobalChoice,
		addBlogList,
		computedStyles,
		generateNewSection,
		setSelectedBlockId,
	};
};
