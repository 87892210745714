// eslint-disable-next-line import/no-cycle
import axios from '@/services/http/axios';

const ECOMMERCE_API = `${import.meta.env.VITE_BACKEND_API_URL}/ecomm`;

export const createStoreWithProducts = async ({
	siteId,
	products,
}) => {
	const { data } = await axios.post(`${ECOMMERCE_API}/${siteId}`, {
		products,
	});

	return data;
};
