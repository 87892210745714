import {
	ref,
	computed,
} from 'vue';
import { PINIA_STORES } from '@/constants/stores';
import {
	PAGE_ID_PRIVATE,
	PAGE_TYPE_BLOG,
	PAGE_TYPE_ECOMMERCE_PRODUCT,
	PAGE_TYPE_PRIVATE,
} from '@zyro-inc/site-modules/constants';
import {
	SEO_DRAWER_TAB_ECOMMERCE,
	SEO_DRAWER_TAB_DEFAULT,
} from '@/constants';
import { defineStore } from 'pinia';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { SeoDrawerTab } from '@/types/seoTypes';
import { isProductPage } from '@zyro-inc/site-modules/utils/ecommerce';

interface Page {
  name: string;
  slug: string;
  type: string;
}
const ITEMS_PER_PAGE = 25;

export const useSeoStore = defineStore(PINIA_STORES.SEO, () => {
	const { getters } = useStore();
	const { t } = useI18n();

	const seoDrawerTabs: SeoDrawerTab[] = [
		{
			id: SEO_DRAWER_TAB_DEFAULT,
			label: t('builder.seoDrawer.mainPages'),
		},
		{
			id: SEO_DRAWER_TAB_ECOMMERCE,
			label: t('builder.seoDrawer.productPages'),
		},
	];

	const searchValue = ref('');
	const currentTab = ref(seoDrawerTabs[0]);
	const pagesToDisplay = ref<Record<string, Page>>({});
	const currentPage = ref(1);

	const sitePages = computed<Page[]>(() => getters.sitePages);
	const defaultNavigationPages = computed(() => Object.fromEntries(
		Object.entries(sitePages.value).filter(
			([_, page]) => !([
				PAGE_TYPE_BLOG,
				PAGE_TYPE_PRIVATE,
			].includes(page.type) && isProductPage(page.type)),
		),
	));
	const ecommercePages = computed(() => Object.fromEntries(
		Object.entries(sitePages.value).filter(
			([_, page]) => page.type === PAGE_TYPE_ECOMMERCE_PRODUCT,
		),
	));
	const currentTabPages = computed(() => (
		currentTab.value.id === SEO_DRAWER_TAB_ECOMMERCE
			? ecommercePages.value
			: defaultNavigationPages.value
	));
	const totalPages = computed(() => Math.ceil(Object.keys(currentTabPages.value).length / ITEMS_PER_PAGE));

	const setCurrentTab = (tab: { id: string; label: string }) => {
		currentTab.value = tab;
	};

	const setCurrentPage = (page: number) => {
		currentPage.value = page;
	};

	const setSearchValue = (value: string) => {
		searchValue.value = value;
	};

	const filterPagesBySearchQuery = () => {
		const query = searchValue.value.trim();
		const nonAlphabeticCharacters = /[^A-Za-z]/g;
		const escapedQuery = query.replace(nonAlphabeticCharacters, '\\$&');
		const regex = new RegExp(escapedQuery, 'i');

		return Object.fromEntries(
			Object.entries(currentTabPages.value).filter(([_, page]) => regex.test(page.slug) || regex.test(page.name)),
		);
	};

	const getCurrentTabPages = () => {
		const pages = searchValue.value ? filterPagesBySearchQuery() : currentTabPages.value;

		return Object.fromEntries(
			Object.entries(pages).slice(0, ITEMS_PER_PAGE * currentPage.value),
		);
	};

	const setPagesToDisplay = (pages: Record<string, Page>) => {
		// Remove private page from SEO list
		const {
			[PAGE_ID_PRIVATE]: _private,
			...rest
		} = pages;

		pagesToDisplay.value = rest;
	};

	return {
		currentTab,
		setCurrentTab,
		pagesToDisplay,
		currentPage,
		getCurrentTabPages,
		totalPages,
		setPagesToDisplay,
		setCurrentPage,
		currentTabPages,
		defaultNavigationPages,
		ecommercePages,
		seoDrawerTabs,
		searchValue,
		setSearchValue,
	};
});
