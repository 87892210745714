// STORE ENV-INDEPENDENT GLOBAL CONSTANTS HERE
import {
	PROPERTY_FONT_SIZE,
	PROPERTY_LINE_HEIGHT,
	PROPERTY_M_FONT_SIZE,
	PROPERTY_TEXT_DECORATION,
	PROPERTY_TEXT_TRANSFORM,
	TYPOGRAPHY_H1,
	TYPOGRAPHY_H2,
	TYPOGRAPHY_H3,
	TYPOGRAPHY_H4,
	TYPOGRAPHY_H5,
	TYPOGRAPHY_H6,
	TYPOGRAPHY_BODY_LARGE,
	TYPOGRAPHY_BODY,
	TYPOGRAPHY_BODY_SMALL,
	ELEMENT_GRID_BUTTON_PRIMARY,
	ELEMENT_GRID_BUTTON_SECONDARY,
	TYPOGRAPHY_NAV_LINK,
	PROPERTY_LETTER_SPACING,
	PROPERTY_FONT_FAMILY,
	PROPERTY_FONT_WEIGHT,
	PROPERTY_FONT_SECONDARY,
	PROPERTY_FONT_PRIMARY,
	FONT,
} from '@zyro-inc/site-modules/constants/globalStyles';
import {
	ELEMENT_TYPE_BUTTON,
	ELEMENT_TYPE_STRIPE_BUTTON,
	ELEMENT_TYPE_ECOMMERCE_BUTTON,
	ELEMENT_TYPE_MAP,
	ELEMENT_TYPE_VIDEO,
	ELEMENT_TYPE_IMAGE,
	ELEMENT_TYPE_TEXT_BOX,
	ELEMENT_TYPE_FORM,
	ELEMENT_TYPE_INSTAGRAM_FEED,
	ELEMENT_TYPE_SOCIAL_ICONS,
	ELEMENT_TYPE_GALLERY,
	ELEMENT_TYPE_EMBED,
	ELEMENT_TYPE_SHAPE,
	ELEMENT_TYPE_SEARCH_BAR,
} from '@zyro-inc/site-modules/constants';

export const IGNORED_AXIOS_CODES = [
	'ECONNABORTED',
	'ETIMEDOUT',
	'ERR_NETWORK',
];

export const BEARER_TOKEN = 'bearerToken' as const;
export const JWT = 'jwt' as const;
export const AUTH_TOKEN = 'auth_token' as const;

export const DEFAULT_COLOR = 'rgb(255, 255, 255)' as const;

// eslint-disable-next-line no-underscore-dangle
export const _000_WEBHOST_REF = '000webhost';
export const HOSTINGER_REF = 'hostinger';

export const BUILDER_BRAND_HOSTINGER = 'hostinger';
export const BUILDER_BRAND_ZYRO = 'zyro';

export const PANEL_REGRESSION_EMAIL = 'panelregression_';
export const GO_ROBOTS_EMAIL = 'gorobots01011+';
export const GO_ROBOTS_EMAIL_FREE_PUBLISH = 'gorobots01011+freepublish_';
export const REFERRAL_PROGRAM_PRICE = '$20';
export const DEFAULT_BIGGEST_DISCOUNT_PERCENTAGE = '69';
export const SIDEBAR_WIDTH = 56;
export const MULTISELECT_MINIMUM_SELECT_ITEMS_LENGTH = 1;
export const BLOG_POST_EXAMPLE_POSTS_PER_PAGE_COUNT = 4;
export const BLOG_POST_EXAMPLE_POST_COLUMN_COUNT = 2;
export const BLOG_POST_EXAMPLE_POST_COUNT = 6;

export const WWW_REDIRECT_PATHS = {
	HOME: 'home',
	SIGN_UP: 'signup',
	SIGN_IN: 'signin',
	DOMAINS: 'domains',
	SITES: 'sites',
	TEMPLATES: 'templates',
	NEW_TEMPLATE: 'new-template',
	PAYMENTS: 'payments',
	PRICING: 'pricing',
	SUBSCRIPTIONS: 'subscriptions',
	AI_HEATMAP: 'ai/heatmap',
	CLAIM_FREE_DOMAIN: 'my-domains/claim-free-domain',
	SINGLE_SITE_DASHBOARD: 'manage/home',
	SINGLE_SITE_DASHBOARD_DOMAIN: 'manage/domain',
	SINGLE_SITE_DASHBOARD_SUBSCRIPTION: 'manage/subscription',
	SINGLE_SITE_DASHBOARD_EMAIL: 'manage/email',
	CONSULTATION_PURCHASE: 'redirect-to-consultation-purchase',
};
export const HPANEL_REDIRECTS_PATHS = {
	DOMAINS: 'domains',
	ADD_DOMAIN: 'add-domain',
	DOMAIN: 'domain',
	DOMAIN_OVERVIEW: 'domain-overview',
	DOMAIN_DNS: 'dns',
	WEBSITES: 'websites',
	UPGRADE: 'order/upgrade',
	REGISTER_DOMAIN_RESOURCE: 'register-domain-resource',
};
export const ECOMMERCE_REDIRECT_PATHS = {
	BOOKINGS: 'booking',
	CATEGORIES: 'categories',
	CATEGORIES_EDIT: 'categories/edit',
	DISCOUNTS: 'discounts',
	ORDERS: 'orders',
	PRODUCTS: 'products',
	PRODUCTS_ADD: 'products/add',
	PRODUCTS_EDIT: 'products/edit',
	PAYMENT_MANAGEMENT: 'payment-management',
	STORE_SETTINGS: 'store-settings',
	SHIPPING_EDIT: 'store-settings/shipping/edit',
	COMPANY_INFORMATION: 'store-settings/company-information',
	CHECKOUT_SETTINGS: 'store-settings/checkout-settings',
	STORE_LANGUAGE: 'store-settings/checkout-settings#store-language',
} as const;
export const REDIRECT_PARAM_KEYS = {
	RETURN: 'return',
	PLAN: 'plan',
	LANG: 'lang',
	CODE: 'code',
	CATEGORY: 'category',
	SITE_ID: 'siteId',
	DOMAIN_SEARCH: 'domainSearch',
	PLANS_TO_SHOW: 'plansToShow',
	SUBSCRIPTION_ID: 'subscriptionId',
	OPEN_INTERCOM: 'open-intercom',
	ACTIVATE_PLAN: 'activate-plan',
	PRODUCT: 'product',
	FLOW: 'flow',
	REDIRECT_URL: 'redirectUrl',
	DRAWER: 'drawer',
	SECTION: 'section',
	JWT: 'jwt',
};
export const REDIRECT_PARAM_VALUES = {
	RETURN_BUILDER: 'builder',
	RETURN_PUBLISH: 'builder-publish',
	RETURN_STOREFRONT: 'builder-storefront',
	RETURN_DOMAIN_CONNECTION: 'builder-domain-connection',
	RETURN_DOMAIN_CONNECTED: 'builder-domain-connected',
	PLANS_TO_SHOW_BASIC_ECOMMERCE: 'basicEcommerce',
	PLANS_TO_SHOW_ECOMMERCE: 'ecommerce',
	PLANS_TO_SHOW_NON_ECOMMERCE: 'nonEcommerce',
	PLANS_TO_SHOW_ALL: 'all',
	PLANS_TO_SHOW_BUSINESS: 'business',
	FLOW_UPGRADE: 'FLOW_UPGRADE',
	FLOW_EXTEND: 'FLOW_EXTEND',
	RETURN_ECOMMERCE_ADMIN: 'ecommerce-admin',
	AI_BUILDER: 'ai-builder',
	ANALYTICS: 'analytics',
	FORMS: 'forms',
	GAMIFICATION: 'gamification',
	PAGES: 'pages',
	EXPORT: 'export',
};
export const REDIRECT_TYPES = {
	ASSIGN: 'assign',
	REPLACE: 'replace',
};

export const REDIRECT_ORIGIN_HPANEL = 'HPANEL';

export const ONBOARDING_STEP_MULTI_PAGE_DRAWER = 'ONBOARDING_STEP_MULTI_PAGE_DRAWER';
export const ONBOARDING_STEP_ADD_ELEMENTS_DRAWER = 'ONBOARDING_STEP_ADD_ELEMENTS_DRAWER';
export const ONBOARDING_STEP_USER_STYLES_DRAWER = 'ONBOARDING_STEP_USER_STYLES_DRAWER';
export const ONBOARDING_STEP_MOBILE_VIEW = 'ONBOARDING_STEP_MOBILE_VIEW';
export const ONBOARDING_STEP_PUBLISH = 'ONBOARDING_STEP_PUBLISH';
export const ONBOARDING_STEPS_STEP_COUNT_TO_SHOW = 5;
export const ONBOARDING_STEPS_SEO_INTRO = 'ONBOARDING_STEPS_SEO_INTRO';
export const ONBOARDING_STEPS_SEO_BRAND_NAME = 'ONBOARDING_STEPS_SEO_BRAND_NAME';
export const ONBOARDING_STEPS_SEO_LANGUAGE = 'ONBOARDING_STEPS_SEO_LANGUAGE';
export const ONBOARDING_STEPS_SEO_DESCRIPTION = 'ONBOARDING_STEPS_SEO_DESCRIPTION';
export const ONBOARDING_STEPS_SEO_BRAND_KEYWORDS = 'ONBOARDING_STEPS_SEO_BRAND_KEYWORDS';
export const ONBOARDING_STEPS_SEO_BRAND_REVIEW = 'ONBOARDING_STEPS_SEO_BRAND_REVIEW';

export const ONBOARDING_STEPS_SHORT_TOUR = [
	ONBOARDING_STEP_MULTI_PAGE_DRAWER,
	ONBOARDING_STEP_ADD_ELEMENTS_DRAWER,
	ONBOARDING_STEP_USER_STYLES_DRAWER,
	ONBOARDING_STEP_MOBILE_VIEW,
	ONBOARDING_STEP_PUBLISH,
];
export const ONBOARDING_STEPS_SEO = [
	ONBOARDING_STEPS_SEO_INTRO,
	ONBOARDING_STEPS_SEO_BRAND_NAME,
	ONBOARDING_STEPS_SEO_LANGUAGE,
	ONBOARDING_STEPS_SEO_DESCRIPTION,
	ONBOARDING_STEPS_SEO_BRAND_KEYWORDS,
	ONBOARDING_STEPS_SEO_BRAND_REVIEW,
];

// Cookies
export const COOKIE_ECOMM_PRODUCT_TO_EDIT = 'ecommProductToEdit';
export const COOKIE_PRODUCTS_PRICING_VALUES = 'pricingValues';
export const COOKIE_ZYRO_DEVICE_ID = 'zyroDeviceId';
export const COOKIE_QA_FILTER_OUT_AI_TEMPLATES = 'qaFilterOutAITemplates';
export const COOKIE_HOSTINGER_DEVICE_ID = 'hostingerDeviceId';
export const COOKIE_ZYRO_SESSION_ID = 'zyroSessionId';
export const COOKIE_KNOWLEDGE_BASE_NOTIFICATION_DISABLED = 'z-builder-knowledge-base-notification-disabled';
export const COOKIE_MANUAL_LOCALE = 'user_language';
export const COOKIE_HPANEL_REDIRECT = 'hpanel-redirect';
export const COOKIE_GEOIP_LOCALE = 'z-www-location-by-locale';
export const COOKIE_HOSTINGER_LANGUAGE = 'language';
export const COOKIE_SITE_ID = 'siteId';
export const COOKIE_QA_NO_EXPERIMENTS = 'hostinger-qa-no-experiments';
export const COOKIE_IS_CLOUDFLARE_CAPTCHA_SOLVED = 'z-is-cf-captcha-solved';
export const COOKIE_EXPERIMENT_STATE = 'hostinger-experiment-state';
export const COOKIE_NPS_HIDDEN = 'z-cookie-nps-hidden';
export const COOKIE_NPS_HIDDEN_DURATION = 30;
export const COOKIE_GOOGLE_ANALYTICS = '_ga';
export const COOKIE_E2E_TEST_ID = 'e2e-test-id';
export const COOKIE_FAVORITE_TEMPLATES = 'favoriteTemplates';
export const COOKIE_BRAND_NAME = 'brand-name' as const;
export const COOKIE_SETUP_PAYMENT_NOTIFICATION_CLOSED_AT = 'setup-payment-notification-closed-at' as const;

// Local storage
export const LOCAL_STORAGE_KEY_ONBOARDING_COMPLETED = 'is-onboarding-completed';
export const LOCAL_STORAGE_KEY_AI_BUILDER_FREE_TRY_WEBSITES = 'ai-builder-free-try-websites';
export const LOCAL_STORAGE_KEY_AI_ENTER_BUILDER = 'ai-enter-builder';
export const LOCAL_STORAGE_KEY_AI_BUILDER_FIRST_SITE = 'first-site-generated';
export const LOCAL_STORAGE_KEY_CREDITS_ORDER = 'credits-order';
export const LOCAL_STORAGE_KEY_AI_PREVIEW_ENABLED = 'ai-preview-enabled';
export const LOCAL_STORAGE_KEY_AI_MODAL_CLOSED = 'ai-modal-closed';
export const LOCAL_STORAGE_KEY_HOTJAR_WHY_NOT_PUBLISHED_SENT = 'hotjar-why-not-published-sent';
export const LOCAL_STORAGE_KEY_IS_FORCE_RELOAD_REQUIRED = 'is-force-reload-required';
export const LOCAL_STORAGE_KEY_CURRENT_ROUTE_PATH = 'current-route-path';
export const LOCAL_STORAGE_KEY_LAST_FORCE_RELOAD_TIME = 'last-force-reload-time';
export const LOCAL_STORAGE_KEY_ENABLE_DEV_TOOLBAR = 'enable-dev-toolbar';

export const HEADER_X_CORRELATION_ID = 'X-Correlation-Id';

export const SUBSCRIPTION_NAMES = {
	unleashed: 'siteSettings.websitePlanName',
	ecommerce: 'siteSettings.onlineStorePlanName',
	ecommerceplus: 'siteSettings.advancedStorePlanName',
	ecommercebasic: 'siteSettings.ecommerceBasicPlanName',
	basic: 'siteSettings.basicPlanName',
	business: 'siteSettings.businessPlanName',
};

export const SUBSCRIPTION_STATUS_CANCELLED = 'CANCELLED';
export const WEBSITE_PLAN_GROUP_BUSINESS = 'business';
export const WEBSITE_PLAN_GROUP_ECOMMERCE_PLUS = 'ecommerceplus';
export const SUBSCRIPTION_DURATION_ONE_MONTH = 1;
export const SUBSCRIPTION_DURATION_ONE_YEAR = 12;

// ! When adding a new experiment, update hPanel list too (otherwise, they'll override each other): https://github.com/hostinger/hpanel-mf-main/blob/pre-prod/src/types/enums/experimentEnum.ts
export const EXPERIMENTS_IDS = {};

export const AI_IMAGE_GENERATE_COST = 1;
export const AI_IMAGE_GENERATE_DEFAULT_COUNT = 4;

export const AI_BUILDER_TEMPLATE_ID = 'aigenerated';
export const AI_BUILDER_DESCRIPTION_WORD_COUNT_BAD_QUALITY = 10;
export const AI_BUILDER_DESCRIPTION_WORD_COUNT_MEDIUM_QUALITY = 20;
export const AI_BUILDER_MAXIMUM_LOGO_CHARACTER_LENGTH = 20;
export const AI_BUILDER_LOGO_SIZE = 23;
export const AI_BUILDER_DEFAULT_LOGO_WIDTH = 55;
export const AI_BUILDER_DEFAULT_LOGO_HEIGHT = 24;
export const AI_BUILDER_TITLE_MAX_LENGTH = 100;
export const AI_BUILDER_DESCRIPTION_MAX_LENGTH = 1000;
export const AI_BUILDER_FOOTER_BRAND_ELEMENT_ID = 'footer-brand-name';
export const AI_BUILDER_CATEGORY_ID_STORE = 'online-store';
export const AI_BUILDER_CATEGORY_ID_BLOG = 'blog';
export const AI_BUILDER_CATEGORY_ID_BUSINESS = 'business';
export const AI_BUILDER_CATEGORY_ID_PORTFOLIO = 'portfolio';
export const AI_BUILDER_CATEGORY_ID_RESTAURANT = 'restaurant';
export const AI_BUILDER_CATEGORY_ID_WEDDINGS = 'Weddings invitation';
export const AI_BUILDER_CATEGORY_ID_OTHER = 'other';
export const AI_BUILDER_CATEGORY_ID_LANDING_PAGE = 'landing-page';
export const AI_BUILDER_TEMPLATE_SECTION_TYPE_REGEXES = [
	'section\\d+$',
	'hero$',
	'about$',
	'slogan$',
	'portfolio$',
	'contact$',
	'delivery$',
	'refunds$',
	'gallery$',
	'reviews$',
	'store$',
	'principles$',
	'blog$',
	'activities$',
];
export const AI_BUILDER_TEMPLATE_TYPE_DEFAULT = 'default';
export const AI_BUILDER_TEMPLATE_TYPE_PORTFOLIO = 'portfolio';
export const AI_BUILDER_TEMPLATE_TYPE_ONLINE_STORE = 'online-store';
export const AI_BUILDER_TEMPLATE_TYPE_BLOG = 'blog';
export const AI_BUILDER_PRICING_ENDPOINT = 'website-builder#pricing';
export const AI_BUILDER_REDIRECT_REF = 'www_wb_builder';
export const AI_BUILDER_PAGE_NAMES = [
	'home',
	'about',
	'contacts',
	'onlineStoreTitle',
	'blog',
	'portfolio',
	'appointments',
	'products',
];
export const AI_BUILDER_FAILED_GENERATION_BEFORE_REDIRECT_COUNT = 2;

export const AI_GENERATED_IMAGE_SIZE = {
	DESKTOP: 480,
	MOBILE: 320,
};

export const WEB_TRANSLATION_API_SUPPORTED_LANGUAGES = [
	'en',
	'fr',
	'de',
	'it',
	'pt',
	'ru',
	'es',
	'tr',
];

export const SUPPORT_ARTICLE_LINK_BACKUPS = 'https://support.hostinger.com/en/articles/6436203-website-builder-how-to-create-and-restore-website-backups';
export const ROADMAP_LINK = 'https://roadmap.hostinger.com/tabs/3-website-builder';

export const LEGAL_GENERATOR_COMPANY_NAME_KEY = '%{COMPANY_NAME}';
export const LEGAL_GENERATOR_WEBSITE_NAME_KEY = '%{WEBSITE_NAME}';
export const LEGAL_GENERATOR_COMPANY_ADDRESS_KEY = '%{COMPANY_ADDRESS}';
export const LEGAL_GENERATOR_WEBSITE_ADDRESS_KEY = '%{WEBSITE_ADDRESS}';
export const LEGAL_GENERATOR_EMAIL_ADDRESS_KEY = '%{EMAIL_ADDRESS}';

export const DATA_SELECTOR_ELEMENT_EDIT_TEXT = 'EDIT_TEXT';
export const DATA_SELECTOR_DRAWER = 'DRAWER';
export const DATA_SELECTOR_POPUP = 'POPUP';
export const DATA_SELECTOR_HEATMAP = 'HEATMAP';
export const DATA_SELECTOR_IMAGE_PROVIDER = 'IMAGE_PROVIDER';
export const DATA_SELECTOR_BUILDER_PREVIEW = 'BUILDER_PREVIEW';
export const DATA_SELECTOR_MODAL_CONTENT = 'MODAL';

export const GAMIFICATION_DISCOUNT_CODE = 'GOLIVE10';
export const GAMIFICATION_VISIBILITY_TIMESTAMP = 1682899200; // 2023-05-01 00:00:00 UTC
export const GAMIFICATION_AI_BUILDER_VISIBILITY_TIMESTAMP = 1691539200; // 2023-08-09 00:00:00 UTC
export const MESSENGER_DEPRECATION_TIMESTAMP = 1715634000; // 2024-05-14 00:00:00 UTC

export const GAMIFICATION_TASK_START = 'start_journey';
export const GAMIFICATION_TASK_CHANGE_HEADING = 'edit_heading';
export const GAMIFICATION_TASK_UPDATE_IMAGE = 'update_image';
export const GAMIFICATION_TASK_CHANGE_PARAGRAPH = 'edit_paragraph';
export const GAMIFICATION_TASK_CHANGE_LOGO = 'add_logo';
export const GAMIFICATION_TASK_CHANGE_SOCIAL_ICONS = 'update_social_icons';
export const GAMIFICATION_TASK_CHECK_MOBILE_VIEW = 'check_mobile_view';
export const GAMIFICATION_TASK_CHANGE_SEO = 'found_google';
export const GAMIFICATION_TASK_CONNECT_DOMAIN = 'connect_domain';
export const GAMIFICATION_TASK_GO_LIVE = 'go_live';
export const GAMIFICATION_TASK_ADD_PRODUCT = 'add_your_first_product';
export const GAMIFICATION_TASK_ADD_PAYMENT_METHOD = 'set_up_a_payment_method';
export const GAMIFICATION_TASK_UPDATE_SHIPPING_OPTIONS = 'review_shipping_options';
export const GAMIFICATION_TASK_UPDATE_COMPANY_DETAILS = 'update_company_information';
export const GAMIFICATION_EVENT_NAMES = {
	ENTER: 'website_builder.gamefication.enter',
	COMPLETED: 'website_builder.gamefication.completed',
	RATED: 'website_builder.gamefication.rated',
	TASK_ENTER: {
		[GAMIFICATION_TASK_START]: 'website_builder.game_start.enter',
		[GAMIFICATION_TASK_CHANGE_HEADING]: 'website_builder.game_edit_heading.enter',
		[GAMIFICATION_TASK_UPDATE_IMAGE]: 'website_builder.game_update_image.enter',
		[GAMIFICATION_TASK_CHANGE_PARAGRAPH]: 'website_builder.game_edit_paragraph_text.enter',
		[GAMIFICATION_TASK_CHANGE_LOGO]: 'website_builder.game_add_your_own_log.enter',
		[GAMIFICATION_TASK_CHANGE_SOCIAL_ICONS]: 'website_builder.game_update_social_media.enter',
		[GAMIFICATION_TASK_CHECK_MOBILE_VIEW]: 'website_builder.game_check_your_mobile.enter',
		[GAMIFICATION_TASK_CHANGE_SEO]: 'website_builder.game_get_found_on_google.enter',
		[GAMIFICATION_TASK_CONNECT_DOMAIN]: 'website_builder.game_connect_domain.enter',
		[GAMIFICATION_TASK_GO_LIVE]: 'website_builder.game_go_live.enter',
		[GAMIFICATION_TASK_ADD_PRODUCT]: 'website_builder.game_add_product.enter',
		[GAMIFICATION_TASK_ADD_PAYMENT_METHOD]: 'website_builder.game_add_payment_method.enter',
		[GAMIFICATION_TASK_UPDATE_SHIPPING_OPTIONS]: 'website_builder.game_update_shipping_options.enter',
		[GAMIFICATION_TASK_UPDATE_COMPANY_DETAILS]: 'website_builder.game_update_company_details.enter',
	},
	TASK_COMPLETED: {
		[GAMIFICATION_TASK_START]: 'website_builder.game_start.completed',
		[GAMIFICATION_TASK_CHANGE_HEADING]: 'website_builder.game_edit_heading.completed',
		[GAMIFICATION_TASK_UPDATE_IMAGE]: 'website_builder.game_update_image.completed',
		[GAMIFICATION_TASK_CHANGE_PARAGRAPH]: 'website_builder.game_edit_paragraph_text.completed',
		[GAMIFICATION_TASK_CHANGE_LOGO]: 'website_builder.game_add_your_own_log.completed',
		[GAMIFICATION_TASK_CHANGE_SOCIAL_ICONS]: 'website_builder.game_update_social_media.completed',
		[GAMIFICATION_TASK_CHECK_MOBILE_VIEW]: 'website_builder.game_check_your_mobile.completed',
		[GAMIFICATION_TASK_CHANGE_SEO]: 'website_builder.game_get_found_on_google.completed',
		[GAMIFICATION_TASK_CONNECT_DOMAIN]: 'website_builder.game_connect_domain.completed',
		[GAMIFICATION_TASK_GO_LIVE]: 'website_builder.game_go_live.completed',
		[GAMIFICATION_TASK_ADD_PRODUCT]: 'website_builder.game_add_product.completed',
		[GAMIFICATION_TASK_ADD_PAYMENT_METHOD]: 'website_builder.game_add_payment_method.completed',
		[GAMIFICATION_TASK_UPDATE_SHIPPING_OPTIONS]: 'website_builder.game_update_shipping_options.completed',
		[GAMIFICATION_TASK_UPDATE_COMPANY_DETAILS]: 'website_builder.game_update_company_details.completed',
	},
} as const;

export const GAMIFICATION_ID_TO_TRANSLATED_NAME_MAP = {
	[GAMIFICATION_TASK_START]: 'builder.gamificationTaskNameStartJourney',
	[GAMIFICATION_TASK_CHANGE_HEADING]: 'builder.gamificationTaskNameEditHeading',
	[GAMIFICATION_TASK_UPDATE_IMAGE]: 'builder.gamificationTaskNameUpdateImage',
	[GAMIFICATION_TASK_CHANGE_PARAGRAPH]: 'builder.gamificationTaskNameEditParagraph',
	[GAMIFICATION_TASK_CHANGE_LOGO]: 'builder.gamificationTaskNameAddLogoV2',
	[GAMIFICATION_TASK_CHANGE_SOCIAL_ICONS]: 'builder.gamificationTaskNameUpdateSocialIcons',
	[GAMIFICATION_TASK_CHECK_MOBILE_VIEW]: 'builder.gamificationTaskNameCheckMobileViewV2',
	[GAMIFICATION_TASK_CHANGE_SEO]: 'builder.gamificationTaskNameFoundGoogle',
	[GAMIFICATION_TASK_CONNECT_DOMAIN]: 'builder.gamificationTaskConnectDomain',
	[GAMIFICATION_TASK_GO_LIVE]: 'builder.gamificationTaskNameGoLive',
	[GAMIFICATION_TASK_ADD_PRODUCT]: 'builder.gamificationTaskAddProduct',
	[GAMIFICATION_TASK_ADD_PAYMENT_METHOD]: 'builder.gamificationTaskAddPaymentMethod',
	[GAMIFICATION_TASK_UPDATE_COMPANY_DETAILS]: 'builder.gamificationTaskUpdateCompanyDetails',
	[GAMIFICATION_TASK_UPDATE_SHIPPING_OPTIONS]: 'builder.gamificationTaskUpdateShippingOptions',
};
export const GAMIFICATION_PRODUCT_TYPES = {
	ECOMMERCE: 'builder-ecommerce',
} as const;

export const SIDEBAR_SETTINGS_POPUP_ITEM_ID_VERSION_HISTORY = 'version-history';
export const SIDEBAR_SETTINGS_POPUP_ITEM_ID_GENERAL = 'general';
export const SIDEBAR_SETTINGS_POPUP_ITEM_ID_INTEGRATIONS = 'integrations';
export const SIDEBAR_SETTINGS_POPUP_ITEM_ID_ANALYTICS = 'analytics';
export const SIDEBAR_SETTINGS_POPUP_ITEM_ID_SEO = 'seo';
export const SIDEBAR_SETTINGS_POPUP_ITEM_ID_AI_SALES_ASSISTANT = 'ai-sales-assistant';
export const SIDEBAR_SETTINGS_POPUP_ITEM_ID_FORMS = 'forms';
export const SIDEBAR_SETTINGS_POPUP_ITEM_ID_ASSETS = 'assets';
export const SIDEBAR_SETTINGS_POPUP_ITEM_ID_EXPORT = 'export';
export const SIDEBAR_SETTINGS_POPUP_ITEM_ID_MULTILANGUAGE = 'multilanguage';
export const SIDEBAR_SETTINGS_POPUP_ITEM_ID_BLOG = 'blog';
export const SIDEBAR_SETTINGS_POPUP_ITEM_APPOINTMENTS = 'appointments';
export const SIDEBAR_SETTINGS_POPUP_ITEM_ID_HELP = 'help';
export const SIDEBAR_SETTINGS_POPUP_ITEM_ID_PRODUCT_UPDATES = 'product-updates';

export const MODAL_BUY_CREDITS = 'BuyCreditsModal';
export const MODAL_USER_TYPOGRAPHY_STYLES_RESET = 'UserTypographyStylesResetModal';
export const MODAL_USER_TYPOGRAPHY_RESET_ALL_CHANGES = 'UserTypographyResetAllChangesModal';
export const MODAL_WEBSITE_EDIT = 'WebsiteEditModal';
export const MODAL_ADD_BLOCK = 'AddBlockModal';
export const MODAL_ADD_PAGE = 'AddPageModal';
export const MODAL_BLOG_POST_SETTINGS = 'BlogPostSettingsModal';
export const MODAL_PAGE_SETTINGS = 'PageSettingsModal';
export const MODAL_LINK_SETTINGS = 'LinkSettingsModal';
export const MODAL_BLOG_SETTINGS = 'BlogSettingsModal';
export const MODAL_SUBSCRIPTION_EXPIRED = 'SubscriptionExpiredModal';
export const MODAL_BEFORE_PUBLISH = 'BeforePublishModal';
export const MODAL_PUBLISHED = 'PublishedModal';
export const MODAL_CONNECT_SUBSCRIPTION = 'ConnectSubscriptionModal';
export const MODAL_ASSET_MANAGER = 'AssetManager';
export const MODAL_CHOOSE_BLOCK_PLACEMENT = 'ChooseBlockPlacementModal';
export const MODAL_PUBLISHED_ROOT = 'PublishedModalRoot';
export const MODAL_PUBLISHED_UPDATE_SECTION = 'PublishedModalUpdateSection';
export const MODAL_PUBLISH_OPTION = 'PublishModalOption';
export const MODAL_SYSTEM_DIALOG = 'SystemDialogModal';
export const MODAL_UNSAVED_CHANGES = 'UnsavedChangesModal';
export const MODAL_ECOMMERCE_UPGRADE_PLAN = 'UpgradePlanEcommerce';
export const MODAL_LEGAL_CONTENT_GENERATOR = 'LegalContentGeneratorModal';
export const MODAL_EDIT_ONLINE_STORE = 'EditOnlineStoreModal';
export const MODAL_GENERATE_PAGE = 'GeneratePageModal';
export const MODAL_GENERATE_SECTION = 'GenerateSectionModal';
export const MODAL_NO_BACKUPS_MODAL = 'NoBackupsModal';
export const MODAL_CREATE_QR = 'CreateQRModal';
export const MODAL_QR_NOT_AVAILABLE = 'QRNotAvailableModal';
export const MODAL_UPGRADE_TO_BUSINESS_PLAN = 'UpgradeToBusinessPlanModal';
export const MODAL_CHECKOUT_SLUG_ERROR = 'CheckoutSlugErrorModal';
export const MODAL_WORDPRESS_EXPORTING = 'WordPressExportingModal';
export const MODAL_WORDPRESS_EXPORT_COMPLETE = 'WordPressExportCompleteModal';
export const MODAL_WORDPRESS_EXPORT_FAIL = 'WordPressExportFailModal';

export const BLOCK_CATEGORY_BLOG = 'blog-page';
export const BLOCK_CATEGORY_LITE_STORE = 'lite-store';
export const BLOCK_CATEGORY_FOOTER = 'footer-page';
export const BLOCK_CATEGORY_ABOUT = 'about-page';
export const BLOCK_CATEGORY_MAIN = 'main';
export const BLOCK_CATEGORY_PRIVACY_POLICY = 'privacy-policy';
export const BLOCK_CATEGORY_REFUND_POLICY = 'refund-policy';
export const BLOCK_CATEGORY_TERMS_AND_CONDITIONS = 'terms-and-conditions';
export const BLOCK_CATEGORY_STICKY_BAR = 'sticky-bar';

export const PAGE_TYPE_BLOG = 'blog';
export const PAGE_TYPE_ONLINE_STORE = 'online-store';
export const PAGE_TYPE_ABOUT = 'about';
export const PAGE_TYPE_CONTACT = 'contact';
export const PAGE_TYPE_PROJECTS = 'projects';
export const PAGE_TYPE_SERVICES = 'services';
export const PAGE_TYPE_PRIVACY_POLICY = 'privacy-policy';
export const PAGE_TYPE_REFUND_POLICY = 'refund-policy';
export const PAGE_TYPE_TERMS_AND_CONDITIONS = 'terms-and-conditions';
export const PAGE_TYPE_PORTFOLIO = 'portfolio';
export const PAGE_TYPE_APPOINTMENTS = 'appointments';

export const ADD_MODAL_ECOMMERCE_SINGLE_PRODUCT = 'ecommerceSingleProduct';
export const ADD_MODAL_ECOMMERCE_PRODUCT_LIST = 'ecommerceProductList';

export const PAGE_TYPE_DEFAULT_SLUG = 'page';
export const PAGE_TYPE_BLOG_SLUG = 'blog-post';
export const PAGE_TYPE_BLOG_LIST_SLUG = 'blog-list';
export const PAGE_TYPE_PRODUCT_SLUG = 'product-page';

export const LEGAL_CONTENT_BLOCKS = [
	BLOCK_CATEGORY_PRIVACY_POLICY,
	BLOCK_CATEGORY_REFUND_POLICY,
	BLOCK_CATEGORY_TERMS_AND_CONDITIONS,
];
export const LEGAL_CONTENT_PAGES = [
	PAGE_TYPE_PRIVACY_POLICY,
	PAGE_TYPE_REFUND_POLICY,
	PAGE_TYPE_TERMS_AND_CONDITIONS,
];

export const ADD_CONTENT_MODAL_MASONRY_GUTTER_DESKTOP = 20;
export const ADD_CONTENT_MODAL_MASONRY_GUTTER_MOBILE = 0;
export const ADD_CONTENT_MODAL_MASONRY_COLUMN_COUNT_DESKTOP = 2;
export const ADD_CONTENT_MODAL_MASONRY_COLUMN_COUNT_MOBILE = 1;

export const TIME_DENOMINATOR_MINUTE = 60 * 1000;
export const TIME_DENOMINATOR_HOUR = 60 * 60 * 1000;
export const TIME_DENOMINATOR_DAY = 24 * 60 * 60 * 1000;

export const RATE_SITE_BUTTON_CLOSE_TIMEOUT = 2000;

const TYPOGRAPHY_DEFAULT_STYLES_HEADING = {
	[PROPERTY_LINE_HEIGHT]: '1.3em',
	[PROPERTY_LETTER_SPACING]: 0,
	[PROPERTY_TEXT_TRANSFORM]: 'none',
	[PROPERTY_TEXT_DECORATION]: 'none',
};
const TYPOGRAPHY_DEFAULT_STYLES_PARAGRAPH = {
	...TYPOGRAPHY_DEFAULT_STYLES_HEADING,
	[PROPERTY_LINE_HEIGHT]: '1.5em',
};
const TYPOGRAPHY_LINK_BUTTON_DEFAULT_STYLE = {
	[PROPERTY_FONT_SIZE]: '16px',
	[PROPERTY_M_FONT_SIZE]: '16px',
	...TYPOGRAPHY_DEFAULT_STYLES_PARAGRAPH,
};

export const TYPOGRAPHY_DEFAULT_STYLES_TEXT = {
	[TYPOGRAPHY_H1]: {
		[PROPERTY_FONT_SIZE]: '64px',
		[PROPERTY_M_FONT_SIZE]: '40px',
		...TYPOGRAPHY_DEFAULT_STYLES_HEADING,
	},
	[TYPOGRAPHY_H2]: {
		[PROPERTY_FONT_SIZE]: '56px',
		[PROPERTY_M_FONT_SIZE]: '36px',
		...TYPOGRAPHY_DEFAULT_STYLES_HEADING,
	},
	[TYPOGRAPHY_H3]: {
		[PROPERTY_FONT_SIZE]: '48px',
		[PROPERTY_M_FONT_SIZE]: '32px',
		...TYPOGRAPHY_DEFAULT_STYLES_HEADING,
	},
	[TYPOGRAPHY_H4]: {
		[PROPERTY_FONT_SIZE]: '40px',
		[PROPERTY_M_FONT_SIZE]: '24px',
		...TYPOGRAPHY_DEFAULT_STYLES_HEADING,
	},
	[TYPOGRAPHY_H5]: {
		[PROPERTY_FONT_SIZE]: '32px',
		[PROPERTY_M_FONT_SIZE]: '24px',
		...TYPOGRAPHY_DEFAULT_STYLES_HEADING,
	},
	[TYPOGRAPHY_H6]: {
		[PROPERTY_FONT_SIZE]: '24px',
		[PROPERTY_M_FONT_SIZE]: '20px',
		...TYPOGRAPHY_DEFAULT_STYLES_HEADING,
	},
	[TYPOGRAPHY_BODY_LARGE]: {
		[PROPERTY_FONT_SIZE]: '18px',
		[PROPERTY_M_FONT_SIZE]: '18px',
		...TYPOGRAPHY_DEFAULT_STYLES_PARAGRAPH,
	},
	[TYPOGRAPHY_BODY]: {
		[PROPERTY_FONT_SIZE]: '16px',
		[PROPERTY_M_FONT_SIZE]: '16px',
		...TYPOGRAPHY_DEFAULT_STYLES_PARAGRAPH,
	},
	[TYPOGRAPHY_BODY_SMALL]: {
		[PROPERTY_FONT_SIZE]: '14px',
		[PROPERTY_M_FONT_SIZE]: '14px',
		...TYPOGRAPHY_DEFAULT_STYLES_PARAGRAPH,
	},
	[TYPOGRAPHY_NAV_LINK]: {
		...TYPOGRAPHY_LINK_BUTTON_DEFAULT_STYLE,
	},
	[ELEMENT_GRID_BUTTON_PRIMARY]: {
		...TYPOGRAPHY_LINK_BUTTON_DEFAULT_STYLE,
	},
	[ELEMENT_GRID_BUTTON_SECONDARY]: {
		...TYPOGRAPHY_LINK_BUTTON_DEFAULT_STYLE,
	},
};
export const TYPOGRAPHY_STYLE_ELEMENTS = [
	TYPOGRAPHY_H1,
	TYPOGRAPHY_H2,
	TYPOGRAPHY_H3,
	TYPOGRAPHY_H4,
	TYPOGRAPHY_H5,
	TYPOGRAPHY_H6,
	TYPOGRAPHY_BODY_SMALL,
	TYPOGRAPHY_BODY,
	TYPOGRAPHY_BODY_LARGE,
	TYPOGRAPHY_NAV_LINK,
	FONT,
	ELEMENT_GRID_BUTTON_PRIMARY,
	ELEMENT_GRID_BUTTON_SECONDARY,
];
export const TYPOGRAPHY_STYLE_REQUIRED_PROPERTIES = [
	PROPERTY_FONT_FAMILY,
	PROPERTY_LETTER_SPACING,
	PROPERTY_LINE_HEIGHT,
	PROPERTY_FONT_SIZE,
	PROPERTY_M_FONT_SIZE,
	PROPERTY_TEXT_TRANSFORM,
	PROPERTY_FONT_WEIGHT,
	PROPERTY_TEXT_DECORATION,
	PROPERTY_FONT_PRIMARY,
	PROPERTY_FONT_SECONDARY,
];

export const NAVIGATION_TYPE_LINK = 'Link';
export const NAVIGATION_TYPE_PAGE = 'Page';
export const NAVIGATION_TYPE_FOLDER = 'Folder';
export const NAVIGATION_TYPE_HOMEPAGE = 'Homepage';
export const NAVIGATION_GROUP_ROOT = 'ROOT';
export const NAVIGATION_GROUP_HIDDEN = 'HIDDEN';
export const NAVIGATION_ICONS = {
	[NAVIGATION_TYPE_LINK]: {
		icon: 'link',
	},
	[NAVIGATION_TYPE_PAGE]: {
		icon: 'page',
	},
	[NAVIGATION_TYPE_FOLDER]: {
		icon: 'dropdown',
	},
	[NAVIGATION_TYPE_HOMEPAGE]: {
		icon: 'home',
	},
};

export const TEXT_EDITOR_NODE_NAME_PARAGRAPH = 'paragraph';
export const TEXT_EDITOR_NODE_NAME_HEADING = 'heading';
export const TEXT_EDITOR_PROPERTY_TEXT_STYLE = 'textStyle';
export const TEXT_EDITOR_PROPERTY_LIST_ITEM = 'listItem';
export const TEXT_EDITOR_PROPERTY_COLOR_MARK = 'color';
export const TEXT_EDITOR_PROPERTY_LETTERCASE_MARK = 'letterCase';
export const TEXT_EDITOR_PROPERTY_LINK_MARK = 'link';
export const TEXT_EDITOR_LEVELS_HEADING = [
	1,
	2,
	3,
	4,
	5,
	6,
];
export const TEXT_EDITOR_CLASS_BODY_LARGE = 'body-large';
export const TEXT_EDITOR_CLASS_BODY = 'body';
export const TEXT_EDITOR_CLASS_SMALL = 'body-small';
export const TEXT_EDITOR_TEXT_TYPES_PARAGRAPH = [
	{
		type: TEXT_EDITOR_CLASS_BODY_LARGE,
		level: '1',
	},
	{
		type: TEXT_EDITOR_CLASS_BODY,
		level: '2',
	},
	{
		type: TEXT_EDITOR_CLASS_SMALL,
		level: '3',
	},
];
export const TEXT_EDITOR_SUPPORTED_PARAGRAPH_CLASSES = [
	TEXT_EDITOR_CLASS_BODY_LARGE,
	TEXT_EDITOR_CLASS_BODY,
	TEXT_EDITOR_CLASS_SMALL,
];
export const TEXT_EDITOR_TAG_PARAGRAPH = 'p';
export const TEXT_EDITOR_DATA_ATTRIBUTE_LINKED_PAGE_ID = 'data-page-id';
export const TEXT_EDITOR_FONT_WEIGHT_BOLD = 700;
export const TEXT_EDITOR_FONT_WEIGHT_REGULAR = 400;

export const LAYOUT_SNAP_THRESHOLD_X = 8;
export const LAYOUT_SNAP_THRESHOLD_Y = 8;
export const LAYOUT_SNAP_TYPES = {
	ELEMENT_EDGE: 'elementEdge',
	ELEMENT_CENTER: 'elementCenter',
	COLUMN: 'column',
	ROW: 'row',
	BLOCK_CENTER: 'blockCenter',
};
// Used to leave additional space until last element
// Without it, while resizing block overlaps elements outline
export const LAYOUT_MIN_HEIGHT_THRESHOLD = 1;
export const LAYOUT_MIN_SECTION_HEIGHT = 20;

// Randomly high number, Not really needed, but used to simplify resizing
export const LAYOUT_ELEMENT_MAX_HEIGHT = 50000;
export const LAYOUT_ELEMENT_MAX_WIDTH_MOBILE = 328;
export const LAYOUT_ELEMENT_RESIZE_DIRECTIONS = [
	'top',
	'right',
	'bottom',
	'left',
	'top-left',
	'top-right',
	'bottom-left',
	'bottom-right',
];
export const LAYOUT_ELEMENT_MINIMUM_WIDTHS = {
	GridGallery: 80,
	GridInstagramFeed: 80,
	GridForm: 80,
	SearchBar: 260,
};
export const LAYOUT_ELEMENT_DEFAULT_MIN_WIDTH = 10;
export const LAYOUT_ELEMENT_DEFAULT_MIN_HEIGHT = 10;
export const LAYOUT_ELEMENT_HANDLES_ELEMENT_MIN_SIZE = 35;
export const LAYOUT_ELEMENT_DEFAULT_MOBILE_HEIGHT = 200;
export const LAYOUT_BLOCK_WIDTH_DESKTOP = 1224;
export const LAYOUT_ELEMENT_SELECTOR_CURRENT_ELEMENT = 'current-element';

export const LAYOUT_COLUMN_COUNT_DESKTOP = 12;
export const LAYOUT_COLUMN_COUNT_MOBILE = 2;

export const LAYOUT_DEFAULT_COLUMN_GAP = 12;

export const LAYOUT_MOBILE_AUTO_POSITIONING_KEY = 'isMobileAutoPositioningEnabled';
export const LAYOUT_MOBILE_AUTO_POSITIONING_MIN_ELEMENTS_COUNT = 2;

// Grids
export const GRID_ELEMENT_TYPE_TRANSLATION = {
	[ELEMENT_TYPE_TEXT_BOX]: 'common.text',
	[ELEMENT_TYPE_IMAGE]: 'common.image',
	[ELEMENT_TYPE_GALLERY]: 'common.gallery',
	[ELEMENT_TYPE_VIDEO]: 'common.video',
	[ELEMENT_TYPE_SOCIAL_ICONS]: 'common.socialIcons',
	[ELEMENT_TYPE_MAP]: 'common.map',
	[ELEMENT_TYPE_FORM]: 'common.form',
	[ELEMENT_TYPE_BUTTON]: 'common.button',
	[ELEMENT_TYPE_STRIPE_BUTTON]: 'common.stripeCheckout',
	[ELEMENT_TYPE_ECOMMERCE_BUTTON]: 'builder.ecommerceAddToBagButton',
	[ELEMENT_TYPE_INSTAGRAM_FEED]: 'common.instagramFeed',
	[ELEMENT_TYPE_EMBED]: 'common.embedCode',
	[ELEMENT_TYPE_SHAPE]: 'common.shape',
	[ELEMENT_TYPE_SEARCH_BAR]: 'common.searchBar',
};

export const STRIPE_PUBLIC_KEY_REGEX = /^pk_(?:live|test)_\w{99}$/;
export const STRIPE_PRICE_ID_REGEX = /^price_\w{24}$/;

export const CONTROL_LINE_SCROLL_START_THRESHOLD = 100;
export const CONTROL_LINE_SCROLL_AMOUNT = 5;
export const CONTROL_LINE_SCROLL_INTERVAL_DELAY = 10;

export const INTEGRATION_KEY_CUSTOM_META = 'customMeta';
export const INTEGRATION_KEY_CUSTOM_HEAD_ELEMENTS = 'customHeadElements';
export const INTEGRATION_KEY_CUSTOM_BODY_ELEMENTS = 'customBodyElements';
export const INTEGRATION_KEY_FACEBOOK_MESSENGER = 'facebookMessenger';
export const INTEGRATION_KEY_FACEBOOK_PIXEL = 'facebookPixel';
export const INTEGRATION_KEY_GOOGLE_AD_SENSE = 'googleAdSense';
export const INTEGRATION_KEY_GOOGLE_AD_MOB = 'googleAdMob';
export const INTEGRATION_KEY_GOOGLE_ANALYTICS = 'googleAnalytics';
export const INTEGRATION_KEY_GOOGLE_TAG_MANAGER = 'googleTagManager';
export const INTEGRATION_KEY_HOTJAR = 'hotjar';
export const INTEGRATION_KEY_WHATS_APP = 'whatsAppNumber';

export const SITE_META_PRIVATE_MODE_KEY = 'isPrivateModeActive';

export const BUTTON_PROPERTY_SIZE_SMALL = 'small';
export const BUTTON_PROPERTY_SIZE_MEDIUM = 'medium';
export const BUTTON_PROPERTY_SIZE_LARGE = 'large';

export const DRAWER_USER_STYLES = 'UserStylesDrawer';
export const DRAWER_BLOG = 'BlogDrawer';
export const DRAWER_MULTI_PAGE = 'MultiPageDrawer';
export const DRAWER_MULTILINGUAL = 'MultilingualDrawer';
export const DRAWER_ADD_ELEMENT = 'AddElementDrawer';
export const DRAWER_ADD_SECTION = 'AddSectionDrawer';
export const DRAWER_ADD_ONLINE_STORE = 'ChooseStoreDrawer';
export const DRAWER_MANAGE_STORE = 'ManageStoreDrawer';
export const DRAWER_MANAGE_STORE_PRODUCTS = 'ManageEcommerceStoreProducts';
export const DRAWER_AI_WRITER = 'AiWriterDrawer';
export const DRAWER_HELP_AND_RESOURCES = 'HelpAndResourcesDrawer';
export const DRAWER_GAMIFICATION = 'GamificationDrawer';
export const DRAWER_SEO = 'SeoDrawer';
export const DRAWER_AI_SALES_ASSISTANT = 'AISalesAssistantDrawer';
export const DRAWER_GENERAL_SETTINGS = 'GeneralSettingsDrawer';
export const DRAWER_INTEGRATIONS = 'IntegrationsDrawer';
export const DRAWER_ANALYTICS = 'AnalyticsDrawer';
export const DRAWER_FORMS = 'FormsDrawer';
export const DRAWER_EXPORT = 'ExportDrawer';

export const EDIT_BLOCK_NAVIGATION_TAB_ID_LOGO = 'logo';

export const DRAWER_STYLES_TAB_ID_COLORS = 'GlobalColorsLibrary';
export const DRAWER_STYLES_TAB_ID_TYPOGRAPHY = 'TypographyStyleList';
export const DRAWER_STYLES_TAB_ID_BUTTON_SETS = 'ButtonSetsLibrary';
export const DRAWER_STYLES_TAB_ID_ANIMATIONS = 'AnimationsLibrary';

export const USER_STYLES_MAIN_PAGE_KEY = 'main-page';
export const USER_STYLES_SELECT_KEY_TYPOGRAPHY_CATEGORY = 'typography-category-select';
export const USER_STYLES_SELECT_KEY_HEADING = 'heading-select';
export const USER_STYLES_SELECT_KEY_PARAGRAPH = 'paragraph-select';

export const USER_STYLES_DRAWER_STYLES_COMPONENT = 'UserStyles';
export const USER_STYLES_DRAWER_TYPOGRAPHY_COMPONENT = 'TypographyCategorySelect';
export const USER_STYLES_DRAWER_TYPOGRAPHY_TYPE_SELECT_COMPONENT = 'TypographyTypeSelect';

const USER_STYLES_DRAWER_PAGES = {
	[USER_STYLES_MAIN_PAGE_KEY]: {
		component: USER_STYLES_DRAWER_STYLES_COMPONENT,
		title18nPath: 'builder.userStyles.title',
	},
	[USER_STYLES_SELECT_KEY_TYPOGRAPHY_CATEGORY]: {
		component: USER_STYLES_DRAWER_TYPOGRAPHY_COMPONENT,
		title18nPath: 'builder.userStyles.typography.textStyles',
		subtext18nPath: 'builder.userStyles.typography.textStylesSub',
	},
	[USER_STYLES_SELECT_KEY_HEADING]: {
		component: USER_STYLES_DRAWER_TYPOGRAPHY_TYPE_SELECT_COMPONENT,
		title18nPath: 'builder.userStyles.typography.headings',
		subtext18nPath: 'builder.userStyles.typography.headingsSubtext',
		fontType: 'primary',
		previousPage: USER_STYLES_SELECT_KEY_TYPOGRAPHY_CATEGORY,
		options: {
			currentTypographyType: null,
		},
	},
	[USER_STYLES_SELECT_KEY_PARAGRAPH]: {
		component: USER_STYLES_DRAWER_TYPOGRAPHY_TYPE_SELECT_COMPONENT,
		title18nPath: 'builder.userStyles.typography.paragraphsAndNavigation',
		subtext18nPath: 'builder.userStyles.typography.paragraphSubtext',
		fontType: 'secondary',
		previousPage: USER_STYLES_SELECT_KEY_TYPOGRAPHY_CATEGORY,
		options: {
			currentTypographyType: null,
		},
	},
};

export const DRAWER_PAGES = {
	[DRAWER_USER_STYLES]: USER_STYLES_DRAWER_PAGES,
};
export const DRAWER_PAGES_DEFAULT_STATE = {
	[DRAWER_USER_STYLES]: DRAWER_PAGES[DRAWER_USER_STYLES][USER_STYLES_MAIN_PAGE_KEY],
};

export const NPS_MAX_DAYS_AFTER_LAST_SCORE = 30;
export const NPS_TYPE_BUILDER_GENERAL = 'builder-general';
export const NPS_TYPE_AI_PREVIEW = 'generated-site-preview';
export const NPS_TYPE_AI_ONLINE_STORE_PREVIEW = 'generated-online-store-preview';
export const NPS_TYPE_AI_TEMPLATES_PREVIEW = 'ai-templates-preview';
export const NPS_TYPE_AI_BUILDER_FREEMIUM = 'ai-builder-freemium';
export const NPS_TYPE_AI_WRITER = 'ai-writer';
export const NPS_TYPE_FEATURE_BUTTON = 'element-button';
export const NPS_TYPE_FEATURE_SEARCH_BAR = 'element-search-bar';
export const NPS_TYPE_FEATURE_AI_SALES_ASSISTANT = 'ai-sales-assistant';
export const NPS_TYPE_FEATURE_FORM = 'element-form';
export const NPS_TYPE_FEATURE_EMBED_CODE = 'element-embed-code';
export const NPS_TYPE_FEATURE_GALLERY = 'element-gallery';
export const NPS_TYPE_FEATURE_IMAGE = 'element-image';
export const NPS_TYPE_FEATURE_INSTAGRAM_FEED = 'element-instagram-feed';
export const NPS_TYPE_FEATURE_MAP = 'element-map';
export const NPS_TYPE_FEATURE_SIMPLE_STORE = 'element-simple-store';
export const NPS_TYPE_FEATURE_SOCIAL_ICONS = 'element-social-icons';
export const NPS_TYPE_FEATURE_VIDEO = 'element-video';
export const NPS_TYPE_FEATURE_TEXT = 'element-text';
export const NPS_TYPE_FEATURE_BLOG = 'blog';
export const NPS_TYPE_FEATURE_GLOBAL_STYLES_ANIMATION = 'global-styles-animation';
export const NPS_TYPE_FEATURE_GLOBAL_STYLES_BUTTONS = 'global-styles-buttons';
export const NPS_TYPE_FEATURE_GLOBAL_STYLES_COLORS = 'global-styles-colors';
export const NPS_TYPE_FEATURE_GLOBAL_STYLES_TEXT = 'global-styles-text';
export const NPS_TYPE_FEATURE_MULTILINGUAL = 'multi-language';
export const NPS_TYPE_FEATURE_SHAPE = 'element-shape';
export const NPS_TYPE_FEATURE_SEARCH = 'element-search';
export const NPS_TYPE_FEATURE_GAMIFICATION = 'builder-gamification';
export const NPS_TYPE_FEATURE_AI_IMAGE_GENERATION = 'ai-image-generation';
export const NPS_TYPE_FEATURE_AI_PAGE_GENERATION = 'ai-page-generation';
export const NPS_TYPE_FEATURE_BLOCK_STICKY_BAR = 'block-sticky-bar';

const DEFAULT_NPS_DATA = {
	titleI18Key: 'builder.nps.scoreTitle',
	maxScoreI18Key: 'builder.nps.extremelyLikely',
	lowestScoreI18Key: 'builder.nps.notLikely',
};

const DEFAULT_AI_NPS_DATA = {
	titleI18Key: 'builder.nps.aiScoreTitle',
	maxScoreI18Key: 'builder.nps.extremelyLikely',
	lowestScoreI18Key: 'builder.nps.notLikely',
};

export const NPS_DATA_BUILDER_GENERAL = {
	...DEFAULT_NPS_DATA,
	formType: NPS_TYPE_BUILDER_GENERAL,
};
export const NPS_DATA_FEATURE_BUTTON = {
	...DEFAULT_NPS_DATA,
	formType: NPS_TYPE_FEATURE_BUTTON,
};
export const NPS_DATA_FEATURE_EMBED_CODE = {
	...DEFAULT_NPS_DATA,
	formType: NPS_TYPE_FEATURE_EMBED_CODE,
};
export const NPS_DATA_FEATURE_GALLERY = {
	...DEFAULT_NPS_DATA,
	formType: NPS_TYPE_FEATURE_GALLERY,
};
export const NPS_DATA_FEATURE_IMAGE = {
	...DEFAULT_NPS_DATA,
	formType: NPS_TYPE_FEATURE_IMAGE,
};
export const NPS_DATA_FEATURE_INSTAGRAM_FEED = {
	...DEFAULT_NPS_DATA,
	formType: NPS_TYPE_FEATURE_INSTAGRAM_FEED,
};
export const NPS_DATA_FEATURE_MAP = {
	...DEFAULT_NPS_DATA,
	formType: NPS_TYPE_FEATURE_MAP,
};
export const NPS_DATA_FEATURE_SIMPLE_STORE = {
	...DEFAULT_NPS_DATA,
	formType: NPS_TYPE_FEATURE_SIMPLE_STORE,
};
export const NPS_DATA_FEATURE_SOCIAL_ICONS = {
	...DEFAULT_NPS_DATA,
	formType: NPS_TYPE_FEATURE_SOCIAL_ICONS,
};
export const NPS_DATA_FEATURE_VIDEO = {
	...DEFAULT_NPS_DATA,
	formType: NPS_TYPE_FEATURE_VIDEO,
};
export const NPS_DATA_FEATURE_TEXT = {
	...DEFAULT_NPS_DATA,
	formType: NPS_TYPE_FEATURE_TEXT,
};
export const NPS_DATA_FEATURE_FORM = {
	...DEFAULT_NPS_DATA,
	formType: NPS_TYPE_FEATURE_FORM,
};
export const NPS_DATA_FEATURE_GLOBAL_STYLES_NPS_ANIMATIONS_DATA = {
	...DEFAULT_NPS_DATA,
	formType: NPS_TYPE_FEATURE_GLOBAL_STYLES_ANIMATION,
};
export const NPS_DATA_FEATURE_GLOBAL_STYLES_NPS_BUTTONS_DATA = {
	...DEFAULT_NPS_DATA,
	formType: NPS_TYPE_FEATURE_GLOBAL_STYLES_BUTTONS,
};
export const NPS_DATA_FEATURE_GLOBAL_STYLES_NPS_COLORS_DATA = {
	...DEFAULT_NPS_DATA,
	formType: NPS_TYPE_FEATURE_GLOBAL_STYLES_COLORS,
};
export const NPS_DATA_FEATURE_GLOBAL_STYLES_NPS_TEXT_DATA = {
	...DEFAULT_NPS_DATA,
	formType: NPS_TYPE_FEATURE_GLOBAL_STYLES_TEXT,
};
export const NPS_DATA_FEATURE_MULTILINGUAL = {
	...DEFAULT_NPS_DATA,
	formType: NPS_TYPE_FEATURE_MULTILINGUAL,
};
export const NPS_DATA_FEATURE_SHAPE = {
	...DEFAULT_NPS_DATA,
	formType: NPS_TYPE_FEATURE_SHAPE,
};
export const NPS_DATA_FEATURE_SEARCH = {
	...DEFAULT_NPS_DATA,
	formType: NPS_TYPE_FEATURE_SEARCH,
};
export const NPS_DATA_FEATURE_AI_SALES_ASSISTANT = {
	...DEFAULT_NPS_DATA,
	formType: NPS_TYPE_FEATURE_AI_SALES_ASSISTANT,
};
export const NPS_DATA_FEATURE_SEARCH_BAR = {
	...DEFAULT_NPS_DATA,
	formType: NPS_TYPE_FEATURE_SEARCH_BAR,
};
export const NPS_DATA_FEATURE_BLOG = {
	...DEFAULT_NPS_DATA,
	formType: NPS_TYPE_FEATURE_BLOG,
};
export const NPS_DATA_FEATURE_GAMIFICATION = {
	...DEFAULT_NPS_DATA,
	formType: NPS_TYPE_FEATURE_GAMIFICATION,
};

export const NPS_DATA_AI_BUILDER_FREEMIUM = {
	...DEFAULT_AI_NPS_DATA,
	formType: NPS_TYPE_AI_BUILDER_FREEMIUM,
};
export const NPS_DATA_TYPE_AI_WRITER = {
	...DEFAULT_NPS_DATA,
	formType: NPS_TYPE_AI_WRITER,
};
export const NPS_DATA_TYPE_AI_IMAGE_GENERATION = {
	...DEFAULT_NPS_DATA,
	formType: NPS_TYPE_FEATURE_AI_IMAGE_GENERATION,
};

export const NPS_DATA_TYPE_AI_PAGE_GENERATION = {
	...DEFAULT_NPS_DATA,
	formType: NPS_TYPE_FEATURE_AI_PAGE_GENERATION,
};

export const NPS_DATA_TYPE_AI_PREVIEW = {
	...DEFAULT_NPS_DATA,
	formType: NPS_TYPE_AI_PREVIEW,
};

export const NPS_DATA_TYPE_AI_ONLINE_STORE_PREVIEW = {
	...DEFAULT_NPS_DATA,
	formType: NPS_TYPE_AI_ONLINE_STORE_PREVIEW,
};

export const NPS_DATA_TYPE_AI_TEMPLATES_PREVIEW = {
	...DEFAULT_NPS_DATA,
	formType: NPS_TYPE_AI_TEMPLATES_PREVIEW,
};

export const NPS_DATA_TYPE_BLOCK_STICKY_BAR = {
	...DEFAULT_NPS_DATA,
	formType: NPS_TYPE_FEATURE_BLOCK_STICKY_BAR,
};

export const NPS_DATA_MAP = {
	[NPS_TYPE_BUILDER_GENERAL]: NPS_DATA_BUILDER_GENERAL,
	[NPS_TYPE_AI_PREVIEW]: NPS_DATA_TYPE_AI_PREVIEW,
	[NPS_TYPE_AI_ONLINE_STORE_PREVIEW]: NPS_DATA_TYPE_AI_ONLINE_STORE_PREVIEW,
	[NPS_TYPE_AI_TEMPLATES_PREVIEW]: NPS_DATA_TYPE_AI_TEMPLATES_PREVIEW,
	[NPS_TYPE_AI_BUILDER_FREEMIUM]: NPS_DATA_AI_BUILDER_FREEMIUM,
	[NPS_TYPE_FEATURE_BUTTON]: NPS_DATA_FEATURE_BUTTON,
	[NPS_TYPE_FEATURE_BLOG]: NPS_DATA_FEATURE_BLOG,
	[NPS_TYPE_FEATURE_FORM]: NPS_DATA_FEATURE_FORM,
	[NPS_TYPE_FEATURE_EMBED_CODE]: NPS_DATA_FEATURE_EMBED_CODE,
	[NPS_TYPE_FEATURE_GALLERY]: NPS_DATA_FEATURE_GALLERY,
	[NPS_TYPE_FEATURE_IMAGE]: NPS_DATA_FEATURE_IMAGE,
	[NPS_TYPE_FEATURE_INSTAGRAM_FEED]: NPS_DATA_FEATURE_INSTAGRAM_FEED,
	[NPS_TYPE_FEATURE_MAP]: NPS_DATA_FEATURE_MAP,
	[NPS_TYPE_FEATURE_SIMPLE_STORE]: NPS_DATA_FEATURE_SIMPLE_STORE,
	[NPS_TYPE_FEATURE_SOCIAL_ICONS]: NPS_DATA_FEATURE_SOCIAL_ICONS,
	[NPS_TYPE_FEATURE_VIDEO]: NPS_DATA_FEATURE_VIDEO,
	[NPS_TYPE_FEATURE_TEXT]: NPS_DATA_FEATURE_TEXT,
	[NPS_TYPE_FEATURE_GLOBAL_STYLES_ANIMATION]: NPS_DATA_FEATURE_GLOBAL_STYLES_NPS_ANIMATIONS_DATA,
	[NPS_TYPE_FEATURE_GLOBAL_STYLES_BUTTONS]: NPS_DATA_FEATURE_GLOBAL_STYLES_NPS_BUTTONS_DATA,
	[NPS_TYPE_FEATURE_GLOBAL_STYLES_COLORS]: NPS_DATA_FEATURE_GLOBAL_STYLES_NPS_COLORS_DATA,
	[NPS_TYPE_FEATURE_GLOBAL_STYLES_TEXT]: NPS_DATA_FEATURE_GLOBAL_STYLES_NPS_TEXT_DATA,
	[NPS_TYPE_FEATURE_MULTILINGUAL]: NPS_DATA_FEATURE_MULTILINGUAL,
	[NPS_TYPE_FEATURE_SHAPE]: NPS_DATA_FEATURE_SHAPE,
	[NPS_TYPE_FEATURE_SEARCH]: NPS_DATA_FEATURE_SEARCH,
	[NPS_TYPE_FEATURE_SEARCH_BAR]: NPS_DATA_FEATURE_SEARCH_BAR,
	[NPS_TYPE_FEATURE_AI_SALES_ASSISTANT]: NPS_DATA_FEATURE_AI_SALES_ASSISTANT,
	[NPS_TYPE_FEATURE_GAMIFICATION]: NPS_DATA_FEATURE_GAMIFICATION,
	[NPS_TYPE_AI_WRITER]: NPS_DATA_TYPE_AI_WRITER,
	[NPS_TYPE_FEATURE_AI_IMAGE_GENERATION]: NPS_DATA_TYPE_AI_IMAGE_GENERATION,
	[NPS_TYPE_FEATURE_AI_PAGE_GENERATION]: NPS_DATA_TYPE_AI_PAGE_GENERATION,
	[NPS_TYPE_FEATURE_BLOCK_STICKY_BAR]: NPS_DATA_TYPE_BLOCK_STICKY_BAR,
};

export const ASSETS_IMAGE_FILE_TYPES = [
	'jpeg',
	'jpg',
	'png',
	'ico',
	'gif',
	'webp',
	'svg',
];
export const ASSETS_FONT_FILE_TYPES = [
	'woff',
	'woff2',
];
export const ASSETS_TAB_ID_MY_LIBRARY = 'myLibrary';
export const ASSETS_TAB_ID_MY_LIBRARY_PAYWALL = 'myLibraryPaywall';
export const ASSETS_TAB_ID_IMAGES = 'images';
export const ASSETS_TAB_ID_DOCUMENTS = 'documents';
export const ASSETS_TAB_ID_UNSPLASH = 'unsplash';
export const ASSETS_TAB_ID_PEXELS = 'pexels';
export const ASSETS_TAB_ID_AI_IMAGES = 'aiImages';
export const ASSETS_CATEGORY_IMAGE = 'image';
export const ASSETS_CATEGORY_VIDEO = 'video';
export const ASSETS_CATEGORY_AI_IMAGE = 'aiImage';
export const ASSETS_CATEGORY_DOCUMENT = 'document';
export const ASSETS_CATEGORIES = [
	ASSETS_CATEGORY_IMAGE,
	ASSETS_CATEGORY_DOCUMENT,
];
export const ASSETS_DOCUMENT_SIZE_LIMIT_MB = 50;
export const ASSETS_IMAGE_SIZE_LIMIT_MB = 15;
export const ASSETS_THUMBNAIL_WIDTH = 210;
export const MAX_LENGTH_PAGE_NAME = 512;
export const MAX_LENGTH_PAGE_SLUG = 250;
export const COLOR_LIGHT = 'light';
export const COLOR_DARK = 'dark';
export const UNSPLASH_QUALITY_SETTINGS = '?w=1366&q=70&auto=format';
export const IMAGE_ORIGIN_UNSPLASH = 'unsplash';
export const IMAGE_ORIGIN_OTHER = 'other';
export const IMAGE_ORIGIN_ASSETS = 'assets';
export const DEFAULT_IMAGE_SHAPE_MASK = 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" preserveAspectRatio="none" viewBox="0 0 200 200" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M200.5 0H0.5V200H200.5V0Z" fill="%23727586"/></svg>';

export const SPACING_HANDLE_OUTSIDE_ELEMENT_BOX_WIDTH_THRESHOLD = 162;

export const EXPIRATION_DAYS_COOKIE_HPANEL_REDIRECT = 7;

export const HOSTINGER_AI_BUILDER_AI_PRICING_URLS = {
	pt_BR: '/criador-de-sites-com-ia#pricing',
	es_AR: '/creador-de-paginas-web-con-ia#pricing',
	es_CO: '/creador-de-paginas-web-con-ia#pricing',
	de_DE: '/ki-website-erstellen#pricing',
	es_ES: '/creador-de-paginas-web-con-ia#pricing',
	fr_FR: '/createur-de-sites-internet-ia#pricing',
	it_IT: '/builder-di-siti-web-ai#pricing',
	es_MX: '/creador-de-paginas-web-con-ia#pricing',
	en_GB: '/ai-website-builder#pricing',
	en_US: '/ai-website-builder#pricing',
	en_IN: '/ai-website-builder#pricing',
};

export const HOSTINGER_US_LOCALE = 'en_US';

export const HOSTINGER_LOCALE_DOMAINS = {
	pt_BR: 'https://www.hostinger.com.br',
	es_AR: 'https://www.hostinger.com.ar',
	es_CO: 'https://www.hostinger.co',
	de_DE: 'https://www.hostinger.de',
	es_ES: 'https://www.hostinger.es',
	fr_FR: 'https://www.hostinger.fr',
	it_IT: 'https://www.hostinger.it',
	es_MX: 'https://www.hostinger.mx',
	en_GB: 'https://www.hostinger.co.uk',
	en_US: import.meta.env.VITE_HOSTINGER_URL,
	en_IN: 'https://www.hostinger.in',
};

export const COMMAND_SEND_TO_FRONT = 'sendFront';
export const COMMAND_SEND_TO_BACK = 'sendBack';
export const COMMAND_MOVE_FORWARD = 'moveForward';
export const COMMAND_MOVE_BACKWARD = 'moveBackward';
export const COMMAND_CUT = 'cut';
export const COMMAND_COPY = 'copy';
export const COMMAND_DUPLICATE = 'duplicate';
export const COMMAND_DELETE = 'delete';

export const SITE_ID_PLAYGROUND = 'playground';

export const AI_BUILDER_THEME = 'theme4';

export const DATA_PORTAL_APP = 'app';
export const DATA_PORTAL_BUILDER_PREVIEW = 'builder-preview';
export const DATA_PORTAL_BUILDER_PREVIEW_MOBILE_BOTTOM_BAR = 'builder-preview-mobile-bottom-bar';
export const DATA_PORTAL_MODAL = 'modal';
export const DATA_PORTAL_GLOBAL_COLORS_COLOR_PICKER = 'global-colors-color-picker';
export const DATA_PORTAL_ASSET_MANAGER = 'asset-manager';
export const DATA_PORTAL_AI_BUILDER_CUSTOM_COLORS = 'ai-builder-custom-colors';
export const DATA_PORTAL_SEO_SLUG_SETTINGS = 'seo-slug-settings';
export const SELECTOR_DATA_PORTAL_AI_BUILDER_CUSTOM_COLORS = `[data-portal="${DATA_PORTAL_AI_BUILDER_CUSTOM_COLORS}"]`;
export const SELECTOR_DATA_PORTAL_APP = `[data-portal="${DATA_PORTAL_APP}"]`;
export const SELECTOR_DATA_PORTAL_BUILDER_PREVIEW = `[data-portal="${DATA_PORTAL_BUILDER_PREVIEW}"]`;
export const SELECTOR_DATA_PORTAL_BUILDER_PREVIEW_MOBILE_BOTTOM_BAR = `[data-portal="${DATA_PORTAL_BUILDER_PREVIEW_MOBILE_BOTTOM_BAR}"]`;
export const SELECTOR_DATA_PORTAL_MODAL = `[data-portal=${DATA_PORTAL_MODAL}]`;
export const SELECTOR_DATA_PORTAL_GLOBAL_COLORS_COLOR_PICKER = `[data-portal="${DATA_PORTAL_GLOBAL_COLORS_COLOR_PICKER}"]`;
export const SELECTOR_DATA_PORTAL_ASSET_MANAGER = `[data-portal="${DATA_PORTAL_ASSET_MANAGER}"]`;
export const SELECTOR_DATA_PORTAL_SEO_SLUG_SETTINGS = `[data-portal="${DATA_PORTAL_SEO_SLUG_SETTINGS}"]`;

export const TEMPLATE_TYPE_AI_DESIGNER = 'ai-designer';
export const AI_TYPES_ELEMENTS = [
	'title',
	'subtitle',
	'description',
	'CTA',
	'list item title',
	'list item subtitle',
	'list item description',
	'quote',
	'slug',
	'caption',
	'name',
	'number',
	'paragraph',
	'content',
];
export const AI_TYPES_SECTIONS = [
	'Hero',
	'Generic information',
	'Testimonials',
	'Location details',
	'Contact',
	'Portfolio',
	'About',
	'Blog',
	'FAQ',
	'Team/People',
	'Social media',
	'Subscribe/Newsletter',
	'Awards',
	'Blog',
	'Products',
];

export const FLOW_AI_PREVIEW = 'ai-preview';

export const ELEMENT_EDIT_POPUP_TAB_ID_GENERAL = 'general';
export const ELEMENT_EDIT_POPUP_TAB_ID_STYLE = 'style';
export const ELEMENT_EDIT_POPUP_TAB_ID_ACTION = 'action';
export const ELEMENT_EDIT_POPUP_TAB_ID_IMAGE = 'image';
export const ELEMENT_EDIT_POPUP_TAB_ID_SHAPE = 'shape';
export const ELEMENT_EDIT_POPUP_TAB_ID_ANIMATION = 'animation';
export const ELEMENT_EDIT_POPUP_STYLE_STATE_ID_NORMAL = 'normal';
export const ELEMENT_EDIT_POPUP_STYLE_STATE_ID_HOVER = 'hover';
export const ELEMENT_EDIT_POPUP_GENERAL_MAX_LENGTH_INPUT = 100;
export const TOUCH_MOVE_DELAY = 125;
export const TOUCH_POSITION_DELTA_TRESHOLD = 15;
export const ELEMENT_FORM_ID_SUBSCRIBE = 'Subscribe form';
export const ADD_ELEMENT_EVENT_PROPERTIES = {
	[ELEMENT_TYPE_TEXT_BOX]: 'text',
	[ELEMENT_TYPE_BUTTON]: 'button',
	[ELEMENT_TYPE_ECOMMERCE_BUTTON]: 'add_to_bag',
	[ELEMENT_TYPE_IMAGE]: 'image',
	[ELEMENT_TYPE_GALLERY]: 'gallery',
	[ELEMENT_TYPE_VIDEO]: 'video',
	[ELEMENT_TYPE_SHAPE]: 'shape',
	[ELEMENT_TYPE_MAP]: 'map',
	[ELEMENT_TYPE_INSTAGRAM_FEED]: 'instagram',
	[ELEMENT_TYPE_FORM]: 'form',
	[ELEMENT_FORM_ID_SUBSCRIBE]: 'subscribe',
	[ELEMENT_TYPE_SOCIAL_ICONS]: 'social_icons',
	[ELEMENT_TYPE_EMBED]: 'embed_code',
	[ELEMENT_TYPE_SEARCH_BAR]: 'search',
};

export const DOMAIN_CONNECTION_ERROR_DOMAIN_TAKEN = 'The domain is already taken';

export const AI_BLOCK_TYPES = {
	hero: "Hero section is the first thing visitors see on your website, featuring a captivating image or video and a brief message to convey your website's purpose. It's designed to grab attention and encourage exploration, exclusively placed on the homepage. It is always used as a first section in the website's homepage and must not be used anywhere else.",
	menu: 'Menu section used for restaurants, shows food and drink offerings. This section must be used on Menu page only.',
	about: 'About section offers insights into your company, brand, or yourself, covering history, mission, values, and team.',
	footer: 'Contains the footer of the website.',
	contact: 'Contact section provides multiple contact options, like a form, phone number, email, and social media links.',
	gallery: 'Gallery section displays images or videos related to your business or personal events.',
	location: 'Location section offers a map and address, vital for physical businesses like stores or restaurants.',
	products: 'Products section organizes various items for viewing or purchase.',
	projects: 'Projects section showcase completed or ongoing work for service-based businesses.',
	services: 'Services section outlines your business offerings, including pricing, features, and benefits, crucial for potential customers.',
	blog_posts: 'Blog Posts section showcase your latest articles or news for industry insights.',
	reservation: 'Reservation section allows online booking for businesses like restaurants or hotels, often featuring a booking form or call-to-action.',
	my_background: 'My Background section used for personal or portfolio sites, showing details about education, work, skills, and achievements.',
	blog_post_header: 'Contains blog post title, description and cover image.',
	customer_reviews: 'Customer Reviews section displays feedback from previous clients.',
	'Blog post content': 'Contains the content of the blog post.',
	ecommerce_product: "Contains the product's title, description, price and image.",
	ecommerce_products: 'Ecommerce Products section display items for sale in online stores, with images, descriptions, and prices.',
	banner: 'Banner section displays a announcement about sale, free shipping or something similar',
	headline: 'Heading section displays a headline or title.',
	subscription: 'Subscription section usually contains form and allows users to subscribe to certain content.',
	card: 'Card section displays a card or cards with an image, title, subtitle and description.',
};

export const TYPE_BLOCK_BACKGROUND = {
	Gradient: 'gradient',
	Color: 'color',
};

export const AI_ELEMENT_TYPES = {
	Date: 'Date',
	Image: 'Image',
	Title: 'Title',
	Address: 'Address',
	Subtitle: 'Subtitle',
	Workplace: 'Workplace',
	'CTA Button': 'CTA Button',
	Description: 'Description',
	Testimonial: 'Testimonial',
	'Price number': 'Price number',
	'Address title': 'Address title',
	'Email address': 'Email address',
	'Working hours': 'Working hours',
	'Blog post content': 'Blog post content',
	'Full phone number': 'Full phone number',
	'Working hours title': 'Working hours title',
	'Site title/ Brand name': 'Site title/ Brand name',
	'Price with currency (ex. 10$)': 'Price with currency (ex. 10$)',
	'Banner text': 'Banner text',
	'Headline text': 'Headline text',
	'Subscription headline': 'Subscription headline',
	'Byline text': 'Byline text',
	'Card image': 'Card image',
	'Card title': 'Card title',
	'Card description': 'Card description',
};
export const BLANK_LAYOUT_TEMPLATE_ID = 'blanklayout';

export const AI_BLOG_TONE_OF_VOICE_INPUT_OPTIONS = [
	'formal',
	'neutral',
	'casual',
];

export const AI_BLOG_LENGTH_OF_CONTENT_INPUT_OPTIONS = [
	'300-500',
	'800-1000',
	'1500-2000',
];

export const AI_BLOG_DESCRIPTION_MAX_LENGTH = 700;
export const AI_BLOG_DESCRIPTION_WORD_COUNT_BAD_QUALITY = 10;
export const AI_BLOG_DESCRIPTION_WORD_COUNT_MEDIUM_QUALITY = 20;
export const TEMPLATES_ACCOUNT_EMAIL = 'templates@hostinger.com';

export const NEW_ELEMENT_RELATIVE_POSITION = {
	BOTTOM: 'bottom',
	EXACT: 'exact',
};
export const NEW_ELEMENT_TOP_OFFSET = 40;
export const HEADER_HEIGHT = 50;

export const AI_WRITER_DESCRIPTION_NOT_CLEAR_TEXT_RESPONSE = 'Description not clear.';

export const P2P_DAYS_UNTIL_SITE_DELETED_URGENT = 7;

export const QUERY_PARAM_OPEN_AI_TEMPLATE_FLOW_FORM = 'ai-form';

export const QUERY_PARAM_ENABLE_DEV_TOOLBAR = 'enableDevToolbar';

export const AI_TOOLS = {
	IMAGE: 'image',
	WRITER: 'writer',
	SECTION_GENERATOR: 'section-generator',
	PAGE_GENERATOR: 'page-generator',
	BLOG: 'blog',
	SEO_ASSISTANT: 'seo-assistant',
	HEATMAP: 'heatmap',
	LOGO: 'logo',
} as const;

export const AI_CHATBOT_FUNCTION_MAP = {
	AI_IMAGE: 'open_ai_image',
	AI_LOGO: 'open_ai_logo',
	AI_WRITER: 'open_ai_writer',
	AI_BLOG: 'open_ai_blog',
	AI_HEATMAP: 'open_ai_heatmap',
	AI_PAGE_GENERATOR: 'open_ai_page_generator',
} as const;

export const AI_CHATBOT_FUNCTION_TOOL_MAP = {
	[AI_CHATBOT_FUNCTION_MAP.AI_IMAGE]: AI_TOOLS.IMAGE,
	[AI_CHATBOT_FUNCTION_MAP.AI_LOGO]: AI_TOOLS.LOGO,
	[AI_CHATBOT_FUNCTION_MAP.AI_WRITER]: AI_TOOLS.WRITER,
	[AI_CHATBOT_FUNCTION_MAP.AI_BLOG]: AI_TOOLS.BLOG,
	[AI_CHATBOT_FUNCTION_MAP.AI_HEATMAP]: AI_TOOLS.HEATMAP,
	[AI_CHATBOT_FUNCTION_MAP.AI_PAGE_GENERATOR]: AI_TOOLS.PAGE_GENERATOR,
};
export const SEO_DRAWER_TAB_DEFAULT = 'default';
export const SEO_DRAWER_TAB_ECOMMERCE = 'ecommerce';
export const ECOMMERCE_DYNAMIC_PAGE_ENABLE_TIMESTAMP = 1725321600; // 2024-09-03 00:00:00 UTC

export const AI_BUILDER_THEME_IDS = {
	THEME_4: 'theme4',
};

export const BACKGROUND_IMAGE_ATTACHMENT_OPTIONS = {
	fixed: 'fixed',
	unset: 'unset',
} as const;

export const PEXELS_VIDEO_QUALITIES = [
	'sd',
	'hd',
	'uhd',
];

export const PEXELS_VIDEOS_PER_PAGE = 20;
export const PEXELS_SEARCH_DEBOUNCE_TIME = 500;

export const IFRAME_EVENT_ECOMMERCE_ADMIN_UPDATED = 'Ecommerce admin updated';
export const IFRAME_EVENT_ECOMMERCE_ADMIN_UNAVAILABLE = 'Ecommerce admin unavailable';

export const ECOMMERCE_DEFAUTL_PRODUCT_CATEGORY = 'all-products';

export const CIRCULAR_PROGRESS_BAR_SIZES = {
	SMALL: 'small',
	LARGE: 'large',
} as const;

export const WORDPRESS_EXPORT_STATUSES = {
	DONE: 'done',
	IN_PROGRESS: 'in_progress',
} as const;

export const ECOMMERCE_FUNNEL_LOCATIONS = {
	AI_BLOG_GENERATOR_MODAL: 'ai-blog-generator-modal',
	AI_BUILDER_PREVIEW_HEADER: 'ai-builder-preview-header',
	IFRAME_MANAGE_PRODUCTS: 'iframe-manage-products',
	IFRAME_EDIT_PRODUCT: 'iframe-edit-product',
	IFRAME_ADD_PRODUCT: 'iframe-add-product',
	AI_IMAGE_GENERATION_POPUP: 'ai-image-generation-popup',
	AI_TEXT_GENERATOR_FORM: 'ai-text-generator-form',
	IFRAME_PRODUCT_ORDER: 'iframe-product-order',
	IFRAME_STORE_LANGUAGE: 'iframe-store-language',
	IFRAME_EDIT_PRODUCT_BLOCK_TAB: 'iframe-edit-product-block-tab',
	IFRAME_EDIT_PRODUCT_BUTTON: 'iframe-edit-product-button',
	IFRAME_STORE_SETTINGS: 'iframe-store-settings',
	CHOOSE_STORE_DRAWER: 'choose-store-drawer',
	IFRAME_EDIT_PRODUCT_MANAGE_ECOMMERCE: 'iframe-edit-product-manage-ecommerce',
	MANAGE_ECOMMERCE_STORE: 'manage-ecommerce-store',
	GENERATE_CONTENT_MODAL: 'generate-content-modal',
	SEO_ONBOARDING_MODAL: 'seo-onboarding-modal',
	SETUP_PAYMENT_PROVIDER_NOTIFICATION: 'setup-payment-provider-notification',
	STORE_MANAGER_SIDEBAR_SETTINGS_BUTTON: 'store-manager-sidebar-settings-button',
	ACTIONS_LIST_OPEN_STORE_MANAGER: 'actions-list-open-store-manager',
	ACTIONS_LIST_MANAGE_PRODUCTS: 'actions-list-manage-products',
	ACTIONS_LIST_ADD_PRODUCT: 'actions-list-add-product',
	ACTIONS_LIST_MANAGE_CATEGORIES: 'actions-list-manage-categories',
	ACTIONS_LIST_MANAGE_ORDERS: 'actions-list-manage-orders',
	ACTIONS_LIST_MANAGE_BOOKINGS: 'actions-list-manage-bookings',
	ACTIONS_LIST_MANAGE_DISCOUNTS: 'actions-list-manage-discounts',
	ACTIONS_LIST_OPEN_STORE_SETTINGS: 'actions-list-open-store-settings',
	AI_HEATMAP_BUTTON: 'ai-heatmap-button',
	BOOKINGS_REDIRECT: 'bookings-redirect',
	PRODUCTS_REDIRECT: 'products-redirect',
	PRODUCTS_ADD_REDIRECT: 'products-add-redirect',
	PRODUCTS_EDIT_REDIRECT: 'products-edit-redirect',
	PAYMENT_MANAGEMENT_REDIRECT: 'payment-management-redirect',
	SHIPPING_EDIT_REDIRECT: 'shipping-edit-redirect',
	COMPANY_INFORMATION_REDIRECT: 'company-information-redirect',
};

export const HOSTINGER_URL_HELP = `${import.meta.env.VITE_HPANEL_URL}/help?ref=/websites&topic=website_builder`;
