<template>
	<div class="ecommerce-tab-buy-button">
		<ZyroFieldToggle
			id="buyButton"
			v-qa="`editproductlist-tabbuybutton-enable-${isButtonEnabled}`"
			class="ecommerce-tab-buy-button__controls"
			:label="$t('builder.showAddToBagButton')"
			:model-value="isButtonEnabled"
			@update:model-value="updateIsButtonEnabled"
		/>
		<template v-if="isButtonEnabled">
			<div
				v-if="hasNonPurchasableProducts"
				class="ecommerce-tab-buy-button__disclaimer"
			>
				<Icon
					class="ecommerce-tab-buy-button__disclaimer-icon"
					dimensions="16px"
					name="info"
				/>
				<p class="text-body-2">
					{{ $t('builder.ecommerceNonPurchasableItemsDisclaimer') }}
				</p>
			</div>
			<ZyroSeparator class="ecommerce-tab-buy-button__separator" />
			<ZyroRadioList
				v-qa="`editproductlist-tabbuybutton-controls`"
				:model-value="buttonDisplay"
				:options="buttonDisplayOptions"
				class="ecommerce-tab-buy-button__controls"
				@update:model-value="updateButtonDisplay"
			/>
			<EditBlockTabButton />
			<div class="ecommerce-tab-buy-button__toggle">
				<ZyroLabel>
					{{ $t('builder.stretchToFullWidth') }}
				</ZyroLabel>
				<ZyroToggle
					id="button-full-width"
					:model-value="isFullWidth"
					@update:model-value="setIsFullWidth"
				/>
			</div>
		</template>
	</div>
</template>

<script>
import ZyroFieldToggle from '@/components/global/ZyroFieldToggle.vue';
import ZyroRadioList from '@/components/global/ZyroRadioList.vue';
import cloneDeep from 'lodash.clonedeep';
import ZyroSeparator from '@/components/global/ZyroSeparator.vue';
import Icon from '@/components/global/Icon.vue';
import ZyroLabel from '@/components/global/ZyroLabel.vue';
import ZyroToggle from '@/components/global/ZyroToggle.vue';
import {
	mapState,
	mapGetters,
	mapActions,
} from 'vuex';
import EditBlockTabButton from '@/components/builder-controls/edit-block-ecommerce-product/EditBlockTabButton.vue';
import { useEcommerceStore } from '@/stores/ecommerceStore';

export default {
	components: {
		ZyroFieldToggle,
		ZyroRadioList,
		EditBlockTabButton,
		ZyroSeparator,
		Icon,
		ZyroLabel,
		ZyroToggle,
	},

	setup() {
		const ecommerceStore = useEcommerceStore();

		return {
			products: ecommerceStore.products,
		};
	},

	data() {
		return {
			initialCurrentBlockData: null,
			buttonSettingsRef: null,
		};
	},

	computed: {
		...mapState(['currentBlockId']),
		...mapGetters([
			'currentBlock',
			'currentBlockStyles',
		]),
		buttonDisplayOptions() {
			return {
				always: {
					name: this.$t('common.always'),
				},
				hover: {
					name: this.$t('builder.onlyOnHover'),
				},
			};
		},
		buttonDisplay() {
			return this.currentBlock.buttonDisplay || 'always';
		},
		isButtonEnabled() {
			return this.currentBlock.isButtonEnabled;
		},
		hasNonPurchasableProducts() {
			return this.products.some((product) => !product.purchasable);
		},
		isFullWidth() {
			return this.currentBlock.isButtonFullWidth ?? false;
		},
	},

	mounted() {
		this.buttonSettingsRef = this.$refs.buttonSettings;
		this.blockId = this.currentBlockId;
		this.initialCurrentBlockData = cloneDeep(this.currentBlock);
	},

	beforeUnmount() {
		this.createSnapshot();
	},

	methods: {
		...mapActions(['updateBlockData']),
		...mapActions('undoRedo', ['createSnapshot']),

		updateIsButtonEnabled(isEnabled) {
			this.updateBlockData({
				blockId: this.blockId,
				blockData: {
					isButtonEnabled: isEnabled,
				},
				merge: true,
			});
		},
		updateButtonDisplay(newValue) {
			this.updateBlockData({
				blockId: this.blockId,
				blockData: {
					buttonDisplay: newValue,
				},
				merge: true,
			});
		},
		setIsFullWidth(value) {
			this.updateBlockData({
				blockId: this.blockId,
				blockData: {
					isButtonFullWidth: value,
				},
				merge: true,
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.ecommerce-tab-buy-button {
	&__controls {
		padding: 0;
		margin: 24px 0;

		&:first-child {
			margin: 0;
		}
	}

	&__separator {
		margin: 24px 0;
	}

	&__input {
		margin: 6px 0 25px;
	}

	&__disclaimer {
		display: flex;
		margin-top: 24px;
		color: $color-gray-dark;
	}

	&__disclaimer-icon {
		flex: 1 0 auto;
		margin-right: 8px;
	}

	&__toggle {
		display: flex;
		justify-content: space-between;
		margin-top: 16px;
	}
}
</style>
