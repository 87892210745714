import { captureException } from '@sentry/vue';

import {
	META_ECOMMERCE_TYPE,
	ECOMMERCE_TYPE_ZYRO,
} from '@zyro-inc/site-modules/constants';
import {
	getIsLocaleWithEcommerce,
	getIsSiteWithEcommerce,
} from '@zyro-inc/site-modules/utils/getters/getIsLocaleWithEcommerce';
import { getStoreId } from '@zyro-inc/site-modules/utils/getters/getStoreId';

import {
	getSettings,
	getVariantsQuantity,
	getCategories,
} from '@/api/StoreApi';
import { getMessagesByLocale } from '@/i18n/setup';

import { generateRandomId } from '@/utils/generateRandomId';
import { getImageSrc } from '@zyro-inc/site-modules/utils/getImageSrc';
import { useSiteStore } from '@/stores';
import { useEcommerceStore } from '@/stores/ecommerceStore';

const ECOMMERCE_PRICE_MAX_AMOUNT = 100000; // in cents
const ECOMMERCE_PRICE_MIN_AMOUNT = 100; // in cents

export default {
	namespaced: true,
	state: {
		isLoadingCategories: false,
		categories: [],
		variantsQuantity: [],
		count: 0,
		productMetaUpdates: {},
	},
	getters: {
		isStoreTypeZyro: (state, getters, rootState, rootGetters) => rootGetters
			.siteMeta[META_ECOMMERCE_TYPE] === ECOMMERCE_TYPE_ZYRO,
		isEcommerceAvailableForUsers: (state, getters, rootState, rootGetters) => !rootGetters['subscription/hasActiveEcommerceSubscription'],
		// need to update this place
		isLocaleWithEcommerceItems: (state, getters, rootState, rootGetters) => getIsLocaleWithEcommerce({
			blocks: rootGetters.siteBlocks,
			elements: rootGetters.siteElements,
		}),
		isSiteWithEcommerceItems: () => {
			const siteStore = useSiteStore();

			return getIsSiteWithEcommerce(siteStore.site);
		},
		isEcommerceAvailableForBusinessPlan: (state, getters, rootState, rootGetters) => (rootGetters['subscription/hasActiveBusinessSubscription']),
		isCartVisible: (state, getters, rootState, rootGetters) => rootGetters.headerBlock.settings.isCartVisible,
		products: (state) => state.products,
		categories: (state) => state.categories,
		count: (state) => state.count,
	},
	mutations: {
		setCategories(state, categories) {
			state.categories = categories;
		},
		setVariantsQuantity(state, variantsQuantity) {
			state.variantsQuantity = variantsQuantity;
		},
		setIsLoadingCategories(state, { isLoading }) {
			state.isLoadingCategories = isLoading;
		},
	},
	actions: {
		setProductMetaUpdates: ({ state }, metaUpdates) => {
			if (!metaUpdates) {
				state.productMetaUpdates = {};

				return;
			}

			const {
				productId,
				productMeta,
			} = metaUpdates;

			if (!state.productMetaUpdates[productId]) {
				state.productMetaUpdates[productId] = {};
			}

			state.productMetaUpdates[productId] = {
				...state.productMetaUpdates[productId],
				...productMeta,
			};
		},
		getSettings: async ({
			rootGetters,
			dispatch,
		}) => {
			const storeId = getStoreId(rootGetters.siteMeta);

			if (!storeId) {
				return;
			}

			try {
				const settings = await getSettings(storeId);
				const storeLanguage = settings.store_owner.language?.toLowerCase();
				let messages = await getMessagesByLocale(storeLanguage);

				if (!messages?.onlineStore) {
					messages = await getMessagesByLocale('en');
				}

				dispatch('addEcommerceShoppingCart', {
					translations: messages.onlineStore,
					lang: storeLanguage,
				}, {
					root: true,
				});
			} catch (error) {
				dispatch('notifications/notify', {
					message: 'Error while getting settings.',
				}, {
					root: true,
				});

				captureException(error);
			}
		},
		updateStoreProduct: ({ state }, updatedProducts) => {
			const ecommerceStore = useEcommerceStore();

			const mergedProducts = ecommerceStore.products.map((product) => {
				const updatedProduct = updatedProducts.find((_updatedProduct) => _updatedProduct.id === product.id);

				if (updatedProduct) {
					return {
						...product,
						...updatedProduct,
					};
				}

				return product;
			});

			ecommerceStore.setStoreProducts({
				products: mergedProducts,
				count: state.count,
			});
		},

		setAiBuilderProducts: async ({ rootState }, {
			images = [],
			productList = [],
		} = {}) => {
			const ecommerceStore = useEcommerceStore();
			const getImageUrl = (index) => getImageSrc(images[index].origin, images[index].path, rootState.websiteId);

			const products = Array.from({
				length: 6,
			}, (_, index) => ({
				id: generateRandomId(),
				images: [
					{
						url: getImageUrl(index),
					},
				],
				thumbnail: getImageUrl(index),
				title: productList[index].title,
				subtitle: productList[index].subtitle,
				description: productList[index].description,
				type: {
					value: 'physical',
				},
				variants: [
					{
						title: productList[index].title,
						prices: [
							{
								amount: Math.floor(
									Math.random() * (ECOMMERCE_PRICE_MAX_AMOUNT - ECOMMERCE_PRICE_MIN_AMOUNT),
								) + ECOMMERCE_PRICE_MIN_AMOUNT,
								currency: {
									code: 'usd',
									decimal_digits: 2,
									template: '$$1',
								},
							},
						],
						options: [],
					},
				],
				options: [],
			}));

			ecommerceStore.setStoreProducts(products);
		},
		getVariantsQuantity: async ({
			commit,
			rootGetters,
			dispatch,
		}) => {
			const storeId = getStoreId(rootGetters.siteMeta);

			if (!storeId) {
				return;
			}

			try {
				const variantsQuantity = await getVariantsQuantity(storeId);

				commit('setVariantsQuantity', variantsQuantity);
			} catch (error) {
				let sentryText = 'Fetching variants quantity';

				if (error.code === 'ECONNABORTED') {
					sentryText = 'Fetching variants quantity: timeout';
				}

				captureException({
					...error,
					message: sentryText,
				});
				dispatch('notifications/notify', {
					message: 'Error while getting products quantity',
				}, {
					root: true,
				});
			}
		},
		getCategories: async ({
			commit,
			dispatch,
			rootGetters,
		}) => {
			const storeId = getStoreId(rootGetters.siteMeta);

			if (!storeId) {
				return;
			}

			try {
				commit('setIsLoadingCategories', {
					isLoading: true,
				});
				const categories = await getCategories(storeId);
				const sortedCategories = categories.sort((a, b) => a.title.localeCompare(b.title));

				commit('setCategories', sortedCategories);
			} catch (error) {
				dispatch('notifications/notify', {
					message: 'Error while getting categories.',
				}, {
					root: true,
				});

				captureException(error);
			} finally {
				commit('setIsLoadingCategories', {
					isLoading: false,
				});
			}
		},

		initEcommerce: async ({ dispatch }, {
			shouldPickStylesFromTheme = false,
			shouldRefetch = false,
		} = {}) => {
			const ecommerceStore = useEcommerceStore();
			const { products } = ecommerceStore;

			dispatch('getVariantsQuantity');

			if (shouldRefetch || !products.length) {
				// needed for addEcommerceProductPages store action
				await ecommerceStore.fetchProducts({
					pickStylesFromTheme: shouldPickStylesFromTheme,
				});
			}

			await dispatch('getSettings');
			await dispatch('getCategories');
		},
	},
};
