<template>
	<div class="ecommerce-tab-product">
		<EcommerceNoProducts v-if="!ecommerceStore.products.length" />
		<div
			v-else
			class="ecommerce-tab-product__content"
		>
			<template v-if="!ecommerceStore.getIsBlockInEcommerceProductPage(blockId)">
				<ZyroLabel>
					{{ $t('builder.editButton.ecommerce.connectButtonTo') }}
				</ZyroLabel>
				<ZyroSelect
					v-qa="'ecommerce-tab-product-select'"
					class="ecommerce-tab-product__dropdown"
					:options="ecommerceStore.products"
					label-key="title"
					:model-value="product"
					is-infinite-scroll-enabled
					:total-options-count="ecommerceStore.count"
					@load-more="loadProducts"
					@update:model-value="handleProductSelect"
				>
					<template #option="{ option }: {option: any}">
						<div
							v-qa="`dropdown-option-${option.title}`"
							class="ecommerce-tab-product__dropdown-option"
						>
							<span
								v-if="option.sku"
								class="ecommerce-tab-product__dropdown-prefix"
							>
								{{ `SKU ${option.sku}` }}
							</span>
							{{ option.title }}
						</div>
					</template>
				</ZyroSelect>
			</template>
			<template v-if="isProductIdValid">
				<HostingerButton
					v-qa="'product-tab-product-edit-button'"
					:is-disabled="isAiBuilderMode"
					button-type="outlined"
					class="ecommerce-tab-product__edit-button"
					@click="handleButtonClick(product.id)"
				>
					{{ $t('builder.editButton.ecommerce.editProduct') }}
				</HostingerButton>
				<div
					v-if="!product.purchasable"
					class="ecommerce-tab-product__disclaimer"
				>
					<Icon
						dimensions="16px"
						name="info"
						class="ecommerce-tab-product__disclaimer-icon"
					/>
					<p class="text-body-2">
						{{
							isAiBuilderMode
								? $t('builder.ecommerceAiBuilderProductEditDisclaimer')
								: $t('builder.ecommerceNonPurchasableProductsDisclaimer')
						}}
					</p>
				</div>
			</template>
		</div>
	</div>
</template>

<script setup lang="ts">
import ZyroSelect from '@/components/global/ZyroSelect.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroLabel from '@/components/global/ZyroLabel.vue';
import Icon from '@/components/global/Icon.vue';

import { useStore } from 'vuex';

import { useI18n } from 'vue-i18n';
import EventLogApi from '@/api/EventLogApi';

import {
	computed,
	onBeforeUnmount,
	onMounted,
	ref,
} from 'vue';
import EcommerceNoProducts from '@/components/builder-controls/edit-button/EcommerceNoProducts.vue';
import { useBuilderMode } from '@/use/useBuilderMode';
import { EcommerceProduct } from '@zyro-inc/site-modules/types';
import {
	ECOMMERCE_REDIRECT_PATHS,
	REDIRECT_PARAM_KEYS,
	ECOMMERCE_FUNNEL_LOCATIONS,
} from '@/constants';
import { useEcommerceAdminStore } from '@/stores';
import { useEcommerceStore } from '@/stores/ecommerceStore';

const { isAiBuilderMode } = useBuilderMode();
const ecommerceAdminStore = useEcommerceAdminStore();
const ecommerceStore = useEcommerceStore();

const { t } = useI18n();
const {
	state,
	getters,
	dispatch,
} = useStore();

const currentPage = ref(0);

const blockId = computed(() => state.currentBlockId);
const currentBlock = computed(() => getters.currentBlock);
const product = computed<EcommerceProduct>(() => {
	const dynamicPageProductId = ecommerceStore.currentDynamicPageProductId;

	if (dynamicPageProductId) {
		return ecommerceStore.products.find(({ id }) => id === dynamicPageProductId) as EcommerceProduct;
	}

	const { product: currentBlockProduct } = currentBlock.value;
	const assignedProduct = ecommerceStore.products.find(({ id }) => id === currentBlockProduct.id);

	return assignedProduct || ({
		id: -1,
		title: t('builder.ecommerce.chooseProduct'),
	}) as EcommerceProduct;
});
const isProductIdValid = computed(() => product.value.id !== -1);

const handleProductSelect = (selectedProduct: EcommerceProduct) => {
	dispatch('updateBlockData', {
		blockId: blockId.value,
		blockData: {
			product: {
				id: selectedProduct.id,
			},
		},
		merge: true,
	});
};

const handleButtonClick = async (productId: string | number) => {
	await EventLogApi.logEvent({
		eventName: 'website_builder.ecomm_products.edit',
		eventProperties: {
			location: 'section',
		},
	});

	ecommerceAdminStore.setIsEcommerceAdminIframeOpen({
		isOpen: true,
		path: ECOMMERCE_REDIRECT_PATHS.PRODUCTS_EDIT,
		params: {
			[REDIRECT_PARAM_KEYS.PRODUCT]: productId as string,
		},
		location: ECOMMERCE_FUNNEL_LOCATIONS.IFRAME_EDIT_PRODUCT_BLOCK_TAB,
	});
};

const loadProducts = async () => {
	if (ecommerceStore.count === ecommerceStore.products.length) {
		return;
	}

	currentPage.value += 1;

	await ecommerceStore.fetchProducts({
		limit: 25 * currentPage.value,
	});
};

onMounted(async () => {
	await loadProducts();
});

onBeforeUnmount(() => {
	dispatch('undoRedo/createSnapshot');
});
</script>

<style lang="scss" scoped>
.ecommerce-tab-product {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&__content {
		display: flex;
		flex-direction: column;
	}

	&__dropdown {
		margin-top: 8px;
		margin-bottom: 24px;
		background-color: $color-light;
	}

	&__dropdown-option {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&__dropdown-prefix {
		color: $color-gray;
	}

	&__edit-button {
		margin: 0 auto;
	}

	&__disclaimer {
		display: flex;
		align-items: center;
		margin-top: 24px;
		color: $color-gray-dark;
	}

	&__disclaimer-icon {
		flex: 1 0 auto;
		margin-right: 8px;
	}
}
</style>
