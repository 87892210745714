<template>
	<div class="edit-block-header-tab-logo">
		<ZyroImageSelector
			:origin="currentBlockSettings.logoImageOrigin"
			:path="currentBlockSettings.logoImagePath"
			:logo-svg="currentBlockSettings.logoSvg"
			@update="updateLogo"
		/>
		<div class="edit-block-header-tab-logo__ai-link-container">
			<Icon
				name="auto_awesome"
				is-filled
				dimensions="20px"
			/>
			<a
				class="text-body-2 z-link"
				target="_blank"
				rel="noopener"
				@click="redirectToLogoMaker"
			>
				{{ $t('builder.editBlockNavigation.tabLogo.generate') }}
			</a>
		</div>
		<ZyroFieldRange
			:model-value="isMobileMode ? logoMobileWidth : logoWidth"
			:qa="`editblocknavigation-tablogo-logowidth-${isMobileMode ? logoMobileWidth : logoWidth}`"
			min="30"
			:max="maxLogoWidth"
			:label="$t('builder.editBlockNavigation.tabLogo.logoWidth')"
			@update:model-value="updateLogoWidth"
		/>
	</div>
</template>

<script>
import ZyroFieldRange from '@/components/global/ZyroFieldRange.vue';
import ZyroImageSelector from '@/components/global/ZyroImageSelector.vue';
import Icon from '@/components/global/Icon.vue';
import {
	mapActions,
	useStore,
} from 'vuex';

import {
	defineComponent,
	computed,
} from 'vue';
// eslint-disable-next-line import/no-cycle
import { useUpdateLogo } from '@/components/builder-controls/edit-block-header/useUpdateLogo';
import {
	ELEMENT_POSITION_KEY_MOBILE,
	ELEMENT_POSITION_KEY_DESKTOP,
} from '@zyro-inc/site-modules/constants';
import { authRedirectService } from '@/services/http/authRedirectService';

export const MAX_LOGO_WIDTH = 350;
export const MAX_LOGO_WIDTH_MOBILE = 250;

export default defineComponent({
	components: {
		ZyroFieldRange,
		ZyroImageSelector,
		Icon,
	},

	setup() {
		const {
			state,
			getters,
		} = useStore();
		const { updateLogo } = useUpdateLogo();
		const currentBlockId = computed(() => state.currentBlockId);
		const isMobileMode = computed(() => getters['gui/isMobileMode']);
		const headerBlock = computed(() => getters.headerBlock);
		const currentBlock = computed(() => getters.currentBlock);
		const currentBlockSettings = computed(() => getters.currentBlockSettings);
		const currentBlockStyles = computed(() => getters.currentBlockStyles);

		return {
			updateLogo,
			MAX_LOGO_WIDTH,
			MAX_LOGO_WIDTH_MOBILE,
			currentBlock,
			currentBlockSettings,
			currentBlockStyles,
			isMobileMode,
			headerBlock,
			currentBlockId,
		};
	},

	data() {
		return {
			align: '',
		};
	},

	computed: {
		logoMobileWidth() {
			return Number.parseInt(this.currentBlockStyles?.['m-logo-width'], 10);
		},
		logoWidth() {
			return Number.parseInt(this.currentBlockStyles?.['logo-width'], 10);
		},
		logoAspectRatio() {
			return this.currentBlock.logoAspectRatio;
		},
		maxLogoWidth() {
			return this.isMobileMode ? this.MAX_LOGO_WIDTH_MOBILE : this.MAX_LOGO_WIDTH;
		},
	},

	methods: {
		...mapActions(['updateBlockData']),

		redirectToLogoMaker() {
			authRedirectService.redirectToLogoMaker({
				ref: 'navigation-edit',
			});
		},
		updateLogoWidth(newValue) {
			const key = this.isMobileMode ? 'm-logo-width' : 'logo-width';
			const elementPositionKey = this.isMobileMode ? ELEMENT_POSITION_KEY_MOBILE : ELEMENT_POSITION_KEY_DESKTOP;

			if (this.logoAspectRatio) {
				const logoHeight = Math.ceil(newValue / this.logoAspectRatio);

				this.updateBlockData({
					blockId: this.currentBlockId,

					blockData: {
						[elementPositionKey]: {
							logoHeight,
						},
						settings: {
							styles: {
								[key]: `${newValue}px`,
							},
						},
					},
					merge: true,
				});

				return;
			}

			this.updateBlockData({
				blockId: this.currentBlockId,
				blockData: {
					settings: {
						styles: {
							[key]: `${newValue}px`,
						},
					},
				},
				merge: true,
			});
		},
	},
});
</script>

<style lang="scss" scoped>
.edit-block-header-tab-logo {
	&__ai-link-container {
		display: flex;
		color: $color-primary;
		gap: 8px;
		align-items: center;
		cursor: pointer;
	}

	&__menu-position-label {
		margin: 16px 0 8px;
	}

	&__menu-position-icons {
		margin: 8px 0;
	}
}
</style>
