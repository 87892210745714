import { computed } from 'vue';
import { useStore } from 'vuex';
import { generateRandomId } from '@/utils/generateRandomId';
import {
	PAGE_TYPE_PORTFOLIO,
	PAGE_TYPE_BLOG,
	PAGE_TYPE_ONLINE_STORE,
	PAGE_TYPE_APPOINTMENTS,
	PAGE_TYPE_DEFAULT_SLUG,
	BLOG_POST_EXAMPLE_POSTS_PER_PAGE_COUNT,
	BLOG_POST_EXAMPLE_POST_COUNT,
	BLOG_POST_EXAMPLE_POST_COLUMN_COUNT,
} from '@/constants';
import { CLOSE_MODAL } from '@/store/builder/gui';
import { NAVIGATION_PAGE_TYPE } from '@zyro-inc/site-modules/constants';
import { useI18n } from 'vue-i18n';
import { useDrawerPageSettingsPopup } from '@/use/useDrawerPageSettingsPopup';

import {
	getPortfolioBlock,
	getPageTitleBlock,
	getEcommerceProductListBlock,
	getEcommerceProductBlock,
} from '@/components/block/blocks';
import {
	addPage,
	removePage,
} from '@/utils/siteDataUtils';
import { getBiggestIncrementedString } from '@zyro-inc/site-modules/utils/modifyString';
import { BLOG_PLACEHOLDERS } from '@/data';
import { getValidPagePath } from '@/utils/urlValidators';
import { common } from '@/i18n/defaultMessages.json';
import { useSiteStore } from '@/stores/siteStore';
import { useEcommerceStore } from '@/stores/ecommerceStore';
/**
 * Returns an array of blog posts data that is ready to be used with addBlog() action
 */
export const getExampleBlogPostsData = ({
	postTitle,
	postDescription,
	postContent,
	postThumbnails,
	postCount = BLOG_POST_EXAMPLE_POSTS_PER_PAGE_COUNT,
} = {}) => [...Array(postCount)].map((_, index) => ({
	postTitle,
	postDescription,
	postContent,
	postThumbnail: postThumbnails[index],
}));

export const useAddPage = () => {
	const {
		getters,
		dispatch,
		state,
	} = useStore();

	const { t } = useI18n();
	const { setActiveDrawerPageSettingsPopup } = useDrawerPageSettingsPopup();
	const siteStore = useSiteStore();
	const ecommerceStore = useEcommerceStore();

	const website = computed(() => siteStore.site);
	const currentLocale = computed(() => state.currentLocale);

	const portfolioBlock = getPortfolioBlock();

	const pagesByType = {
		[PAGE_TYPE_PORTFOLIO]: {
			pageTitle: t('common.portfolio'),
			blockData: portfolioBlock.blockData,
			blockElements: portfolioBlock.elements,
		},
		[PAGE_TYPE_ONLINE_STORE]: {
			pageTitle: t('common.products'),
			blockData: getEcommerceProductListBlock({
				products: ecommerceStore.products,
				textAlign: 'outlined',
				blockPadding: '100px 16px',
				imageRatio: 'portrait',
				imageHoverEffect: 'swap_image',
				columnCountDesktop: 3,
				columnGapDesktop: 20,
				productsPerPage: 6,
				isButtonEnabled: true,
				buttonDisplay: 'always',
				isButtonFullWidth: true,
				isSortingEnabled: true,
				isCategoryListEnabled: true,
				isTotalProductCountShown: true,
				buttonBorderWidth: 1,
				buttonStyle: {
					'grid-button-primary-color': 'rgb(0, 0, 0)',
					'grid-button-primary-background-color': 'rgba(0, 0, 0, 0)',
					'grid-button-primary-border-color': 'rgb(0, 0, 0)',
				},
			}),
			isPageTypeEcommerce: true,
		},
		[PAGE_TYPE_APPOINTMENTS]: {
			pageTitle: t('common.appointments'),
			blockData: getEcommerceProductBlock({
				buttonText: t('common.bookNow'),
			}),
			isPageTypeEcommerce: true,
		},
	};

	const addPageWithContent = async ({
		newPageId = generateRandomId(),
		newPageName,
		newPageSlug,
		newPageBlocks,
		newPageElements,
		isPageTypeEcommerce = false,
		shouldUpdateCurrentPage = false,
		shouldOpenPageSettingsPopup = false,
	}) => {
		const websiteDataWithNewPage = addPage({
			locale: currentLocale.value,
			siteData: website.value,
			pageId: newPageId,
			pageData: {
				name: newPageName,
				slug: newPageSlug,
				blocks: [...Object.keys(newPageBlocks)],
				type: 'default',
			},
			blocks: {
				...newPageBlocks,
			},
			elements: {
				...newPageElements,
			},
			navigationItem: {
				linkType: NAVIGATION_PAGE_TYPE,
				subItems: [],
			},
			isPageTypeEcommerce,
		});

		await dispatch('overwriteWebsiteData', {
			websiteData: websiteDataWithNewPage,
		});

		if (shouldUpdateCurrentPage) {
			dispatch('updateCurrentPageId', newPageId);
		}

		if (shouldOpenPageSettingsPopup) {
			setActiveDrawerPageSettingsPopup({
				pageId: newPageId,
			});
		}
	};

	const addPageWithTitle = async ({
		pageId,
		pageTitle,
		blockData,
		blockElements = {},
		isPageTypeEcommerce = false,
	}) => {
		const blockId = generateRandomId();
		const titleBlockId = generateRandomId();
		const titleBlock = getPageTitleBlock({
			title: pageTitle,
		});

		const blocks = {
			[titleBlockId]: titleBlock.blockData,
			[blockId]: blockData,
		};

		const elements = {
			...titleBlock.elements,
			...blockElements,
		};

		const websiteDataWithNewPage = addPage({
			siteData: website.value,
			pageId,
			pageData: {
				name: pageTitle,
				slug: getBiggestIncrementedString({
					stringToMatch: getValidPagePath(pageTitle || PAGE_TYPE_DEFAULT_SLUG).path,
					strings: Object.values(getters.currentLanguageData.pages).map(({ slug }) => slug),
				}),
				blocks: [
					titleBlockId,
					blockId,
				],
				type: 'default',
			},
			blocks,
			elements,
			navigationItem: {
				linkType: NAVIGATION_PAGE_TYPE,
				subItems: [],
			},
			isPageTypeEcommerce,
		});

		dispatch('overwriteWebsiteData', {
			websiteData: websiteDataWithNewPage,
		});
	};

	const addPageByType = ({
		pageType,
		pageId,
		blogPageData,
		aiTranslations,
	}) => {
		if (pageType === PAGE_TYPE_BLOG) {
			const blogPostsData = blogPageData?.items.map((blogPostData, blogPostIndex) => ({
				postTitle: blogPostData.title,
				postDescription: blogPostData.description,
				postContent: blogPostData.description,
				postThumbnail: blogPageData.images[blogPostIndex],
			}));

			dispatch('addBlog', {
				pageTitle: aiTranslations?.[common.blog] || t('common.blog'),
				pageId,
				blogPostsData,
			});

			return;
		}

		const {
			pageTitle: pageTitleByType,
			blockData,
			blockElements,
			isPageTypeEcommerce,
		} = pagesByType[pageType];

		if (pageType === PAGE_TYPE_ONLINE_STORE) {
			dispatch('generateEcommercePages');
		}

		addPageWithTitle({
			pageId,
			pageTitle: aiTranslations?.[pageTitleByType] || pageTitleByType,
			blockData,
			blockElements,
			isPageTypeEcommerce,
		});
	};

	const removePageById = ({
		pageType,
		pageId,
	}) => {
		const websiteDataWithRemovedPage = removePage({
			pageId,
			siteData: website.value,
		});

		dispatch('overwriteWebsiteData', {
			websiteData: websiteDataWithRemovedPage,
		});

		const isEcommercePage = pageType === PAGE_TYPE_ONLINE_STORE || pageType === PAGE_TYPE_APPOINTMENTS;
		const isAppointmentsPageAdded = getters.doesPageIdAlreadyExist({
			pageId: PAGE_TYPE_APPOINTMENTS,
		});
		const isOnlineStorePageAdded = getters.doesPageIdAlreadyExist({
			pageId: PAGE_TYPE_ONLINE_STORE,
		});
		const shouldDeleteEcommerceItems = isEcommercePage && !isOnlineStorePageAdded && !isAppointmentsPageAdded;

		if (shouldDeleteEcommerceItems) {
			ecommerceStore.deleteEcommerceItemsFromSite();
		}
	};

	const addBlogPage = ({
		pageId = generateRandomId(),
		shouldOpenPageSettingsPopup = false,
		postsPerPage = BLOG_POST_EXAMPLE_POSTS_PER_PAGE_COUNT,
		postColumnCount = BLOG_POST_EXAMPLE_POST_COLUMN_COUNT,
	} = {}) => {
		const blogPostsData = getExampleBlogPostsData({
			postTitle: t('builder.blog.blogPost.title'),
			postDescription: t('builder.blog.blogPost.description'),
			postContent: t('builder.blog.blogPageElements.content'),
			postThumbnails: BLOG_PLACEHOLDERS,
			postCount: BLOG_POST_EXAMPLE_POST_COUNT,
		});

		dispatch('addBlog', {
			pageId,
			pageTitle: t('common.blog'),
			blogPostsData,
			postColumnCount,
			postsPerPage,
		});

		dispatch(`gui/${CLOSE_MODAL}`);

		// Sends Hotjar event
		if (state.user.user?.id) {
			window.hj('identify', state.user.user.id, {
				'builder.blog.add_blog_page': true,
			});
		}

		if (shouldOpenPageSettingsPopup) {
			setActiveDrawerPageSettingsPopup({
				pageId,
			});
		}
	};

	return {
		addBlogPage,
		addPageWithContent,
		addPageByType,
		removePageById,
	};
};
