<template>
	<div class="ecommerce-tab-category">
		<div class="ecommerce-tab-category__select-wrapper">
			<p class="text-bold-2 ecommerce-tab-category__label">
				{{ $t('builder.whichCategoryToShow') }}
			</p>
			<ZyroSelect
				v-qa="'ecommerce-tab-category-select'"
				class="ecommerce-tab-category__dropdown"
				label-key="title"
				:options="categoryList"
				:model-value="productCategory"
				:disabled="isCategoryListEnabled"
				@update:model-value="updateProductCategory"
			/>
		</div>
		<div class="ecommerce-tab-category__filter-toggle">
			<template v-if="isCategoryFilterSelectionEnabled">
				<ZyroLabel>
					{{ $t('builder.showCategoriesAsFilters') }}
				</ZyroLabel>
				<ZyroToggle
					id="categories-list"
					:model-value="isCategoryListEnabled"
					@update:model-value="setIsCategoryListEnabled"
				/>
			</template>
			<p
				v-else
				class="text-body-2 ecommerce-tab-category__disclaimer"
			>
				{{ $t('builder.showCategoriesAsFiltersNotice') }}
			</p>
		</div>
	</div>
</template>

<script lang="ts" setup>
import ZyroSelect from '@/components/global/ZyroSelect.vue';
import cloneDeep from 'lodash.clonedeep';
import ZyroToggle from '@/components/global/ZyroToggle.vue';
import { useStore } from 'vuex';
import {
	ref,
	computed,
	onMounted,
	onBeforeUnmount,
} from 'vue';
import { getFilteredProductsByCategoryId } from '@/utils/ecommerce';
import { useI18n } from 'vue-i18n';
import {
	EcommerceCollection,
	EcommerceProduct,
} from '@zyro-inc/site-modules/types';
import { SiteBlock } from '@hostinger/builder-schema-validator';
import ZyroLabel from '@/components/global/ZyroLabel.vue';
import { useEcommerceStore } from '@/stores/ecommerceStore';

const {
	dispatch,
	getters,
	state,
} = useStore();
const { t } = useI18n();
const ecommerceStore = useEcommerceStore();

const categoryList = ref<EcommerceCollection[]>([]);
const blockId = ref('');
const initialCurrentBlockData = ref<SiteBlock | null>(null);

const currentBlockId = computed<string>(() => state.currentBlockId);
const categories = computed<EcommerceCollection[]>(() => getters['ecommerce/categories']);
const currentBlock = computed<SiteBlock>(() => getters.currentBlock);
const defaultCategory = computed<EcommerceCollection>(() => ({
	id: '',
	title: t('builder.ecommerceProductListCategoryAllProducts'),
}) as EcommerceCollection);
const productCategory = computed<EcommerceCollection>(() => categories.value
	.find(({ id }: { id: string }) => id === currentBlock.value.productCategoryId) || defaultCategory.value);
const isCategoryFilterSelectionEnabled = computed(() => productCategory.value.id === '');
const isCategoryListEnabled = computed(() => currentBlock.value.isCategoryListEnabled);

const setCategories = () => {
	categoryList.value = [
		defaultCategory.value,
		...categories.value,
	];
};

const updateProductCategory = (newValue: EcommerceCollection) => {
	const filteredProducts: EcommerceProduct[] = !newValue.id
		? ecommerceStore.products.sort((a, b) => a.title.localeCompare(b.title))
		: getFilteredProductsByCategoryId(ecommerceStore.products, newValue.id);

	dispatch('updateBlockData', {
		blockId: blockId.value,
		blockData: {
			productCategoryId: newValue.id,
			productIds: filteredProducts.map((product) => product.id),
		},
		merge: true,
	});
};

const setIsCategoryListEnabled = (value: boolean) => {
	dispatch('updateBlockData', {
		blockId: blockId.value,
		blockData: {
			productCategoryId: undefined,
			productIds: ecommerceStore.products.map((product) => product.id),
			isCategoryListEnabled: value,
		},
		merge: true,
	});
};

setCategories();

onMounted(() => {
	blockId.value = currentBlockId.value;
	initialCurrentBlockData.value = cloneDeep(currentBlock.value);
});

onBeforeUnmount(() => {
	dispatch('undoRedo/createSnapshot');
});

</script>

<style lang="scss" scoped>
.ecommerce-tab-category {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&__disclaimer {
		color: var(--color-gray)
	}

	&__filter-toggle {
		display: flex;
		justify-content: space-between;
	}

	&__select-wrapper {
		margin-bottom: 16px;
		border-bottom: 1px solid var(--color-gray-border);

		:deep(.vs__selected-options) {
			flex-wrap: nowrap;
			align-items: center;
			max-width: calc(100% - 16px);
		}

		:deep(.vs__selected) {
			display: block;
			white-space: nowrap;
			text-overflow: ellipsis;
			max-width: calc(100% - 20px);
			overflow: hidden;
			height: fit-content;
		}
	}

	&__dropdown {
		background-color: $color-light;
		margin-bottom: 16px;
		height: 40px;
	}

	&__label {
		padding: 8px 0;
	}
}
</style>
