<template>
	<div>
		<p class="text-bold-2 link-settings__label">
			{{ $t('builder.editButton.linkSettings.label') }}
		</p>
		<ZyroSelect
			v-model="selectedLinkType"
			v-qa="'linksettingsmodal-inputdropdown-page'"
			label-key="title"
			:options="dropdownValues"
			class="link-settings__input-dropdown"
			:label="$t('builder.editButton.linkSettings.label')"
			@update:model-value="handleLinkTypeSelect"
			@blur="handleLinkTypeBlur"
		>
			<template #selected-option>
				<div
					v-if="selectedLinkTypeItem"
					v-qa="'link-settings-selected-link-type'"
					class="link-settings__selected-type"
				>
					<Icon
						:name="selectedLinkTypeItem.icon"
						dimensions="20px"
					/>
					{{ selectedLinkTypeItem.title }}
				</div>
			</template>

			<template #option="{ option }">
				<div
					v-qa="`dropdown-option-${option.title}`"
					class="link-type-option"
				>
					<div class="link-type-option__left">
						<Icon
							:name="option.icon"
							dimensions="20px"
						/>
						{{ option.title }}
					</div>
					<Icon
						v-if="option.type === selectedLinkType"
						name="check"
						dimensions="20px"
					/>
				</div>
			</template>
		</ZyroSelect>

		<LinkSettingsFileDownload
			v-if="isTypeSelected(LINK_TYPE.DOWNLOAD)"
			:href="href"
			@update:target="$emit('update:target', $event)"
			@update:href="$emit('update:href', $event)"
		/>

		<template v-else>
			<div v-if="isTypeSelected(LINK_TYPE.ANCHORED_SECTION)">
				<ZyroSelect
					v-qa="'section-link-dropdown'"
					class="link-settings__anchored-section-datalist"
					label-key="slug"
					:options="anchoredSections"
					:model-value="currentLink.value ? currentLink.value : null"
					:placeholder="anchoredSectionDropdownPlaceholder"
					:disabled="!anchoredSections.length"
					@update:model-value="handleLinkChange"
				>
					<template #selected-option>
						<i18n-t
							tag="span"
							keypath="builder.editButton.linkSettings.anchorInPage"
							class="anchored-section__search-value"
						>
							<span class="anchor-id">{{ currentLink.value }}</span>
							<span v-qa="'section-link-dropdown-active-item'">{{ currentLink.title }}</span>
						</i18n-t>
					</template>

					<template #option="{ option }">
						<span>{{ option.value }}</span>
						<br>
						<i18n-t
							tag="span"
							keypath="builder.editButton.linkSettings.anchorWithPageSuffix"
							class="anchored-section__option-page-name"
						>
							{{ option.title }}
						</i18n-t>
					</template>
				</ZyroSelect>
			</div>

			<ZyroSelect
				v-if="isTypeSelected(LINK_TYPE.PAGE)"
				v-qa="'page-link-dropdown'"
				class="link-settings__anchored-section-datalist"
				:options="internalPages"
				label-key="title"
				:model-value="currentLink.value ? currentLink.value : null"
				:placeholder="t('builder.editButton.linkSettings.pagePlaceholder')"
				@update:model-value="handleLinkChange"
			>
				<template #selected-option>
					<span v-qa="'page-link-dropdown-active-item'">{{ currentLink.title }}</span>
				</template>

				<template #option="{ option }">
					<span>{{ option.title }}</span>
				</template>
			</ZyroSelect>

			<ZyroFieldInput
				v-if="isTextInputVisible"
				:id="`${elementId}-link`"
				v-qa="'new-link-url-input-field'"
				:model-value="currentLink.value ? currentLink.value : null"
				qa-selector="linksettingsmodal-input"
				:placeholder="textInputPlaceholders[selectedLinkType as LinkType]"
				:error="!isCustomLinkURLValid && $t('validate.url')"
				@input-blur="handleInputBlur"
				@update:model-value="handleLinkChange"
			/>

			<ZyroFieldToggle
				v-if="isNewTabToggleVisible"
				:id="`${elementId}-toggle-openinnewtab`"
				v-qa="'linksettingsmodal-inputtoggle-openinnewtab'"
				class="link-settings__toggle"
				:model-value="target === '_blank'"
				:label="$t('builder.editButton.linkSettings.toggleFieldLabel')"
				@update:model-value="toggleOpenNewTab"
			/>

			<ZyroFieldToggle
				v-if="isNoLinkToggleVisible"
				:id="`${elementId}-toggle-relnofollow`"
				v-qa="'linksettingsmodal-inputtoggle-relnofollow'"
				class="link-settings__toggle"
				:model-value="rel === 'nofollow'"
				:label="$t('builder.editButton.linkSettings.toggleRelNoFollowFieldLabel')"
				:message="$t('builder.editButton.linkSettings.toggleRelNoFollowFieldDescription')"
				@update:model-value="toggleRelNoFollow"
			/>
		</template>
	</div>
</template>

<script setup lang="ts">
import ZyroFieldInput from '@/components/global/ZyroFieldInput.vue';
import ZyroFieldToggle from '@/components/global/ZyroFieldToggle.vue';
import ZyroSelect from '@/components/global/ZyroSelect.vue';

import { useStore } from 'vuex';

import {
	LINK_TYPE,
	LinkType,
	PAGE_TYPE_ECOMMERCE_PRODUCT,
	PAGE_TYPE_PRIVATE,
} from '@zyro-inc/site-modules/constants';

import LinkSettingsFileDownload from '@/components/builder-controls/edit-button/LinkSettingsFileDownload.vue';
import { generateRandomId } from '@/utils/generateRandomId';
import {
	getValidPhone,
	getValidEmail,
	getValidUrl,
} from '@/utils/urlValidators';

import {
	ref,
	computed,
	watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useBuilderMode } from '@/use/useBuilderMode';
import Icon from '@/components/global/Icon.vue';
import { PAGE_TYPE_BLOG } from '@/constants';
import {
	SiteBlocks,
	SiteMeta,
	SitePage,
	SitePages,
} from '@hostinger/builder-schema-validator';
import { EcommerceProduct } from '@zyro-inc/site-modules/types';
import { useEcommerceStore } from '@/stores/ecommerceStore';

interface Link {
	value: string;
	type: LinkType;
	title?: string;
	slug?: string;
}

interface LinkTypeDropdownItem {
	title: string;
	icon: string;
	type: LinkType;
}

interface Props {
	target: string;
	href: string;
	rel: string;
	type: LinkType;
	pageId?: string;
}

const DEFAULT_PREFIX = 'https://';

const ALLOWED_PREFIXES = [
	DEFAULT_PREFIX,
	'http://',
	'sms:',
	'fax:',
	'tel:',
	'mailto:',
	'/',
];

const INITIAL_LINK: Link = {
	value: '',
	type: LINK_TYPE.PAGE,
};

const props = withDefaults(defineProps<Props>(), {
	target: '_self',
	type: 'page',
	href: '',
	pageId: '',
});

const emit = defineEmits<{
	'update:type': [LinkType],
	'update:href': [string],
	'update:pageId': [string],
	'update:target': [string],
	'update:rel': [string],
}>();

const { t } = useI18n();
const {
	getters,
	state,
} = useStore();
const ecommerceStore = useEcommerceStore();
const { isAiBuilderMode } = useBuilderMode();

const sitePages = computed<SitePages>(() => getters.sitePages);
const siteBlocks = computed<SiteBlocks>(() => getters.siteBlocks);
const siteMeta = computed<SiteMeta>(() => getters.siteMeta);

const localePrefix = computed(() => (state.currentLocale !== getters.defaultLocale ? `/${state.currentLocale}` : ''));
const isCurrentPagePrivate = computed(() => getters.isCurrentPagePrivate);
const privatePage = computed<SitePage>(() => getters.privatePage || {});

const getInitialSectionAnchor = (): Link => {
	if (!props.href.includes('#')) {
		return INITIAL_LINK;
	}

	const [pageSlug, anchorId] = props.href.split('#');

	const page = !pageSlug || pageSlug === '/' ? getters.sitePages[getters.homePageId] : Object.values(sitePages.value)
		.find(({ slug }) => {
			const localizedPageSlug = `${localePrefix.value}/${slug ? `${slug}` : ''}`;

			return localizedPageSlug === pageSlug;
		});

	if (!page) {
		return INITIAL_LINK;
	}

	const pageName = isCurrentPagePrivate.value
		? privatePage.value.name
		: page.name;

	return {
		value: anchorId,
		title: pageName,
		slug: props.href,
		type: LINK_TYPE.ANCHORED_SECTION,
	};
};

const getInitialPage = (): Link => {
	const page = sitePages.value[props.pageId];

	if (!page) {
		return INITIAL_LINK;
	}

	return {
		title: page.type === PAGE_TYPE_BLOG ? page.meta?.title : page.name,
		value: props.pageId,
		type: LINK_TYPE.PAGE,
		slug: page.slug,
	};
};

const getInitialLink = (): Link => {
	if (props.type === LINK_TYPE.PAGE) {
		return getInitialPage();
	}

	if (!props.href) {
		return INITIAL_LINK;
	}

	if (props.type === LINK_TYPE.ANCHORED_SECTION) {
		return getInitialSectionAnchor();
	}

	return {
		value: props.href,
		type: props.type,
	};
};

const currentLink = ref<Link>(getInitialLink());

const isInputBlurred = ref(false);

const isCustomLinkURLValid = ref(true);

const elementId = generateRandomId();

const dropdownValues = computed<LinkTypeDropdownItem[]>(() => [
	{
		title: t('builder.linkSettingsPage'),
		icon: 'draft',
		type: LINK_TYPE.PAGE,
	},
	{
		title: t('builder.linkSettingsCustomLink'),
		icon: 'link',
		type: LINK_TYPE.EXTERNAL,
	},
	{
		title: t('builder.linkSettingsSection'),
		icon: 'calendar_view_day',
		type: LINK_TYPE.ANCHORED_SECTION,
	},
	{
		title: t('builder.linkSettingsEmailAddress'),
		icon: 'mail',
		type: LINK_TYPE.EMAIL,
	},
	{
		title: t('builder.linkSettingsPhoneNumber'),
		icon: 'call',
		type: LINK_TYPE.PHONE,
	},
	...(isAiBuilderMode.value ? []
		: [
			{
				title: t('builder.linkSettingsFileDownload'),
				icon: 'description',
				type: LINK_TYPE.DOWNLOAD,
			},
		]),
]);

const getInitialSelectedType = (): LinkType => (props.href ? props.type : LINK_TYPE.PAGE);

const selectedLinkType = ref<LinkType | null>(getInitialSelectedType());
const selectedLinkTypeFromDropdown = ref<LinkType>(LINK_TYPE.PAGE);

const selectedLinkTypeItem = computed(
	() => dropdownValues.value.find((option) => selectedLinkType.value === option.type) as LinkTypeDropdownItem,
);

const textInputPlaceholders = computed<Partial<Record<LinkType, string>>>(() => ({
	email: t('builder.editButton.linkSettings.emailPlaceholder'),
	external: 'https://www.example.com',
	phone: t('builder.editButton.linkSettings.phonePlaceholder'),
}));

const anchoredGlobalFooter = computed(() => Object.values(siteBlocks.value).find(({
	htmlId,
	slot,
}) => !!htmlId && slot === 'footer'));

const isHomepage = (pageId: string) => pageId === getters.homePageId;

const anchoredSections = computed(() => [
	...Object.entries(siteBlocks.value)
		.filter(([blockId, blockData]) => {
			const shouldIncludePrivatePageBlocks = isCurrentPagePrivate.value
				? privatePage.value.blocks?.includes(blockId)
				: !privatePage.value.blocks?.includes(blockId);

			return !!blockData.htmlId && blockData.slot !== 'footer' && shouldIncludePrivatePageBlocks;
		})
		.map(([blockId, { htmlId }]) => {
			const [pageId, pageData] = Object.entries(sitePages.value)
				.find(([, { blocks }]) => (blocks || []).includes(blockId)) as [string, SitePage];

			const slug = isHomepage(pageId) ? `/#${htmlId}` : `/${pageData.slug}#${htmlId}`;

			return {
				value: htmlId,
				title: pageData.name,
				slug: `${localePrefix.value}${slug}`,
				type: LINK_TYPE.ANCHORED_SECTION,
			};
		}),
	// Handles global anchored footers
	// Buttons that link to footer will link to the current page footer
	...(anchoredGlobalFooter.value ? [
		{
			value: anchoredGlobalFooter.value.htmlId,
			title: getters.currentPage.name,
			slug: isHomepage(state.currentPageId)
				? `${localePrefix.value}/#${anchoredGlobalFooter.value.htmlId}`
				: `${localePrefix.value}/${getters.currentPage.slug}#${anchoredGlobalFooter.value.htmlId}`,
			type: LINK_TYPE.ANCHORED_SECTION,
		},
	] : []),
]);

const anchoredSectionDropdownPlaceholder = ref(anchoredSections.value.length
	? t('builder.editButton.linkSettings.anchoredSectionPlaceholder')
	: t('builder.editButton.linkSettings.noNamedSections'));

const isTypeSelected = (type: LinkType) => selectedLinkType.value === type;

const isTextInputVisible = computed(() => isTypeSelected(LINK_TYPE.EXTERNAL)
|| isTypeSelected(LINK_TYPE.EMAIL)
|| isTypeSelected(LINK_TYPE.PHONE));

const isNewTabToggleVisible = computed(() => isTypeSelected(LINK_TYPE.PAGE)
|| isTypeSelected(LINK_TYPE.EXTERNAL)
|| isTypeSelected(LINK_TYPE.ANCHORED_SECTION));

const isNoLinkToggleVisible = computed(() => isTypeSelected(LINK_TYPE.EXTERNAL) || isTypeSelected(LINK_TYPE.ANCHORED_SECTION));

const internalPages = computed(() => Object
	.entries(sitePages.value)
	.filter(([, page]) => page.type !== PAGE_TYPE_PRIVATE)
	.map(([pageId, page]) => {
		if (page.type === PAGE_TYPE_ECOMMERCE_PRODUCT && siteMeta.value.ecommerceStoreId) {
			const product = ecommerceStore.products.find((data: EcommerceProduct) => data.id === page.productId);

			return {
				title: page.name,
				value: pageId,
				type: LINK_TYPE.PAGE,
				slug: product?.seo_settings?.slug,
			};
		}

		return {
			title: page.type === PAGE_TYPE_BLOG ? page.meta?.title : page.name,
			value: pageId,
			type: LINK_TYPE.PAGE,
			slug: page.slug,
		};
	}));

const handleHrefUpdate = ({
	newValue,
	isValid = true,
}: { newValue?: string, isValid?: boolean}) => {
	isCustomLinkURLValid.value = isValid;

	if (isValid) {
		emit('update:href', newValue || '');
	}
};

// Appends tel:, mailto: or https://, depending on the validation.
// TODO: instead of force-changing href, add proper validation
const addLinkPrefix = (link: Link) => {
	if (!link.value) return;

	if (link.type === LINK_TYPE.EXTERNAL) {
		const doesStartWithAllowedPrefix = ALLOWED_PREFIXES.some((prefix) => link.value.startsWith(prefix));
		const url = doesStartWithAllowedPrefix ? link.value : `${DEFAULT_PREFIX}${link.value}`;

		const webAddressValidationResult = getValidUrl(url);

		if (!webAddressValidationResult.isUrlValid) {
			handleHrefUpdate({
				isValid: false,
			});

			return;
		}

		handleHrefUpdate({
			newValue: webAddressValidationResult.url,
		});

		return;
	}

	if (link.type === LINK_TYPE.EMAIL) {
		const emailValidationResult = getValidEmail(link.value);

		if (!emailValidationResult.isUrlValid) {
			handleHrefUpdate({
				isValid: false,
			});

			return;
		}

		handleHrefUpdate({
			newValue: emailValidationResult.url,
		});

		return;
	}

	if (link.type === LINK_TYPE.PHONE) {
		const phoneValidationResult = getValidPhone(link.value);

		if (!phoneValidationResult.isUrlValid) {
			handleHrefUpdate({
				isValid: false,
			});

			return;
		}

		handleHrefUpdate({
			newValue: phoneValidationResult.url,
		});

		return;
	}

	if (link.type === LINK_TYPE.PAGE) {
		handleHrefUpdate({
			newValue: `${localePrefix.value}/${link.slug}`,
		});

		emit('update:pageId', link.value);
	}

	if (link.type === LINK_TYPE.ANCHORED_SECTION) {
		handleHrefUpdate({
			newValue: link.slug,
		});

		emit('update:pageId', link.value);
	}
};

const handleLinkChange = (link: Link) => {
	const isLinkStringType = typeof link === 'string';

	if (isLinkStringType) {
		currentLink.value = {
			value: link,
			type: selectedLinkType.value as LinkType,
		};

		return;
	}

	currentLink.value = link;
};

const handleInputBlur = () => {
	isInputBlurred.value = true;
	addLinkPrefix(currentLink.value);
};

watch(() => currentLink.value, (newValue) => addLinkPrefix(newValue), {
	deep: true,
});

watch(() => props.type, (newValue) => {
	selectedLinkType.value = newValue;
}, {
	immediate: true,
});

const handleLinkTypeBlur = () => {
	selectedLinkType.value = selectedLinkTypeFromDropdown.value;
};

const handleLinkTypeSelect = (option: LinkTypeDropdownItem) => {
	if (!option) {
		return;
	}

	selectedLinkType.value = option.type;
	selectedLinkTypeFromDropdown.value = option.type;
	currentLink.value = INITIAL_LINK;
	isInputBlurred.value = false;
	isCustomLinkURLValid.value = true;

	emit('update:type', option.type);

	// clear all the attributes to prevent falsely setting stuff to data.json
	emit('update:href', '');
	emit('update:pageId', '');
	emit('update:rel', '');
	emit('update:target', '_self');

	if (option.type === LINK_TYPE.EXTERNAL) {
		emit('update:rel', 'nofollow');
		emit('update:target', '_blank');
	}
};

const toggleOpenNewTab = () => {
	emit('update:target', props.target === '_blank' ? '_self' : '_blank');
};

const toggleRelNoFollow = () => {
	emit('update:rel', props.rel === 'nofollow' ? '' : 'nofollow');
};

</script>

<style scoped lang="scss">
:deep(.link-settings__anchored-section-datalist) {
	.vs__search {
		z-index: 0;
	}
}

.link-settings {
	&__selected-type {
		display: flex;
		align-items: center;
		gap: 8px;
	}

	&__label {
		margin-bottom: 8px;
	}

	&__toggle {
		padding-top: 8px;
		padding-bottom: 8px;

		&:nth-last-child(1) {
			padding-bottom: 16px;
		}
	}

	&__input-dropdown {
		margin-bottom: 8px;

		:deep(.vs__dropdown-menu) {
			max-height: 256px;

			.link-type-option {
				display: flex;
				align-items: center;
				justify-content: space-between;

				&__left {
					display: flex;
					align-items: center;
					gap: 8px;
				}
			}
		}
	}

	&__dropdown-prefix-svg {
		margin-right: 10px;
	}

	&__anchored-section-datalist {
		margin-bottom: 8px;

		.anchored-section__search-value,
		.anchored-section__option-page-name {
			font-size: 14px;
			line-height: 1.43;
			letter-spacing: 0.25px;
			color: $color-gray;
		}

		.anchored-section__search-value {
			width: 255px;
			overflow: hidden;
			text-overflow: ellipsis;

			.anchor-id {
				white-space: nowrap;
				color: $color-dark;
			}
		}
	}

	&__anchored-section-hint {
		font-size: 12px;
		line-height: 1.33;
		color: $color-gray;
	}
}
</style>
