// eslint-disable-next-line import/no-cycle
import { createStoreWithProducts } from '@/api/EcommerceApi';
import { PINIA_STORES } from '@/constants/stores';
import { useSiteStore } from '@/stores/siteStore';
import { updateTemplateWithAiProductsData } from '@/utils/aiTemplateEditingHelpers';
import { mergeObjects } from '@/utils/mergeObjects';
import {
	SiteEcommerceSortingValue,
	SiteData,
	SitePages,
} from '@hostinger/builder-schema-validator';
import { EcommerceProduct } from '@zyro-inc/site-modules/types';
import { removeNullishEntries } from '@zyro-inc/site-modules/utils/object';
import { defineStore } from 'pinia';
import { captureException } from '@sentry/vue';
import {
	computed,
	ref,
} from 'vue';
import { useStore } from 'vuex';
import { META_ECOMMERCE_TYPE } from '@zyro-inc/site-modules/constants';
import {
	removePage,
	removeBlock,
	removeElement,
} from '@/utils/siteDataUtils';
import { BLOCKS_ECOMMERCE } from '@zyro-inc/site-modules/constants/ecommerce';
import {
	getFilteredProductsByCategoryId,
	getSortedProductsKey,
} from '@/utils/ecommerce';
import { getStoreProducts } from '@/api/StoreApi';
import { getStoreId } from '@zyro-inc/site-modules/utils/getters/getStoreId';
import { productsPerPageByColumnCount } from '@zyro-inc/site-modules/components/blocks/ecommerce/utils';
import {
	getIsExperimentActive,
	FEATURE_FLAG_IDS,
} from '@/utils/experiments';
import { ECOMMERCE_DYNAMIC_PAGE_ENABLE_TIMESTAMP } from '@/constants';
import { patcher } from '@/utils/jsondiffpatch';

export const useEcommerceStore = defineStore(PINIA_STORES.ECOMMERCE, () => {
	const {
		state,
		getters,
		dispatch,
	} = useStore();
	const siteStore = useSiteStore();

	const isLoadingProducts = ref<Record<string, boolean>>({
		global: false,
	});
	const productsSorted = ref<Record<SiteEcommerceSortingValue, EcommerceProduct[]>>(
		{} as Record<SiteEcommerceSortingValue, EcommerceProduct[]>,
	);
	const countSorted = ref<Record<SiteEcommerceSortingValue, number>>({} as Record<SiteEcommerceSortingValue, number>);
	const productsNonSorted = ref<EcommerceProduct[]>([]);
	const countNonSorted = ref(0);
	const hiddenProducts = ref<EcommerceProduct[]>([]);
	const isEditingGlobalPage = ref(false);
	const currentDynamicPageProductId = ref('');

	const storeId = computed(() => getStoreId(getters.siteMeta));
	const defaultLocale = computed(() => getters.defaultLocale);

	const isDynamicPageFlowEnabled = computed(() => {
		const isFeatureFlagEnabled = getIsExperimentActive(FEATURE_FLAG_IDS.ECOMMERCE_DYNAMIC_PAGES_FLOW);

		const siteCreationTimestamp = Math.floor(new Date(state.createdAt).getTime() / 1000);
		const isFlowEnabledByTimestamp = siteCreationTimestamp > ECOMMERCE_DYNAMIC_PAGE_ENABLE_TIMESTAMP;

		return (isFeatureFlagEnabled && isFlowEnabledByTimestamp) || Object.keys(getters.ecommerceDynamicProductPageTemplates).length;
	});

	/**
	 *  This function creates new store with new storeId and products.
	 *  Takes in AI generated product data, removes fake ids so proper ones can be assigned, sends cleaned up data to the backend
	 * for store creation. After store is created website data is updated with new storeId and products.
	 * @newProducts - AI generated products that are sent from site or template generation flow
	 */
	const createEcommerceStoreWithAiProducts = async ({ newProducts }:{newProducts: EcommerceProduct[]}) => {
		const cleanedUpProductsData = newProducts.map((product: EcommerceProduct) => {
			const updatedProductData = mergeObjects(product, {
				id: null,
				options: null,
				pageId: null,
				variants: [
					{
						...product.variants[0],
						prices: [
							{
								...product.variants[0].prices[0],
								currency_code: product.variants[0].prices[0].currency.code,
							},
						],
					},
				],
			});

			delete updatedProductData.variants[0].prices[0].currency;
			delete updatedProductData.variants[0].options;

			return removeNullishEntries(updatedProductData);
		});

		const {
			storeId: newStoreId,
			products,
		} = await createStoreWithProducts({
			siteId: siteStore.websiteId,
			products: cleanedUpProductsData,
		});

		const websiteDataWithUpdatedStoreInfo = updateTemplateWithAiProductsData({
			templateData: siteStore.site,
			storeId: newStoreId,
			dummyProductData: newProducts,
			storeProductData: products,
		}) as SiteData;

		siteStore.setSiteData(websiteDataWithUpdatedStoreInfo);
	};

	const setCurrentDynamicPageProductId = (productId: string) => {
		currentDynamicPageProductId.value = productId;
	};

	const setNonSortedProducts = (products: EcommerceProduct[]) => {
		productsNonSorted.value = [
			...productsNonSorted.value,
			...products,
		].filter((product, index, self) => self.findIndex((p) => p.id === product.id) === index);
	};

	const setStoreProducts = ({
		products,
		sort,
		collectionId,
		count,
	}: {
		products: EcommerceProduct[],
		sort?: SiteEcommerceSortingValue,
		collectionId?: string,
		count?: number,
	}) => {
		setNonSortedProducts(products);

		if (collectionId || sort) {
			const sortedProductsKey = getSortedProductsKey(sort, collectionId);

			productsSorted.value[sortedProductsKey] = products;
			countSorted.value[sortedProductsKey] = count || products.length;
		} else {
			countNonSorted.value = count || products.length;
		}
	};

	const fetchProductById = async (productId: string, blockId: string): Promise<EcommerceProduct | null> => {
		if (!storeId.value) {
			return null;
		}

		isLoadingProducts.value[blockId] = true;

		try {
			const productData = await getStoreProducts(storeId.value, {
				productId,
			});

			setNonSortedProducts(productData.products);

			return productData.products[0];
		} catch (error) {
			dispatch('notifications/notify', {
				message: `Error while getting store product: ${productId}.`,
			});

			captureException(error);

			return null;
		} finally {
			isLoadingProducts.value[blockId] = false;
		}
	};

	const fetchProducts = async ({
		limit,
		resetUndoForPageCreation = true,
		pickStylesFromTheme = false,
		shouldAwaitPageCreation = false,
	}: {
		limit?: number,
		resetUndoForPageCreation?: boolean,
		pickStylesFromTheme?: boolean,
		shouldAwaitPageCreation?: boolean,
	} = {}) => {
		if (!storeId.value) {
			return;
		}

		isLoadingProducts.value.global = true;

		try {
			if (isDynamicPageFlowEnabled.value) {
				const productData = await getStoreProducts(storeId.value, {
					limit: limit || 1,
				});

				setStoreProducts({
					products: productData.products,
					count: productData.count,
				});

				dispatch('addEcommerceProductPages', {
					pickStylesFromTheme,
					resetUndo: resetUndoForPageCreation,
				});
			} else {
				const productData = await getStoreProducts(storeId.value);

				setStoreProducts({
					products: productData.products,
					count: productData.count,
				});

				Object.keys(getters.siteBlocks).forEach((key) => {
					const block = getters.siteBlocks[key];

					if (block.type !== 'BlockEcommerceProductList') {
						return;
					}

					const filteredProducts: EcommerceProduct[] = !block.productCategoryId
						? productsNonSorted.value
						: getFilteredProductsByCategoryId(productsNonSorted.value, block.productCategoryId);

					dispatch('updateBlockData', {
						blockId: key,
						blockData: {
							...block,
							productIds: filteredProducts.map((product) => product.id),
							productsPerPage: block.productsPerPage || productsPerPageByColumnCount[2],
						},
					});
				});
				if (!getters['ecommerce/isStoreTypeZyro']) {
					return;
				}

				if (shouldAwaitPageCreation) {
					await dispatch('addEcommerceProductPages', {
						pickStylesFromTheme,
						resetUndo: resetUndoForPageCreation,
					});
				} else {
					dispatch('addEcommerceProductPages', {
						pickStylesFromTheme,
						resetUndo: resetUndoForPageCreation,
					});
				}
			}
		} catch (error) {
			dispatch('notifications/notify', {
				message: 'Error while getting store products.',
			});

			captureException(error);
		} finally {
			isLoadingProducts.value.global = false;
		}
	};

	const fetchListProducts = async ({
		sortType,
		collectionId,
		blockId,
	}: {
		sortType: SiteEcommerceSortingValue,
		collectionId: string,
		blockId: string,
	}) => {
		isLoadingProducts.value[blockId] = true;

		try {
			const sort = (!sortType && collectionId) ? 'order=ASC&sort_by=collection_order' as SiteEcommerceSortingValue : sortType;
			const productData = await getStoreProducts(storeId.value, {
				sort,
				collectionId,
			});

			setStoreProducts({
				products: productData.products,
				sort,
				collectionId,
				count: productData.count,
			});

			Object.keys(getters.siteBlocks).forEach((key) => {
				const block = getters.siteBlocks[key];

				if (block.type !== 'BlockEcommerceProductList') {
					return;
				}

				const filteredProducts: EcommerceProduct[] = !block.productCategoryId
					? productsNonSorted.value
					: getFilteredProductsByCategoryId(productsNonSorted.value, block.productCategoryId);

				dispatch('updateBlockData', {
					blockId: key,
					blockData: {
						...block,
						productIds: filteredProducts.map((product) => product.id),
						productsPerPage: block.productsPerPage || productsPerPageByColumnCount[2],
					},
				});
			});
		} catch (error) {
			dispatch('notifications/notify', {
				message: 'Error while getting store products.',
			});

			captureException(error);
		} finally {
			isLoadingProducts.value[blockId] = false;
		}
	};

	const getProductsSorted = (sort: SiteEcommerceSortingValue, collectionId: string) => {
		const sortValue = (!sort && collectionId) ? 'order=ASC&sort_by=collection_order' : sort;

		return productsSorted.value[getSortedProductsKey(sortValue, collectionId)] || [];
	};

	const getCountSorted = (sort: SiteEcommerceSortingValue, collectionId: string) => {
		const sortValue = (!sort && collectionId) ? 'order=ASC&sort_by=collection_order' : sort;

		return countSorted.value[getSortedProductsKey(sortValue, collectionId)] || 0;
	};

	const setHiddenProducts = (products: EcommerceProduct[]) => {
		hiddenProducts.value = products;
	};

	const deleteEcommerceItemsFromSite = (siteData: SiteData) => {
		let siteDataClone = patcher.clone(siteData) as SiteData;

		// get ecommerce block and element info from the remaining pages
		Object.entries(siteDataClone.languages).forEach(([
			locale,
			{
				blocks,
				elements,
			},
		]) => {
			const languageEcommerceBlockIds = Object.keys(blocks).filter(
				(key) => BLOCKS_ECOMMERCE.includes(blocks[key]?.type),
			);
			const languageEcommerceElementIds = Object.keys(elements).filter(
				(key) => elements[key]?.type === 'GridEcommerceButton',
			);

			languageEcommerceBlockIds.forEach((blockId) => {
				siteDataClone = removeBlock({
					siteData: siteDataClone,
					blockId,
					locale,
				}) as SiteData;
			});

			languageEcommerceElementIds.forEach((elementId) => {
				siteDataClone = removeElement({
					siteData: siteDataClone,
					elementId,
					locale,
				}) as SiteData;
			});
		});

		delete siteDataClone.meta[META_ECOMMERCE_TYPE];

		// remove ecommerce shopping cart
		const {
			ecommerceShoppingCart,
			...restData
		} = siteDataClone;

		return restData;
	};

	const deleteLegacyEcommercePagesFromSite = () => {
		let siteDataClone = patcher.clone(siteStore.site) as SiteData;

		dispatch('unselectCurrentElement');
		dispatch('updateCurrentBlockId', null);

		// then delete all the product pages together with their product blocks from default lang
		const productPageKeys = Object.keys(getters.ecommerceProductPages);

		if (productPageKeys.length) {
			productPageKeys.forEach((pageId) => {
				siteDataClone = removePage({
					siteData: siteDataClone,
					pageId,
					locale: defaultLocale.value,
				}) as SiteData;
			});
		}

		const siteDataWithDeletedItems = deleteEcommerceItemsFromSite(siteDataClone);

		dispatch('overwriteWebsiteData', {
			websiteData: {
				...siteDataWithDeletedItems,
			},
		});
		dispatch('undoRedo/resetUndoRedo', null);
		dispatch('saving/saveWebsite', {});
	};

	const deleteDynamicEcommercePageFromSite = () => {
		let siteDataClone = patcher.clone(siteStore.site) as SiteData;

		dispatch('unselectCurrentElement');
		dispatch('updateCurrentBlockId', null);
		dispatch('updateCurrentPageId');

		const dynamicProductPageTemplateKeys = Object.keys(getters.ecommerceDynamicProductPageTemplates);

		if (dynamicProductPageTemplateKeys.length) {
			dynamicProductPageTemplateKeys.forEach((pageId) => {
				siteDataClone = removePage({
					siteData: siteDataClone,
					pageId,
					locale: defaultLocale.value,
				}) as SiteData;
			});
		}

		const siteDataWithDeletedItems = deleteEcommerceItemsFromSite(siteDataClone);

		dispatch('overwriteWebsiteData', {
			websiteData: {
				...siteDataWithDeletedItems,
			},
		});
		dispatch('saving/saveWebsite', {});
	};

	const deleteEcommerceFromSite = () => {
		if (Object.keys(getters.ecommerceDynamicProductPageTemplates).length) {
			deleteDynamicEcommercePageFromSite();

			return;
		}

		deleteLegacyEcommercePagesFromSite();
	};

	const getIsBlockInEcommerceProductPage = (blockId: string) => Object.values(
		getters.ecommerceProductPages as SitePages,
	).some(({ blocks }) => blocks?.includes(blockId));

	const resetProductsList = () => {
		productsNonSorted.value = [];
		productsSorted.value = {} as Record<SiteEcommerceSortingValue, EcommerceProduct[]>;
		countNonSorted.value = 0;
		countSorted.value = {} as Record<SiteEcommerceSortingValue, number>;
	};

	return {
		createEcommerceStoreWithAiProducts,
		isEditingGlobalPage,
		deleteEcommerceFromSite,
		currentDynamicPageProductId,
		setCurrentDynamicPageProductId,
		getProductsSorted,
		getCountSorted,
		products: productsNonSorted,
		count: countNonSorted,
		productsSorted,
		countSorted,
		hiddenProducts,
		fetchProducts,
		fetchListProducts,
		isLoadingProducts,
		setStoreProducts,
		fetchProductById,
		setHiddenProducts,
		isDynamicPageFlowEnabled,
		getIsBlockInEcommerceProductPage,
		resetProductsList,
	};
});
