<script setup lang="ts">
import {
	VNodeRef,
	computed,
	ref,
	watch,
} from 'vue';
import { useStore } from 'vuex';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ControlsTooltip from '@/components/ControlsTooltip.vue';
import BlockEditControls from '@/components/builder-controls/BlockEditControls.vue';
import BlockControls from '@/components/block/BlockControls.vue';
import BlockEcommerceProductList from '@zyro-inc/site-modules/components/blocks/ecommerce/BlockEcommerceProductList.vue';
import EditBlockEcommerceProductListTabs from '@/components/builder-controls/edit-block-ecommerce-product-list/EditBlockTabsEcommerceProductList.vue';
import VerticalSeparator from '@/components/global/VerticalSeparator.vue';

import { useBlockEcommerceProductList } from '@zyro-inc/site-modules/components/blocks/ecommerce/useBlockEcommerceProductList';
import { useSiteEngineAnimations } from '@zyro-inc/site-modules/use/useSiteEngineAnimations';

import { DEMO_PRODUCTS } from '@zyro-inc/site-modules/constants/ecommerce';
import {
	SYSTEM_LOCALE,
	DATA_ATTRIBUTE_ANIMATION_STATE,
} from '@zyro-inc/site-modules/constants';
import {
	ECOMMERCE_REDIRECT_PATHS,
	ECOMMERCE_FUNNEL_LOCATIONS,
} from '@/constants';
import { useBuilderMode } from '@/use/useBuilderMode';
import { EcommerceProduct } from '@zyro-inc/site-modules/types';
import { useEcommerceAdminStore } from '@/stores/ecommerceAdminStore';
import { useEcommerceStore } from '@/stores/ecommerceStore';
import {
	SiteEcommerceSortingValue,
	SitePages,
} from '@hostinger/builder-schema-validator';
import { getShouldSkipProductsFetch } from '@/utils/ecommerce';

const EDIT_POPUP_OPTIONS = {
	placement: 'right-start',
	placementMobile: 'right-start',
	flip: false,
	offset: {
		mainAxis: 8,
		crossAxis: 24,
	},
	offsetMobile: {
		mainAxis: 24,
		crossAxis: 0,
	},
	padding: 8,
	autoUpdate: true,
};

const props = defineProps({
	blockId: {
		type: String,
		required: true,
	},
	data: {
		type: Object,
		default: () => ({}),
	},
	// this prop is used to indicate when addBlockModal preview is open
	areDemoProductsShown: {
		type: Boolean,
		default: false,
	},
	ecommerceTranslations: {
		type: Object,
		default: () => ({}),
	},
	currentLocale: {
		type: String,
		default: SYSTEM_LOCALE,
	},
	isFirstBlock: {
		type: Boolean,
		default: false,
	},
	transparentHeaderHeight: {
		type: Number,
		default: 0,
	},
	isMobileView: {
		type: Boolean,
		default: false,
	},
	demoProductCount: {
		type: Number,
		default: 8,
	},
});

const {
	state,
	getters,
	dispatch,
} = useStore();
const { isAiBuilderMode } = useBuilderMode();
const ecommerceStore = useEcommerceStore();
const ecommerceAdminStore = useEcommerceAdminStore();
const {
	customAnimationClass,
	animationAttributeStateValue,
} = useSiteEngineAnimations({
	data: props.data,
	elementId: undefined,
	elementData: undefined,
});
const {
	blockStyle,
	textColorVars,
	columnCount,
	productsPerPage,
	productCategoryId,
	isButtonEnabled,
	buttonDisplay,
	buttonText,
	buttonStyle,
	buttonType,
	buttonBorderWidth,
	ribbonStyle,
	imageRatio,
	imageHoverEffect,
	productSorting,
	backgroundColor,
	isCategoryListEnabled,
	productIds,
	isFullWidth,
	isTotalProductCountShown,
	columnGap,
	rowGap,
	isListCentered,
	isButtonFullWidth,
} = useBlockEcommerceProductList(props);

const ecommerceProductListRef = ref<VNodeRef | null>(null);
const websiteId = ref(state.websiteId);
const sorting = ref(props.data.productSorting?.enabled
	? props.data.productSorting?.sortingOptions?.find(({ isEnabled }: { isEnabled: boolean }) => isEnabled)?.value || ''
	: '');
const currentPage = ref(1);
const currentCategoryId = ref(productCategoryId.value);

const sortingOptions = computed(() => props.data.productSorting?.sortingOptions || []);
const legacyProductPages = computed(() => getters.ecommerceLegacyProductPages);
const defaultLanguageSitePages = computed<SitePages>(() => getters.defaultLanguageSitePages);
const variantsQuantity = computed(() => state.ecommerce.variantsQuantity);
const categories = computed(() => getters['ecommerce/categories']);
const isCartVisible = computed(() => getters['ecommerce/isCartVisible']);
const allProducts = computed(() => ((sorting.value || currentCategoryId.value)
	? ecommerceStore.getProductsSorted(sorting.value, currentCategoryId.value)
	: ecommerceStore.products));
const totalProductCount = computed(() => ((sorting.value || currentCategoryId.value)
	? ecommerceStore.getCountSorted(sorting.value, currentCategoryId.value)
	: ecommerceStore.count));
const products = computed(() => (props.areDemoProductsShown ? DEMO_PRODUCTS.slice(0, props.demoProductCount) : allProducts.value));
const pageCount = computed(() => Math.ceil(products.value.length / productsPerPage.value));
const productsList = computed(() => products.value.slice(
	(currentPage.value - 1) * productsPerPage.value,
	productsPerPage.value * currentPage.value,
) as EcommerceProduct[]);
const isLoadingProducts = computed(() => ecommerceStore.isLoadingProducts[props.blockId]);
const isLoadingCategories = computed(() => state.ecommerce.isLoadingCategories);
const isLoading = computed(() => isLoadingProducts.value || isLoadingCategories.value);
const isCategoriesLoaded = computed(() => !isLoadingCategories.value);

const isProductListShown = computed(() => {
	if (props.areDemoProductsShown) {
		return true;
	}

	return !!products.value?.length;
});

const openProductPage = (product: EcommerceProduct) => {
	const { defaultLocale } = getters;
	const { currentLocale } = state;

	if (ecommerceStore.isDynamicPageFlowEnabled) {
		ecommerceStore.setCurrentDynamicPageProductId(product.id as string);

		const [dynamicProductPageId] = Object.keys(getters.ecommerceDynamicProductPageTemplates);

		if (currentLocale !== defaultLocale) {
			dispatch('updateCurrentLocale', defaultLocale);
		}

		dispatch('updateCurrentPageId', dynamicProductPageId);

		return;
	}

	const productPageId = Object.keys(defaultLanguageSitePages.value).find(
		(pageId) => defaultLanguageSitePages.value[pageId].productId === product.id,
	);

	if (currentLocale !== defaultLocale) {
		dispatch('updateCurrentLocale', defaultLocale);
	}

	dispatch('updateCurrentPageId', productPageId);
};

const handleEcommerceManageButtonClick = () => {
	ecommerceAdminStore.setIsEcommerceAdminIframeOpen({
		isOpen: true,
		path: ECOMMERCE_REDIRECT_PATHS.PRODUCTS,
		location: ECOMMERCE_FUNNEL_LOCATIONS.IFRAME_MANAGE_PRODUCTS,
	});
};

const closeBlockEditPopupHandler = () => {
	dispatch('leaveBlockEditMode');
};

const handlePageChange = (page: number) => {
	currentPage.value = page;
};

const fetchProducts = ({
	sortType,
	collectionId,
	blockId,
}: {sortType: SiteEcommerceSortingValue, collectionId: string, blockId: string}) => {
	const shouldSkipFetch = getShouldSkipProductsFetch({
		sortType,
		collectionId,
		sortedProductsFromStore: ecommerceStore.getProductsSorted(sortType, collectionId),
		allProducts: ecommerceStore.products,
		totalProductCount: totalProductCount.value,
	});

	if (shouldSkipFetch) {
		return;
	}

	ecommerceStore.fetchListProducts({
		sortType,
		blockId,
		collectionId,
	});
};

const handleSortChange = async (event: Event) => {
	const sortType = (event.target as HTMLInputElement)?.value as SiteEcommerceSortingValue;

	sorting.value = sortType;

	fetchProducts({
		sortType,
		blockId: props.blockId,
		collectionId: currentCategoryId.value,
	});
};

const handleCategoryClick = (id: string) => {
	currentPage.value = 1;
	currentCategoryId.value = id;

	fetchProducts({
		sortType: sorting.value,
		blockId: props.blockId,
		collectionId: id,
	});
};

watch(productCategoryId, (newProductCategoryId) => {
	if (props.areDemoProductsShown) {
		return;
	}

	currentCategoryId.value = newProductCategoryId;

	fetchProducts({
		sortType: sorting.value,
		collectionId: newProductCategoryId,
		blockId: props.blockId,
	});
}, {
	immediate: true,
});

watch(sortingOptions, (newSortingOptions?: { value: SiteEcommerceSortingValue; isEnabled: boolean }[]) => {
	const isSortingOptionAvailable = newSortingOptions?.find(({ value }) => value === sorting.value)?.isEnabled;

	if (!isSortingOptionAvailable) {
		const nextSortingOption = newSortingOptions?.find(({ isEnabled }) => isEnabled);

		if (nextSortingOption) {
			sorting.value = nextSortingOption.value;
			fetchProducts({
				sortType: nextSortingOption.value,
				collectionId: currentCategoryId.value,
				blockId: props.blockId,
			});
		} else {
			sorting.value = '';
		}
	}
});

watch(isCategoryListEnabled, (value) => {
	if (!value) {
		handleCategoryClick('');
	}
});
</script>

<template>
	<BlockEcommerceProductList
		ref="ecommerceProductListRef"
		v-qa="'builder-section-zyroecommerceproductlist'"
		:block-id="blockId"
		:is-mobile-view="isMobileView"
		:page-count="pageCount"
		:current-page="currentPage"
		:products="productsList"
		:block-style="blockStyle"
		:text-color-vars="textColorVars"
		:is-product-list-shown="isProductListShown"
		:column-count="columnCount"
		:product-pages="legacyProductPages"
		:product-category-id="currentCategoryId"
		:is-button-enabled="isButtonEnabled"
		:button-display="buttonDisplay"
		:button-text="buttonText"
		:button-style="buttonStyle"
		:button-type="buttonType"
		:button-border-width="buttonBorderWidth"
		:is-loading="isLoading"
		:is-categories-loaded="isCategoriesLoaded"
		:ribbon-style="ribbonStyle"
		is-product-list-item-link-disabled
		is-category-item-link-disabled
		:translations="ecommerceTranslations"
		:site-id="websiteId"
		:variants-quantity="variantsQuantity"
		:image-ratio="imageRatio"
		:image-hover-effect="imageHoverEffect"
		:product-sorting="productSorting"
		:sorting="sorting"
		:categories="categories"
		:product-ids="productIds"
		:is-category-list-enabled="isCategoryListEnabled"
		:background-color="backgroundColor"
		:is-full-width="isFullWidth"
		:is-total-product-count-shown="isTotalProductCountShown"
		:is-list-centered="isListCentered"
		:column-gap="columnGap"
		:row-gap="rowGap"
		:is-button-full-width="isButtonFullWidth"
		:total-product-count="totalProductCount"
		class="block-ecommerce-product-list"
		:class="customAnimationClass"
		:is-cart-visible="isCartVisible"
		:[DATA_ATTRIBUTE_ANIMATION_STATE]="animationAttributeStateValue"
		@sort-changed="handleSortChange"
		@page-changed="handlePageChange"
		@product-click="openProductPage"
		@category-click="handleCategoryClick"
	/>
	<BlockControls
		:block-id="blockId"
		:target-ref="ecommerceProductListRef?.$el"
		:is-first-block="isFirstBlock"
		:transparent-header-height="transparentHeaderHeight"
	/>

	<BlockEditControls
		:block-id="blockId"
		:target-ref="ecommerceProductListRef?.$el"
		is-duplicate-button-visible
		is-delete-button-visible
		is-reordering-button-visible
		is-visibility-button-visible
		:is-stretch-to-full-width-button-visible="!isMobileView"
		:edit-popup-options="EDIT_POPUP_OPTIONS"
		:enter-edit-mode-button-title="$t('builder.editBlockButton.editSection')"
	>
		<template #additional-edit-buttons>
			<template v-if="!isAiBuilderMode">
				<VerticalSeparator />
				<ControlsTooltip>
					<HostingerButton
						v-qa="'builder-section-btn-manageproducts'"
						button-type="plain"
						theme="highlight"
						@click="handleEcommerceManageButtonClick"
					>
						<span class="text-body-2">
							{{ $t('builder.ecommerceManageProducts') }}
						</span>
					</HostingerButton>
				</ControlsTooltip>
			</template>
		</template>

		<template #edit-mode-popup>
			<EditBlockEcommerceProductListTabs
				:block-id="blockId"
				@close="closeBlockEditPopupHandler"
			/>
		</template>
	</BlockEditControls>
</template>
