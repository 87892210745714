<template>
	<div>
		<HostingerButton
			ref="ecommerceSettingsButtonRef"
			v-qa="'manage-ecommerce-drawer-btn-settings'"
			button-type="plain"
			:title="$t('builder.blog.blogDrawer.openModal')"
			@click="isPopupOpen = !isPopupOpen"
		>
			<template #icon>
				<Icon name="more_vert" />
			</template>
		</HostingerButton>

		<Popup
			v-if="isPopupOpen"
			:target-ref="ecommerceSettingsButtonRef.$el"
			placement="right"
			@click-outside="isPopupOpen = false"
		>
			<div class="popup-content">
				<HostingerButton
					v-qa="'manage-ecommerce-popup-button-removestore'"
					button-type="plain"
					class="popup-content__button"
					:title="$t('builder.manageEcommerceRemoveStore')"
					text-class="text-body-2"
					@click="openRemoveStoreModal"
				>
					<template #icon-left>
						<Icon
							name="delete"
							dimensions="20px"
						/>
					</template>
					{{ $t('builder.manageEcommerceRemoveStore') }}
				</HostingerButton>
			</div>
		</Popup>
		<Teleport
			v-if="isDeleteModalShown"
			to="body"
		>
			<SystemDialogModal
				v-qa="'blog-post-delete-modal'"
				max-width="420px"
				:title="$t('builder.manageEcommerceRemoveStore')"
				:primary-button-text="$t('common.cancel')"
				:secondary-button-text="$t('common.delete')"
				secondary-button-color="danger"
				@click-primary="isDeleteModalShown = false"
				@click-secondary="handleRemoveStoreClick"
				@close="isDeleteModalShown = false"
			>
				<i18n-t
					class="text-body-2 remove-store-text"
					keypath="builder.deleteOnlineStoreFromSiteModalTextAll"
					tag="p"
				>
					<span class="text-bold-2">
						{{ $t('builder.deleteOnlineStoreFromSiteModalTextAllSlot') }}
					</span>
				</i18n-t>
				<p class="text-body-2">
					{{ $t('builder.deleteOnlineStoreFromSiteModalTextRestore') }}
				</p>
			</SystemDialogModal>
		</Teleport>
	</div>
</template>

<script>
import HostingerButton from '@/components/global/HostingerButton.vue';
import Icon from '@/components/global/Icon.vue';
import Popup from '@/components/global/Popup.vue';
import { useEcommerceStore } from '@/stores/ecommerceStore';

import SystemDialogModal from '@/components/builder-modals/modals/SystemDialogModal.vue';
import {
	mapActionsGui,
	CLOSE_DRAWER,
} from '@/store/builder/gui';

import {
	defineComponent,
	ref,
} from 'vue';
import EventLogApi from '@/api/EventLogApi';

export default defineComponent({
	components: {
		Icon,
		HostingerButton,
		Popup,
		SystemDialogModal,
	},

	setup() {
		const isPopupOpen = ref(false);
		const ecommerceSettingsButtonRef = ref();
		const isDeleteModalShown = ref(false);
		const ecommerceStore = useEcommerceStore();

		return {
			isPopupOpen,
			ecommerceSettingsButtonRef,
			isDeleteModalShown,
			deleteEcommerceFromSite: ecommerceStore.deleteEcommerceFromSite,
		};
	},
	methods: {
		...mapActionsGui({
			closeDrawer: CLOSE_DRAWER,
		}),
		openRemoveStoreModal() {
			this.isDeleteModalShown = true;
			this.isPopupOpen = false;
		},
		handleRemoveStoreClick() {
			EventLogApi.logEvent({
				eventName: 'website_builder.ecomm.remove',
			});

			this.deleteEcommerceFromSite();
			this.closeDrawer();
		},
	},
});
</script>

<style lang="scss" scoped>
.popup-content {
	padding: 8px;
	background: $color-light;
	border-radius: 16px;
	box-shadow: $box-shadow;

	&__button {
		font-weight: 400;
	}
}

.remove-store-text {
	margin-bottom: 16px;
}
</style>
