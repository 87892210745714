<template>
	<div
		:data-portal="DATA_PORTAL_BUILDER_PREVIEW"
		class="preview"
	>
		<main
			v-if="website"
			ref="builderPreviewContainerRef"
			v-qa="'aibuilder-preview-content'"
			class="preview__content"
		>
			<AddStickyBarButton v-if="isAddStickyBarButtonVisible" />
			<BlockStickyBarProviderBuilder
				v-if="siteBlocks[BLOCK_STICKY_BAR_ID] && !isCurrentPagePrivate"
				:data="siteBlocks[BLOCK_STICKY_BAR_ID]"
				:data-block-id="BLOCK_STICKY_BAR_ID"
				:components="siteElements"
				@vue:mounted="(el) => setBlockRef(el, `${BLOCK_STICKY_BAR_ID}Ref`)"
				@vue:unmounted="() => removeBlockRef(`${BLOCK_STICKY_BAR_ID}Ref`)"
				@mousedown="handleBlockSelectOnMouseDown(BLOCK_STICKY_BAR_ID)"
				@mouseover="handleBlockHover({ blockId: BLOCK_STICKY_BAR_ID })"
				@touchstart="handleBlockSelectOnMouseDown(BLOCK_STICKY_BAR_ID)"
			/>
			<BlockHeaderProviderBuilder
				v-if="isHeaderVisible"
				:all-blocks="allCurrentPageBlocks"
				@mousedown="handleBlockSelect('header')"
			/>
			<BuilderEmptyPage v-if="isCurrentPageEmpty && (!pageBlocksSlotFooter || isCurrentPagePrivate)" />
			<div class="blocks">
				<Block
					v-for="(blockId, index) of allCurrentPageBlocks"
					:key="blockId"
					v-qa="`builder-section-${index}`"
					:block-id="blockId"
					:data-block-id="blockId"
					:data="siteBlocks[blockId]"
					:blocks="siteBlocks"
					:components="siteElements"
					:style="index === 0 ? headerHeightStyle : null"
					:is-first-block="(isNavHidden || isCurrentPagePrivate) && index === 0"
					:is-last-block="index === allCurrentPageBlocks.length - 1"
					:transparent-header-height="index === 0 ? transparentBlockSize : 0"
					@vue:mounted="(el) => setBlockRef(el, `${blockId}Ref`)"
					@vue:unmounted="() => removeBlockRef(`${blockId}Ref`)"
					@mousedown="handleBlockSelectOnMouseDown(blockId)"
					@mouseover="handleBlockHover({ blockId })"
					@touchstart="handleBlockSelectOnMouseDown(blockId)"
					@drag-status-change="setDraggedOverBlock({
						blockId,
						isDraggedOver: $event
					})"
				/>
			</div>
		</main>
		<div
			v-if="isMobileScreen"
			:data-portal="DATA_PORTAL_BUILDER_PREVIEW_MOBILE_BOTTOM_BAR"
			class="preview__bottom-bar"
		/>
	</div>
</template>

<script setup>
import { useStore } from 'vuex';

import Block from '@/components/block/Block.vue';
import BlockHeaderProviderBuilder from '@/components/blocks/BlockHeaderProviderBuilder.vue';
import BuilderEmptyPage from '@/components/builder-view/BuilderEmptyPage.vue';
import BlockStickyBarProviderBuilder from '@/components/blocks/BlockStickyBarProviderBuilder.vue';
import AddStickyBarButton from '@/components/builder-controls/control-line/AddStickyBarButton.vue';
import { getHeaderProps } from '@zyro-inc/site-modules/components/blocks/header/getHeaderProps';
import { getPagePathFromId } from '@zyro-inc/site-modules/utils/page/getPagePathFromId';
import { getLanguageSwitcherLanguages } from '@zyro-inc/site-modules/utils/getLanguageSwitcherLanguages';
import {
	BUILDER_CLASS,
	BUILDER_BOTTOM_CLASS,
	BLOCK_STICKY_BAR_ID,
	BLOCK_TYPE_NAVIGATION,
} from '@zyro-inc/site-modules/constants';

import {
	computed,
	onBeforeUnmount,
	onMounted,
	ref,
	watch,
	onBeforeMount,
	nextTick,
} from 'vue';

import { SET_BLOCK_RESIZE_INFO } from '@/store/builder/gui';
import { onClickOutside } from '@/utils/onClickOutside';
import {
	DATA_PORTAL_BUILDER_PREVIEW_MOBILE_BOTTOM_BAR,
	DATA_SELECTOR_BUILDER_PREVIEW,
	DATA_PORTAL_BUILDER_PREVIEW,
} from '@/constants';
import { useIsWindowBeingResized } from '@/use/useIsWindowResizing';
import { useBuilderMode } from '@/use/useBuilderMode';
import { useHoveredBlock } from '@/use/useHoveredBlock';

import {
	CODE,
	getCode,
} from '@zyro-inc/site-modules/utils/getCode';
import { useSiteStore } from '@/stores/siteStore';

const DEFAULT_ACTIVE_BLOCK_OBSERVER_THRESHOLD = 0.9;
const DEFAULT_HEADER_HEIGHT = 130;

const builderPreviewContainerRef = ref(null);
const { isAiBuilderMode } = useBuilderMode();

// #region Header height handling
const {
	getters,
	commit,
	dispatch,
	state,
} = useStore();

const { setIsWindowBeingResized } = useIsWindowBeingResized();
const {
	isHoveredBlockLocked,
	handleBlockHover,
	hoveredBlock,
} = useHoveredBlock();

const blockRefs = ref({});

const setBlockRef = (el, id) => {
	if (el) {
		blockRefs.value = {
			...blockRefs.value,
			[id]: el,
		};
	}
};

const removeBlockRef = async (id) => {
	const {
		[id]: _blockRef,
		...restBlockRefs
	} = blockRefs.value;

	await nextTick();

	blockRefs.value = restBlockRefs;
};

const editBlockPopupRef = ref(null);

// Observer which has a consistent threshold (smaller than viewport)
const currentActiveBlocksShorterThanViewportObserver = ref(null);
// Observers which have different thresholds (to observe sections higher than the viewport)
const currentActiveBlocksTallerThanViewportObservers = ref([]);
const originalPageId = ref(state.currentPageId);

const siteStore = useSiteStore();

const currentPageId = computed(() => state.currentPageId);

const website = computed(() => siteStore.site);
const websiteId = computed(() => state.websiteId);
const currentBlockId = computed(() => state.currentBlockId);
const currentElementId = computed(() => state.currentElementId);
const currentLocale = computed(() => state.currentLocale);
const currentPage = computed(() => getters.currentPage);
const currentPageBlocks = computed(() => currentPage.value?.blocks || []);
const currentBlockType = computed(() => getters.currentBlockType);

const siteBlocks = computed(() => getters.siteBlocks);
const siteElements = computed(() => getters.siteElements);
const sitePages = computed(() => getters.sitePages);

const isElementEditMode = computed(() => state.isElementEditMode);
const isNavHidden = computed(() => getters.isNavHidden);
const isCurrentPagePrivate = computed(() => getters.isCurrentPagePrivate);
const isCurrentPageEmpty = computed(() => getters.isCurrentPageEmpty);
const scheduledBlogPages = computed(() => getters.scheduledBlogPages);
const isEditingTextBoxElement = computed(() => getters.isEditingTextBoxElement);
const isMobileScreen = computed(() => state.gui.isMobileScreen);
const isMobileMode = computed(() => getters['gui/isMobileMode']);
const isHeaderVisible = computed(() => !getters.isNavHidden && !isCurrentPagePrivate.value);
const isBlockEditorOpen = computed(() => state.isBlockEditorOpen);

const pageBlocksSlotFooter = computed(() => Object.keys(siteBlocks.value).find((blockId) => siteBlocks.value[blockId].slot === 'footer'));
const allCurrentPageBlocks = computed(() => [
	...currentPageBlocks.value.filter((blockId) => blockId !== BLOCK_STICKY_BAR_ID),
	...(pageBlocksSlotFooter.value && !isCurrentPagePrivate.value ? [pageBlocksSlotFooter.value] : []),
]);

const isAddStickyBarButtonVisible = computed(() => {
	if (siteBlocks.value[BLOCK_STICKY_BAR_ID] || isCurrentPagePrivate.value) {
		return false;
	}

	return isNavHidden.value
		? allCurrentPageBlocks.value[0] === hoveredBlock.value?.id
		: hoveredBlock.value?.type === BLOCK_TYPE_NAVIGATION;
});

const isFirstPageBlockHidden = computed(() => {
	const firstPageBlock = siteBlocks.value[allCurrentPageBlocks.value[0]];

	if (!firstPageBlock) {
		return false;
	}

	const isDesktopFirstBlockHidden = !isMobileMode.value && firstPageBlock.desktop?.isHidden;
	const isMobileFirstBlockHidden = isMobileMode.value && firstPageBlock.mobile?.isHidden;

	return isDesktopFirstBlockHidden || isMobileFirstBlockHidden;
});

const headerProps = computed(() => {
	const {
		blocks,
		nav,
		pages,
		elements,
		metaTitle,
	} = website.value.languages[currentLocale.value];
	const newHeaderProps = getHeaderProps({
		siteId: websiteId.value,
		meta: website.value.meta,
		blocks,
		nav,
		pages,
		elements,
		languageMetaTitle: metaTitle,
		languageSwitcherLanguages: getLanguageSwitcherLanguages({
			languages: website.value.languages,
			defaultLocale: website.value.meta.defaultLocale,
		}),
		currentLocale: currentLocale.value,
		currentPageId: currentPageId.value,
		shoppingCartItemCount: 0,
		getPagePathFromId: ({ pageId }) => getPagePathFromId({
			siteData: website.value,
			pageId,
			locale: currentLocale.value,
		}),
		isOpen: false,
	});

	return {
		...newHeaderProps,
		// Disable header transparency in editor if first block is hidden - it won't work.
		isTransparent: isFirstPageBlockHidden.value ? false : newHeaderProps.isTransparent,
	};
});

const isHeaderTransparent = computed(() => headerProps.value.isTransparent);
const headerHeight = computed(() => headerProps.value.height);
const headerHeightMobile = computed(() => headerProps.value.heightMobile);
const transparentBlockSize = computed(() => {
	const height = isMobileMode.value ? headerHeightMobile.value : headerHeight.value;

	return isHeaderTransparent.value ? height : 0;
});
const headerHeightStyle = computed(() => (
	{
		'--header-height': isHeaderTransparent.value ? `${headerHeight.value || DEFAULT_HEADER_HEIGHT}px` : null,
		'--header-height-mobile': isHeaderTransparent.value ? `${headerHeightMobile.value || DEFAULT_HEADER_HEIGHT}px` : null,
	}
));

const unselectCurrentElement = () => {
	dispatch('unselectCurrentElement');
};

const bodyResizeObserver = new ResizeObserver(() => {
	setIsWindowBeingResized();
	unselectCurrentElement();
});

const closeBlockEditor = () => {
	dispatch('leaveBlockEditMode');
	dispatch('setDefaultBlockEditTab', '');
};

const handleUndoRedo = (event) => {
	if (isEditingTextBoxElement.value) {
		return;
	}

	// cmd + shift + z or ctrl + shift + z
	if ((event.ctrlKey || event.metaKey) && event.shiftKey && getCode(event) === CODE.KeyZ) {
		event.preventDefault();
		dispatch('undoRedo/executeRedo');

		return;
	}

	// for window cmd + Y
	if (event.ctrlKey && getCode(event) === CODE.KeyY) {
		event.preventDefault();
		dispatch('undoRedo/executeRedo');

		return;
	}

	// cmd + z or ctrl + z
	if ((event.ctrlKey || event.metaKey) && getCode(event) === CODE.KeyZ) {
		event.preventDefault();
		dispatch('undoRedo/executeUndo');
	}
};

const handleBlockSelect = (blockId) => {
	if (isHoveredBlockLocked.value) {
		return;
	}

	dispatch('updateCurrentBlockId', blockId);

	if (currentBlockType.value !== 'BlockLayout') {
		unselectCurrentElement();
	}
};

const handleBlockSelectOnMouseDown = (blockId) => {
	if (isHoveredBlockLocked.value) {
		return;
	}

	if (currentElementId.value && isEditingTextBoxElement.value) {
		dispatch('leaveElementEditMode');

		if (blockId !== BLOCK_STICKY_BAR_ID) {
			return;
		}
	}

	unselectCurrentElement();
	dispatch('updateCurrentBlockId', blockId);

	closeBlockEditor();
};

const movePublicScheduledPostsToPublishedTab = (scheduledPosts) => {
	Object.entries(scheduledPosts).forEach(([key, value]) => {
		const { date } = value;
		const currentDate = new Date().setHours(0, 0, 0, 0);
		const postDate = new Date(date).setHours(0, 0, 0, 0);

		if (currentDate >= postDate) {
			dispatch('blog/toggleBlogPostVisibility', key);
		}
	});
};

const createActiveBlockIntersectionObserver = (threshold) => new IntersectionObserver(([intersectionEntry]) => {
	if (intersectionEntry.isIntersecting && !isBlockEditorOpen.value && !currentElementId.value && currentBlockId.value) {
		handleBlockSelect(intersectionEntry.target.dataset.blockId);
	}
}, {
	threshold,
});

const destroyActiveBlockIntersectionObservers = () => {
	currentActiveBlocksShorterThanViewportObserver.value?.disconnect();
	currentActiveBlocksShorterThanViewportObserver.value = null;
	// Reset observers for blocks that do not fit in the viewport
	currentActiveBlocksTallerThanViewportObservers.value.forEach((observer) => observer.disconnect());
	currentActiveBlocksTallerThanViewportObservers.value = [];
};

const createActiveBlockIntersectionObservers = () => {
	// This check prevents block switching while user is using Asset Manager.
	// i.e. on pages with multiple blocks, where Slideshow is only partially visible,
	// user makes a change via Asset Manager to Slideshow that causes Layout Update which triggers
	// observer and causes currentSelectedBlock to change while user still has Asset Manager for Slideshow open
	if (window.document.querySelector('body > .asset-manager')) return;

	destroyActiveBlockIntersectionObservers();

	allCurrentPageBlocks.value.forEach((blockId) => {
		const blockToObserve = blockRefs.value[`${blockId}Ref`]?.el;

		if (!blockToObserve) {
			return;
		}

		const blockHeight = blockToObserve.getBoundingClientRect().height;
		const isBlockTooTallForScreen = blockHeight > (window.innerHeight * DEFAULT_ACTIVE_BLOCK_OBSERVER_THRESHOLD);
		const tooTallBlockCoefficientWithViewport = (window.innerHeight * DEFAULT_ACTIVE_BLOCK_OBSERVER_THRESHOLD) / blockHeight;
		const tooTallBlockThreshold = tooTallBlockCoefficientWithViewport * DEFAULT_ACTIVE_BLOCK_OBSERVER_THRESHOLD;

		// If block is too tall for the screen, we need a specific observer for it
		// Reason for that: block may be 2 times bigger than viewport, so, for example, "90%" of that block will never be visible.
		// Thus, the section won't be selected. If we have a specific threshold for that block - issue gets resolved.
		if (isBlockTooTallForScreen) {
			const higherThanViewportBlockObserver = createActiveBlockIntersectionObserver(tooTallBlockThreshold);

			higherThanViewportBlockObserver.observe(blockToObserve);
			// Save the observer so we could reset it later and prevent performance bottlenecks
			currentActiveBlocksTallerThanViewportObservers.value.push(higherThanViewportBlockObserver);

			return;
		}

		// Else, create a general observer
		if (!currentActiveBlocksShorterThanViewportObserver.value) {
			currentActiveBlocksShorterThanViewportObserver.value = createActiveBlockIntersectionObserver(
				DEFAULT_ACTIVE_BLOCK_OBSERVER_THRESHOLD,
			);
		}

		currentActiveBlocksShorterThanViewportObserver.value.observe(blockToObserve);
	});
};

// In case currentPageId becomes undefined (ex. undo/redo deletes current page), reset it to homepage
watch(currentPageId, (newValue) => {
	if (!newValue || !sitePages.value[newValue]) {
		dispatch('updateCurrentPageId');
	}
}, {
	immediate: true,
});

watch(isBlockEditorOpen, (newValue) => {
	commit(`gui/${SET_BLOCK_RESIZE_INFO}`, {
		blockId: newValue ? currentBlockId.value : null,
	});
});

watch(isMobileMode, () => {
	unselectCurrentElement();

	const block = blockRefs.value[`${currentBlockId.value}Ref`];

	if (block) {
		const builderElement = document.getElementsByClassName(BUILDER_CLASS)[0];
		const builderBottomElement = document.getElementsByClassName(BUILDER_BOTTOM_CLASS)[0];

		// Can also do block.el.scrollIntoView({ behavior: 'instant' });
		// but in that case part of the element gets under the header
		builderElement.scrollTo({
			behavior: 'instant',
			top: block.el.getBoundingClientRect().top - builderBottomElement.getBoundingClientRect().top,
		});
	}
});

watch(currentPageBlocks, async (newValue, oldValue) => {
	const isSamePage = originalPageId.value === currentPageId.value;

	originalPageId.value = currentPageId.value;

	if (isAiBuilderMode.value || !newValue) {
		return;
	}

	// Scrolls newly added blocks into view
	const newBlockId = newValue.find((value) => !oldValue.includes(value));

	if (!newBlockId) {
		return;
	}

	await nextTick();

	if (isSamePage) {
		blockRefs.value[[`${newBlockId}Ref`]].el.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
		});
	}
});

watch(scheduledBlogPages, (newValue) => {
	movePublicScheduledPostsToPublishedTab(newValue);
}, {
	immediate: true,
	deep: true,
});

watch(allCurrentPageBlocks, async () => {
	await nextTick();

	createActiveBlockIntersectionObservers();
}, {
	immediate: true,
});

onBeforeMount(() => {
	dispatch('updateCurrentBlockId', null);
});

onMounted(async () => {
	bodyResizeObserver.observe(document.body);

	dispatch('gui/setBuilderPreviewContainerRef', {
		builderPreviewContainerRef: builderPreviewContainerRef.value,
	});

	window.addEventListener('keydown', handleUndoRedo);

	if (isElementEditMode.value) {
		dispatch('leaveElementEditMode');
	}
});

onBeforeUnmount(() => {
	window.removeEventListener('keydown', handleUndoRedo);

	bodyResizeObserver.disconnect();
});

onClickOutside({
	target: editBlockPopupRef,
	preventSelector: DATA_SELECTOR_BUILDER_PREVIEW,
}, () => {
	if (getters['gui/isColorPickerOpen']) return;

	dispatch('leaveBlockEditMode');
});
</script>

<style lang="scss" scoped>
$full-height-settings-padding: 8px;

.preview {
	$this: &;

	position: relative;

	/*
	* Without high min-height edit popup
	* is not always visible when users site has low height
	*/
	min-height: calc(100vh - #{$header-height-editor});

	@media screen and (max-width: $media-mobile) {
		padding-bottom: 50px;
	}

	&__content {
		position: relative;
		z-index: 0;
		display: flex;
		flex-direction: column;
		margin-bottom: 32px;
		user-select: none;
	}

	&__edit-block {
		opacity: 1;
		transition: opacity 0.15s $transition-timing-easing-standard;

		&[data-popper-reference-hidden]:not(.editing-navigation) {
			pointer-events: none;
			opacity: 0;
		}

		&--fixed-position {
			padding: 0 $full-height-settings-padding 0 calc($sidebar-width-editor - $full-height-settings-padding);
		}

		&--popup {
			z-index: $z-index-controls-edit-block;
		}

		&--button {
			z-index: $z-index-controls-edit-block-button;
		}

		@media screen and (max-width: $media-mobile) {
			width: 100%;
		}
	}

	&__bottom-bar {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: $z-index-layout-bottom-bar;
	}
}
</style>
